import React, { FC, useState, useEffect } from "react";
// @ts-ignore
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle";

import "./index.scss";
import "react-calendar/dist/Calendar.css";
import "react-datetime-picker/dist/DateTimePicker.css";

import { IInputProps } from "../../../modules";

export const InputDate: FC<IInputProps> = (props) => {
    const [value, setValue] = useState(props.value ? props.value : null) as any;

    const handleChange = (date: any) => {
        let time: any;
        if (date) time = new Date(date).getTime();
        setValue(time);
        props.onChange(time ? new Date(time).toUTCString() : null);
    };

    const convertTime = (time: any) => {
        if (!time) return;
        return new Date(time);
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value, setValue]);

    return (
        <DateTimePicker
            className="InputDateTime"
            calendarClassName="InputDateTimeCalendar"
            clockClassName="InputDateTimeClock"
            // maxDetail={maxDetail}
            disableClock={true}
            onChange={handleChange}
            // minDate={convertTime(minDate)}
            // maxDate={convertTime(maxDate)}
            format="dd-MM-y"
            value={convertTime(value)}
            locale="en-GB"
            disabled={props.isDisabled}
            onCalendarClose={() => props.onTouched()}
            onBlur={() => props.onTouched()}
        />
    );
};
