import { RequestMainService } from "../request";

export class FaqService {
    static async getFaqList(params: any) {
        return RequestMainService.get(`/admin/faq`, params).then(
            ({ result }) => ({
                data: result.faqs,
                count: result.total,
            })
        );
    }

    static async getFaq(id: any) {
        return RequestMainService.get(`/admin/faq/${id}`);
    }

    static async createFaq(params: any) {
        return RequestMainService.post(`/admin/faq`, params);
    }

    static async updateFaq(params: any) {
        return RequestMainService.put(`/admin/faq/${params.faqId}`, params);
    }

    static async deleteFaq(id: any) {
        return RequestMainService.delete(`/admin/faq/${id}`);
    }
}
