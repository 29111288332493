/* eslint-disable import/no-anonymous-default-export */
export default {
    all: {
        login: "Đăng nhập",
        logout: "Đăng xuất",
        exportExcel: "Xuất Excel",
        sound: "Âm thanh",
        name: "Tên",
        type: "Loại",
        active: "Hoạt động",
        deactive: "Không hoạt động",
        actions: "Thao tác",
        create: "Tạo mới",
        update: "Cập nhật",
        save: "Lưu",
        cancel: "Hủy",
        close: "Đóng",
        confirm: "Xác nhận",
        createSuccess: "Tạo mới thành công",
        updateSuccess: "Cập nhật thành công",
        deleteSuccess: "Xóa thành công",
        success: "Thực hiện thành công",
        total: "Tổng cộng",
        amount: "Số lượng",
        price: "Đơn giá",
        totalPrice: "Tổng tiền",
        all: "Tất cả",
        buy: "Mua",
        sell: "Bán",
        detail: "Chi tiết",
        created: "Ngày tạo",
        trading: "Giao dịch",
        search: "Tìm kiếm",
        payment: "Thanh toán",
        language: "Ngôn ngữ",
        keyword: "Từ khóa",
        status: "Trạng thái",
        payment: "Phương thức thanh toán",
        birthday: "Ngày sinh",
        lastName: "Họ",
        idCard: "CMND/CCCD",
        driverLicense: "Bằng lái xe",
        passport: "Hộ chiếu",
        status: "Trạng thái",
        minutes: "Phút",
        online: "Trực tuyến",
        offline: "Ngoại tuyến",
    },
    login: {
        email: "Địa chỉ email",
        password: "Mật khẩu",
    },
    validate: {
        mustProvided: "Cần được bổ sung",
        emailEmpty: "Vui lòng nhập Email",
        passwordEmpty: "Vui lòng nhập Mật khẩu",
        passwordRegex: "Mật khẩu tối thiểu 8 kí tự, 1 chữ số và 1 chữ in hoa",
        passwordConfirm: "Mật khẩu xác nhận không khớp",
        emailRegex: "Email không đúng định dạng",
        phoneRegex: "Số điện thoại không đúng định dạng",
    },
    dashboard: {
        name: "Dashboard",
        listOrder: "Danh sách mua bán",
    },
    user: {
        name: "Người dùng",
        list: "Danh sách người dùng",
        create: "Tạo mới người dùng",
        update: "Cập nhật người dùng",
        delete: "Xóa người dùng",
        info: "Thông tin User",
        fullname: "Họ tên",
        password: "Mật khẩu mới",
        repassword: "Xác nhận mật khẩu mới",
        phone: "Số điện thoại",
        phonePrefix: "Mã vùng",
        address: "Địa chỉ",
        cmnd: "CMND",
        city: "Tỉnh/Thành",
        birthdate: "Sinh nhật",
        postcode: "Mã bưu điện",
        type: "Loại",
        status: "Trạng thái",
        deleteConfirm: "Xác nhận xóa người dùng ?",
        email: "Email",
        imageCardFrontOf: "Mặt trước",
        imageCardBefore: "Mặt sau",
        avatar: "Ảnh đại diện",
        oldPassword: "Mật khẩu cũ",
        newPassword: "Mật khẩu mới",
        changePassword: "Thay đổi mật khẩu",
        updateProfile: "Cập nhật thông tin",
        notyetKyc: "Chưa xác minh",
        pendingKyc: "Đang xác minh",
        approvedKyc: "Đã xác minh",
        rejectedKyc: "Từ chối xác minh",
        accountInfo: "Thông tin tài khoản",
        rejectReason: "Lý do từ chối xác minh",
        requiredRejectReason: "Lý do từ chối là bắt buộc",
        documentType: "Loại giấy tờ pháp lý",
        address: "Địa chỉ cư trú",
        national: "Quốc gia",
        searchKyc: "Nhập email hoặc họ tên",
        noImage: "Chưa có hình ảnh",

        repasswordRequired: "Xác nhận mật khẩu mới phải khớp với Mật khẩu mới",
        emailRequired: "Vui lòng nhập Email",
        phoneRequired: "Vui lòng nhập Số điện thoại",
        lastNameRequired: "Vui lòng nhập Họ",
        firstRequired: "Vui lòng nhập Tên",
        cardIdRequired: "Vui lòng nhập CMND/CCCD",
        nationalRequired: "Vui lòng nhập Quốc gia",
        birthdayRequired: "Vui lòng nhập Ngày sinh",
        roleRequired: "Vui lòng chọn Role",
        addressRequired: "Vui lòng nhập Địa chỉ",
        cityRequired: "Vui lòng nhập Tỉnh/Thành",
    },
    ipo: {
        name: "IPO",
        list: "Danh sách IPO",
        create: "Tạo mới IPO",
        update: "Cập nhật IPO",
        delete: "Xóa IPO",
        deleteConfirm: "Xác nhận xóa IPO ?",
        symbol: "Ký hiệu",
    },
    feedback: {
        name: "Phản hồi",
        list: "Danh sách phản hồi",
        create: "Tạo mới phản hồi",
        communication: "Nền tảng",
        topic: "Chủ đề",
        update: "Cập nhật phản hồi",
        delete: "Xóa phản hồi",
        deleteConfirm: "Xác nhận xóa phản hồi ?",
        symbol: "Ký hiệu",
        category: "Xếp loại",
        status: "Trạng thái",
        partnerName: "Nickname đối tác",
        userName: "Nickname user đánh giá",
        comment: "Bình luận",
        orderCode: "Mã giao dịch",
    },
    ads: {
        name: "Quảng cáo",
        list: "Danh sách Quảng cáo",
        create: "Tạo mới Quảng cáo",
        update: "Cập nhật Quảng cáo",
        detail: "Chi tiết Quảng cáo",
        delete: "Xóa Quảng cáo",
        deleteConfirm: "Xác nhận xóa Quảng cáo ?",
        symbol: "Ký hiệu",
        userName: "Nickname user quảng cáo",
        totalAmount: "Tổng giá trị",
        totalQuantity: "Tổng số lượng",
        publishType: "Loại công khai",
        priceOfYou: "Giá của bạn",
        priceType: "Loại giá",
        price: "Giá",
        maxPriceByIPO: "Giá lệnh cao nhất",
        term: "Nhận xét",
        autoReply: "Trả lời tự động",
        minimumOrderPrice: "Giới hạn lệnh thấp nhất",
        maximumOrderPrice: "Giới hạn lệnh cao nhất",
        validTotalAmount:
            "Giới hạn thấp nhất < Giới hạn cao nhất < Tổng giá trị",
        selectIPO: "IPO",
        selectFiat: "Loại tiền",
        selectPayment: "Loại thanh toán",
        selectPrice: "Tổng tiền",
        selectActive: "Trạng thái hoạt động",
        search: "Tìm kiếm nickname user quảng cáo, mã quảng cáo",
        createdTime: "Thời gian tạo",
        modifiedTime: "Lần cập nhật gần nhất",
        close: "Đóng quảng cáo",
        closeConfirm: "Xác nhận đóng quảng cáo",
        orderType: "Loại giao dịch",
        online: "Đang online",
        offline: "Offline, thực hiện thủ công sau",
        kycSuccess: "Đã hoàn tất KYC",
        registered: "Đã đăng ký",
        registeredLast: "ngày trước",
        paymentLimitTime: "Hạn mức thời gian trả tiền",
        conditions: "Điều kiện cho đối tác",
        sellIpo: "Muốn bán IPO",
        buyIpo: "Muốn mua IPO",
        step1: "Bước 1: Đặt giá & loại",
        step2: "Bước 2: Đặt số lượng giao dịch & Phương thức thanh toán",
        step3: "Bước 3: Đặt lưu ý & Phản hồi tự động",
    },
    transactions: {
        name: "Transactions",
    },
    trading: {
        name: "Giao dịch",
        code: "Mã lệnh",
        type: "Loại giao dịch",
        created: "Ngày tạo lệnh",
        info: "Thông tin giao dịch",
        feedback: "Góp ý",
        noFeedback: "Không có góp ý",
        searchText: "Nhập Email, Số điện thoại",
        partnerInfo: "Thông tin đối tác",
        newStatus: "Chưa thanh toán",
        transferStatus: "Đã thanh toán",
        successStatus: "Đã hoàn tất",
        cancelStatus: "Đã hủy",
        complainStatus: "Đang khiếu nại",
        orderDate: "Ngày giờ giao dịch",
        totalAmount: "Tổng giá trị",
        accountBank: "Tài khoản giao dịch",
    },
    role: {
        name: "Phân quyền",
        list: "Danh sách Role",
        nameRole: "Tên Role",
        addRole: "Thêm Role",
        create: "Tạo mới Role",
        update: "Cập nhật Role",
        active: "Hiện",
        inActive: "Ẩn",
        removeConfirmTitle: "Xác nhận xóa Role",
        removeDenialTitle: "Không thể xóa Role",
        removeDenialContent:
            "Vui lòng chuyển các User trong Role qua các Role khác",
        validDuplicate: "Tên Role bị trùng, vui lòng nhập tên khác",
    },
    permission: {
        available: "Quyền đã có",
        unavailable: "Quyền chưa có",
        validRequired: "Vui lòng chọn quyền cần thay đổi trước khi thực hiện",
        view: "Xem",
        create: "Thêm",
        edit: "Sửa",
        delete: "Xóa",
        toggle: "Quyền bật/tắt",

        titleFaqCategory: "Loại câu hỏi",
        titleFaq: "Câu hỏi thường gặp",
        titleOther: "Các quyền khác",
        titlePermission: "Phân quyền",
        titleTransaction: "Giao dịch",
        titleReport: "Báo cáo",
        titleIpo: "Quản lý IPO",
        titleAds: "Quản lý quảng cáo",
        titlePayment: "Hình thức thanh toán",
        titleProfile: "Quản lý profile",
        titleRole: "Quản lý Role",
        titleCms: "Quản lý tin tức, bài viết",
        titleIpoOrder: "Quản lý Ipo-Order",
        titlePushNotif: "Quản lý thông báo FCM",
        titleReloadOrder: "Quản lý Reload-Order",
        titleFeedback: "Quản lý phản hồi",

        disableUser: "Khóa User",
        enableUser: "Mở User",
        rateUser: "Xếp hạng User",
        kycReview: "Quyền xem xét KYC",
        loginAdmin: "Đăng nhập trang Admin",
        viewWithdraw: "Xem tiến độ rút tiền",
        reviewWithdraw: "Lịch sử rút tiền",
        changeServerConfig: "Thay đổi thiết lập Server",
        systemMaintenance: "Thiết lập, bảo trì hệ thống",
        viewPaymentInfo: "Xem thông tin thanh toán",
        toggleFeedback: "Quyền bật/tắt xem phản hồi",
    },
    reports: {
        name: "Báo cáo",
    },
    setting: {
        reloadTimes: "Cấu hình làm mới thời gian",
        maintenance: "Bảo trì hệ thống",
        name: "Cài đặt",
    },
    payment: {
        name: "Hình thức thanh toán",
        list: "Danh sách hình thức thanh toán",
        color: "Màu sắc",
        create: "Thêm mới hình thức thanh toán",
        update: "Cập nhật hình thức thanh toán",
        deleteConfirm: "Xác nhận xóa hình thức thanh toán?",
        delete: "Xóa hình thức thanh toán",
        pickColor: "Chọn",
    },
    cms: {
        name: "Tin tức, bài viết",
        list: "Danh sách tin tức, bài viết",
        create: "Thêm mới tin tức, bài viết",
        title: "Title",
        content: "Content",
        description: "Description",
        image: "Image",
        selectImage: "Chọn hình ảnh",
        category: "Category",
        slug: "Slug",
        deleteConfirm: "Xác nhận xóa tin tức, bài viết?",
        delete: "Xóa tin tức, bài viết",
    },
    faq: {
        name: "FAQ",
        list: "Danh sách câu hỏi thường gặp",
        create: "Thêm mới câu hỏi thường gặp",
        category: "Category",
        title: "Title",
        content: "Content",
        description: "Description",
        deleteConfirm: "Xác nhận xóa câu hỏi thường gặp?",
        delete: "Xóa câu hỏi thường gặp",
    },
    faqCategory: {
        name: "Loại câu hỏi",
        list: "Danh sách loại câu hỏi thường gặp",
        create: "Thêm mới loại câu hỏi thường gặp",
        category: "Category",
        title: "Title",
        content: "Content",
        description: "Description",
        deleteConfirm: "Xác nhận xóa loại câu hỏi thường gặp?",
        delete: "Xóa loại câu hỏi thường gặp",
    },
    kyc: {
        status: "Trạng thái",
        email: "Email",
    },
};
