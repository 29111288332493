import { Store } from "redux";

import { RequestMainService } from "../request";
import { SET_AUTH, LOG_OUT } from "./user.reducer";
import { SET_USER_INTERNAL_WALLETS } from "./userInternalWallets.reducer";
import { SET_USER_EXTERNAL_WALLETS } from "./userExternalWallets.reducer";
import { SET_USER_WALLET_BALANCES } from "./userWalletBalances.reducer";
import { SET_USER_KYC } from "./userKYC.reducer";
import { MainService, SET_IS_HAS_PIN_CODE } from "../main";
import { SocketService } from "../socket";
import { CookieService, ECookieVariable } from "../cookie";
import { store } from "../../store";
import { CreateAlert } from "../../modules";

export class UserService {
    static async login(
        email: string,
        password: string,
        recaptcha?: string,
        loginType?: string
    ) {
        return RequestMainService.login(`/admin/login`, {
            email,
            password,
            recaptcha,
            loginType,
        }).then(async (res) => {
            if (res.success) {
                const { token, permissions } = res.result;
                if (permissions.length === 0) throw new Error("Access denied.");

                CookieService.set(ECookieVariable.USER_ACCESS_TOKEN, token);
                await this.getUserData(store);

                store.dispatch({ type: SET_AUTH, data: res.result });
                MainService.forceUpdateApp();
            } else {
                CreateAlert({ type: "danger", message: res.message })
            }
        });
    }

    static async auth(store: Store) {
        const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
        if (token)
            return RequestMainService.get("/user/check")
                .then(({ result }) => {
                    store.dispatch({ type: SET_AUTH, data: result });
                    return this.getUserData(store);
                })
                .catch(() => false);
    }

    static async getUserData(store: Store) {
        return Promise.all([
            this.getInternalWallets(store),
            this.getExternalWallets(store),
            this.getWalletBalances(store),
        ]);
    }

    static logout() {
        CookieService.remove(ECookieVariable.USER_ACCESS_TOKEN);
        store.dispatch({ type: LOG_OUT });
        SocketService.disconnect();
    }

    static async register(payload: any) {
        return RequestMainService.post(`/user/register`, payload);
    }

    static async verifyRegister(code: string) {
        return RequestMainService.post(`/user/verify-register/${code}`);
    }

    static async resendMailVerifyAccount() {
        return RequestMainService.post(`/user/resend-register-email`);
    }

    static async getInternalWallets(store: Store) {
        return RequestMainService.get(`/bank/internal-wallet`)
            .then(({ result }) =>
                store.dispatch({
                    type: SET_USER_INTERNAL_WALLETS,
                    data: {
                        count: Object.keys(result).length,
                        data: Object.keys(result).reduce(
                            (output: any[], key) => {
                                return [
                                    ...output,
                                    {
                                        code: key.toUpperCase(),
                                        ...result[key],
                                    },
                                ];
                            },
                            []
                        ),
                    },
                })
            )
            .catch((res) =>
                store.dispatch({
                    type: SET_USER_INTERNAL_WALLETS,
                    error: res.error,
                })
            );
    }

    static async getExternalWallets(store: Store) {
        return RequestMainService.get(`/bank/external-wallets`)
            .then(({ result }) =>
                store.dispatch({
                    type: SET_USER_EXTERNAL_WALLETS,
                    data: {
                        count: Object.keys(result).length,
                        data: Object.keys(result).reduce(
                            (output: any[], key) => {
                                return [
                                    ...output,
                                    {
                                        code: key.toUpperCase(),
                                        ...result[key],
                                    },
                                ];
                            },
                            []
                        ),
                    },
                })
            )
            .catch((res) =>
                store.dispatch({
                    type: SET_USER_EXTERNAL_WALLETS,
                    error: res.error,
                })
            );
    }

    static async getWalletBalances(store: Store) {
        return RequestMainService.get(`/bank/balance`)
            .then(({ result }) =>
                store.dispatch({
                    type: SET_USER_WALLET_BALANCES,
                    data: {
                        count: Object.keys(result).length,
                        data: Object.keys(result).reduce(
                            (output: any[], key) => {
                                return [
                                    ...output,
                                    {
                                        code: key.toUpperCase(),
                                        amount: result[key],
                                    },
                                ];
                            },
                            []
                        ),
                    },
                })
            )
            .catch((res) =>
                store.dispatch({
                    type: SET_USER_WALLET_BALANCES,
                    error: res.error,
                })
            );
    }

    static async getKYC(store: Store) {
        return RequestMainService.get(`/user/kyc`)
            .then(({ result }) =>
                store.dispatch({
                    type: SET_USER_KYC,
                    data: result,
                })
            )
            .catch((res) =>
                store.dispatch({
                    type: SET_USER_KYC,
                    error: res.error,
                })
            );
    }

    static async sendRequestResetPasswordEmail(email: string) {
        return RequestMainService.post(`/user/forgot-password/${email}`);
    }

    static async resetPassword(newPassword: string, code: string) {
        return RequestMainService.post(`/user/reset-password`, {
            newPassword,
            code,
        });
    }

    static async getPinCode(store: Store) {
        return RequestMainService.get(`/user/check-pin-code`)
            .then((res) =>
                store.dispatch({
                    type: SET_IS_HAS_PIN_CODE,
                    result: res.result,
                })
            )
            .catch(() => false);
    }

    static async updatePinCode(payload: any) {
        return RequestMainService.put(`/user/pin-code`, payload);
    }

    static async getPublicInfoByEmail(email: string) {
        return RequestMainService.get(`/user/public-info/${email}`).then(
            ({ result }) => result
        );
    }

    static async changePassword(currentPassword: string, newPassword: string) {
        return RequestMainService.post(`/user/change-password`, {
            currentPassword,
            newPassword,
        });
    }
}
