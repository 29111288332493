import React, { FC, Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";
import { AppRoutes } from "./AppRoutes";
import { store } from "./store";
import { Alert } from "./modules";
import { MainService } from "./services";
// import { getEnv } from "./configs";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ImageGallery } from "./components/image-gallery";

const Root: FC = () => {
    useEffect(() => {
        MainService.initializeClient();
    }, []);

    return (
        <Fragment>
            <Alert />
            <AppRoutes />
        </Fragment>
    );
};

ReactDOM.render(
    <React.StrictMode>
        {/* <GoogleReCaptchaProvider reCaptchaKey={getEnv("GOOGLE_RECAPTCHA_KEY")}> */}
        <Provider store={store}>
            <ImageGallery />
            <Root />
        </Provider>
        {/* </GoogleReCaptchaProvider> */}
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
