import React, { useState, memo, useEffect } from "react";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./index.scss";
import { Button, useForm, InputWraper, CreateAlert } from "../../../../modules";
import { translate } from "../../../../languages";
import * as Yup from "yup";
import {
    FaqService,
    FAQCategoryService,
    FileService,
} from "../../../../services";
import { withFaqWraper } from "../../wraper";
import { InputText } from "./input-text";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { useNavigate } from "react-router-dom";

import ImageUploader from "react-images-upload";
import {
    InputTextArea,
    InputSelect,
    InputCheckbox,
} from "../../../../components";
import {
    EFAQVisiblePage,
    EFAQDescriptionType,
    EFAQStatus,
} from "../../enumFAQ";

export const CreateEditFaq = withFaqWraper(
    memo((props: any) => {
        const {
            title,
            description,
            descriptionType = EFAQDescriptionType.URL,
            status = EFAQStatus.ACTIVE.value,
            isCreate = true,
            faqId,
            visibleOnPages,
            faqCategory,
        } = props.data || {};
        const navigate = useNavigate();

        const [visibles, setVisibles] = useState(
            (visibleOnPages || []).reduce((obj: any, item: any) => {
                return { ...obj, [item]: true };
            }, {}) || {}
        );
        const [valueCategory, setValueCategory] = useState(null);
        const [valueTitle, setValueTitle] = useState(null);
        const [valueDescription, setValueDescription] = useState(null);
        const [valueContent, setValueContent] = useState(null);
        const [valueCheckbox, setValueCheckbox] = useState(
            status === EFAQStatus.ACTIVE.value
        );

        const { handleSubmit, getInputProps, isSubmitting, setValues, values } =
            useForm({
                structure: [
                    {
                        name: "faqCategoryId",
                        defaultValue: faqCategory?.faqCategoryId,
                        validate: Yup.string().required(
                            translate("validate.mustProvided")
                        ),
                    },
                    {
                        name: "title",
                        defaultValue: title,
                        validate: Yup.string().required(
                            translate("validate.mustProvided")
                        ),
                    },
                    {
                        name: "description",
                        defaultValue: description,
                        validate: Yup.string().required(
                            translate("validate.mustProvided")
                        ),
                    },
                    {
                        name: "descriptionType",
                        defaultValue: descriptionType,
                        validate: Yup.string().required(
                            translate("validate.mustProvided")
                        ),
                    },
                ],
                onSubmit: async (values) => {
                    let res = null;
                    if (isCreate) {
                        return FaqService.createFaq({
                            title: values.title,
                            description: values.description,
                            descriptionType: values.descriptionType,
                            faqCategoryId: values.faqCategoryId,
                            status: valueCheckbox
                                ? EFAQStatus.ACTIVE.value
                                : EFAQStatus.INACTIVE.value,
                            visibleOnPages: Object.keys(visibles).filter(
                                (item) => visibles[item]
                            ),
                        })
                            .then((result) => {
                                CreateAlert({
                                    message: translate("all.createSuccess"),
                                    type: "success",
                                });
                                navigate("/faq/");
                            })
                            .catch((error) => {
                                CreateAlert({
                                    message: error.message,
                                    type: "danger",
                                });
                            });
                    }
                    return FaqService.updateFaq({
                        title: values.title,
                        description: values.description,
                        descriptionType: values.descriptionType,
                        faqCategoryId: values.faqCategoryId,
                        status: valueCheckbox
                            ? EFAQStatus.ACTIVE.value
                            : EFAQStatus.INACTIVE.value,
                        visibleOnPages: Object.keys(visibles).filter(
                            (item) => visibles[item]
                        ),
                        faqId: faqId,
                    })
                        .then(() => {
                            CreateAlert({
                                message: translate("all.updateSuccess"),
                                type: "success",
                            });
                            props.onFinishEdit();
                        })
                        .catch((error) => {
                            CreateAlert({
                                message: error.message,
                                type: "danger",
                            });
                        });
                },
            });

        const format = (arr: any) => {
            return arr.map((item: any) => {
                return {
                    label: item.name,
                    value: item.faqCategoryId,
                    ...(item.children
                        ? {
                              children: format(item.children),
                          }
                        : {}),
                };
            });
        };

        const [categoryOption, setCategoryOption] = useState([]);

        const getCategoryList = async () => {
            const res = await FAQCategoryService.getFAQCategoryList({});

            if (res.data) {
                setCategoryOption(format(res.data));
            }
        };

        useEffect(() => {
            getCategoryList();
        }, []);

        const handleChange = (name: any, value: any) => {
            setValues({ [name]: value });
        };
        console.log(getInputProps("description"));

        return (
            <form className="Edit" onSubmit={handleSubmit}>
                <InputWraper
                    label={"Loại câu hỏi *"}
                    inputProps={getInputProps("faqCategoryId")}
                    className="mr5 inputTextRight"
                    renderInput={(inputProps) => {
                        return (
                            <InputSelect
                                {...inputProps}
                                options={categoryOption}
                                onChange={(value) =>
                                    handleChange("faqCategoryId", value)
                                }
                                onTouched={() => false}
                                name="categoryId"
                            />
                        );
                    }}
                />
                <div className="label">{"Tiêu đề *"}</div>
                <InputWraper
                    className="title"
                    inputProps={getInputProps("title")}
                    component={InputText}
                    placeholder="Nhập tiêu đề"
                />

                <div className="group">
                    <InputCheckbox
                        label={translate("all.active")}
                        onChange={(value) => setValueCheckbox(value)}
                        name="active"
                        value={valueCheckbox}
                        onTouched={() => false}
                    />
                </div>

                <div className="label">Cách hiển thị *</div>
                <div className="group">
                    <InputCheckbox
                        isRequired={true}
                        label={"Link PDF / Video"}
                        name={"link"}
                        type="radio"
                        value={
                            values.descriptionType === EFAQDescriptionType.URL
                        }
                        onChange={() =>
                            setValues({
                                descriptionType: EFAQDescriptionType.URL,
                            })
                        }
                        onTouched={() => false}
                        className="wfull"
                    />
                    <InputCheckbox
                        isRequired={true}
                        label={"Bài viết"}
                        name={"post"}
                        type="radio"
                        value={
                            values.descriptionType ===
                            EFAQDescriptionType.LONG_DESCRIPTION
                        }
                        onChange={() =>
                            setValues({
                                descriptionType:
                                    EFAQDescriptionType.LONG_DESCRIPTION,
                            })
                        }
                        onTouched={() => false}
                        className="wfull"
                    />
                    <InputCheckbox
                        isRequired={true}
                        label={"Nội dung ngắn gọn"}
                        name={"short-des"}
                        type="radio"
                        value={
                            values.descriptionType ===
                            EFAQDescriptionType.SHORT_DESCRIPTION
                        }
                        onChange={() =>
                            setValues({
                                descriptionType:
                                    EFAQDescriptionType.SHORT_DESCRIPTION,
                            })
                        }
                        onTouched={() => false}
                        className="wfull"
                    />
                </div>

                {values.descriptionType === EFAQDescriptionType.URL && (
                    <InputWraper
                        className="title"
                        inputProps={getInputProps("description")}
                        component={InputText}
                        placeholder="Nhập Link PDF / Video"
                    />
                )}
                <div>
                    {(values.descriptionType ===
                        EFAQDescriptionType.SHORT_DESCRIPTION ||
                        values.descriptionType ===
                            EFAQDescriptionType.LONG_DESCRIPTION) && (
                        <>
                            <CKEditor
                                key={values.descriptionType}
                                editor={ClassicEditor}
                                config={{
                                    placeholder:
                                        values.descriptionType ===
                                        EFAQDescriptionType.SHORT_DESCRIPTION
                                            ? "Người nhập không nhập hình ảnh và format phức tạp, chỉ nhập text & chèn link"
                                            : "Nhập nội dung",
                                }}
                                data={getInputProps("description").value}
                                onReady={(editor: any) => {}}
                                onChange={(event: any, editor: any) => {
                                    handleChange(
                                        "description",
                                        editor.getData()
                                    );
                                }}
                            />
                            <p className="errorMessage generalErrorMessage">
                                {getInputProps("description").error}
                            </p>
                        </>
                    )}
                </div>

                <div className="group" style={{ marginTop: "10px" }}>
                    <InputCheckbox
                        label={"Homepage (Giới thiệu)"}
                        name={"homepage"}
                        value={visibles[EFAQVisiblePage.HOME_PAGE.value]}
                        onChange={(value) =>
                            setVisibles((val: any) => ({
                                ...val,
                                [EFAQVisiblePage.HOME_PAGE.value]: value,
                            }))
                        }
                        onTouched={() => false}
                        className="wfull"
                    />
                    <InputCheckbox
                        label={"Câu hỏi thường gặp"}
                        name={"news"}
                        value={visibles[EFAQVisiblePage.FAQ_PAGE.value]}
                        onChange={(value) =>
                            setVisibles((val: any) => ({
                                ...val,
                                [EFAQVisiblePage.FAQ_PAGE.value]: value,
                            }))
                        }
                        onTouched={() => false}
                        className="wfull"
                    />
                </div>
                <div className="groupButton">
                    <Button
                        label={translate("all.cancel")}
                        buttonType="danger"
                        onClick={
                            isCreate ? () => navigate("/faq/") : props.onClose
                        }
                    />
                    <Button
                        isLoading={isSubmitting}
                        label={
                            isCreate
                                ? translate("all.create")
                                : translate("all.update")
                        }
                        className="btnBan ml5"
                        type="submit"
                    />
                </div>
            </form>
        );
    })
);
