import { translate } from "../../languages";

export const paymentOptionsStatus = () => [
    { value: "true", label: translate("all.active") },
    { value: "false", label: translate("all.deactive") }
]

export const paymentGetOption = (type: "status", value: any) => {
    if (!value) return
    if (type === "status") return paymentOptionsStatus().find((t: any) => t.value === value)
}