import React, { useState, memo, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import XLSX from "xlsx";
import {
    Table,
    ITableStructureItem,
    CreateAlert,
    Button,
    Icon,
    InputWraper,
    DateTimeUtils,
} from "../../../modules";
import "./FaqList.scss";
import { FAQCategoryService } from "../../../services";
import { withFaqCategoryWraper } from "../wraper";
import { translate } from "../../../languages";
import { PopupWraper } from "../../../components/popup";
import { Ban } from "./ban";
import { InputSelect, InputText } from "../../../components";
import {
    EFAQCustomerType,
    EFAQCustomerTypeList,
    EFAQFeatureType,
    EFAQFeatureTypeList,
    EFAQStatus,
} from "../enumFAQCategory";
import { CreateEditFaqCategory } from "./CreateEdit";

export const FaqCategoryList = withFaqCategoryWraper(
    memo(() => {
        const navigate = useNavigate();
        const [params, setParams] = useState([] as any);
        const [editData, setEditData] = useState<any>(null);
        const [freezeDataId, setFreezeDataId] = useState<number | null>(null);
        const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());

        const [enableReinitialize, setEnableReinitialize] =
            useState<boolean>(false);
        const [datas, setDatas] = useState(null as any);

        const [titleValue, setTitleValue] = useState<any>(null);
        const [customerTypeValue, setCustomerTypeValue] = useState<any>(null);
        const [featureType, setFeatureType] = useState<any>(null);
        const [status, setStatus] = useState<any>(null);

        const [optionFaqCategory, setOptionFaqCategory] = useState([]);

        useEffect(() => {
            if (params && params.limit && params.pageNumber) {
                FAQCategoryService.getFAQCategoryList({
                    page: params.pageNumber,
                    numberOfItemsPerPage: params.limit,
                    keyword: titleValue || "",
                    customerType: customerTypeValue || "",
                    featureType: featureType || "",
                    status: status || "",
                })
                    .then((res) => {
                        if (params.pageNumber === 1) {
                            setEnableReinitialize(true);
                        }
                        setDatas({
                            count: res.count,
                            data: res.data,
                        });
                    })
                    .catch((err) => setDatas({ error: err.error }));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [forceUpdateTable, params.limit, params.pageNumber]);

        useEffect(() => {
            setEnableReinitialize(false);
        }, [datas]);

        const getDetailFaq = async (item: any) => {
            setEditData({
                ...item,
                isCreate: false,
            });
        };

        const structure: ITableStructureItem[] = [
            {
                name: "Đối tượng",
                key: "customerType",
                render: (item: any) => {
                    return EFAQCustomerType[item.customerType].label;
                },
            },
            {
                name: "Tính năng",
                key: "featureType",
                render: (item) => {
                    return EFAQFeatureType[item.featureType].label;
                },
            },
            {
                name: "Tựa đề câu hỏi",
                key: "name",
            },
            {
                name: "Trạng thái",
                key: "status",
                render: (item) => {
                    return EFAQStatus[item.status].label;
                },
            },
            {
                name: "Ngày tạo/cập nhật",
                key: "modified",
                render: (item) => {
                    const ModifiedSeconds = DateTimeUtils.timeToSeconds(
                        item.modified
                    );
                    return DateTimeUtils.formatToShow(ModifiedSeconds);
                },
            },
            {
                name: translate("all.actions"),
                key: "actions",
                render: (item) => {
                    return (
                        <div className="btnGroup">
                            <span
                                className="btn btn__edit"
                                onClick={() => getDetailFaq(item)}
                            >
                                <Icon.Edit />
                            </span>
                            <span
                                className="btn btn__ban"
                                onClick={() =>
                                    setFreezeDataId(item.faqCategoryId)
                                }
                            >
                                <Icon.Trash />
                            </span>
                        </div>
                    );
                },
            },
        ];

        const handleExportExcel = async () => {
            return new Promise(async (resolve) => {
                try {
                    const response =
                        await FAQCategoryService.getFAQCategoryList({
                            page: params.pageNumber,
                            numberOfItemsPerPage: params.limit,
                            keyword: titleValue || "",
                            customerType: customerTypeValue || "",
                            featureType: featureType || "",
                            status: status || "",
                        });

                    const data = response.data;

                    const fileHead = structure.map((v) => v.name);
                    const dataExport = [
                        fileHead,
                        ...data.map((item: any) =>
                            structure.map((column, index) => {
                                if (!column.key) return "";
                                return item[column.key];
                            })
                        ),
                    ];

                    const ws = XLSX.utils.aoa_to_sheet(dataExport);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                    const now = new Date();
                    XLSX.writeFile(
                        wb,
                        `Faq Category List ${now
                            .toLocaleDateString()
                            .replace(/\//g, "-")} ${now
                            .toLocaleTimeString()
                            .replace(/:/g, "-")}.xlsx`,
                        { type: "binary" }
                    );

                    resolve(
                        CreateAlert({
                            type: "success",
                            message: "Export data success.",
                        })
                    );
                } catch (error: any) {
                    resolve(
                        CreateAlert({ type: "danger", message: error.message })
                    );
                }
            });
        };

        const tableMemo = useMemo(() => {
            return (
                <Table
                    structure={structure}
                    fetchData={async (params) => {
                        setParams(params);
                        return FAQCategoryService.getFAQCategoryList({
                            page: params.pageNumber,
                            numberOfItemsPerPage: params.limit,
                            keyword: titleValue || "",
                            customerType: customerTypeValue || "",
                            featureType: featureType || "",
                            status: status || "",
                        });
                    }}
                />
            );
        }, [forceUpdateTable]);

        return (
            <div className="FaqList">
                <Button
                    className="mb20"
                    label={"Thêm mới loại câu hỏi thường gặp"}
                    buttonType="success"
                    onClick={() => navigate("/faq-category/new")}
                />

                <div className="group mb20">
                    <div className="itemSelect wfull mr5">
                        <div className="label">Đối tượng</div>
                        <InputSelect
                            options={EFAQCustomerTypeList}
                            onChange={(value) => setCustomerTypeValue(value)}
                            onTouched={() => false}
                            name="customerType"
                            value={customerTypeValue}
                        />
                    </div>
                    <div className="itemSelect wfull mr5">
                        <div className="label">Loại tính năng</div>
                        <InputSelect
                            options={EFAQFeatureTypeList}
                            onChange={(value) => setFeatureType(value)}
                            onTouched={() => false}
                            name="featureType"
                            value={featureType}
                        />
                    </div>
                    <div className="itemSelect wfull mr5">
                        <div className="label">Trạng thái</div>
                        <InputSelect
                            options={Object.values(EFAQStatus)}
                            onChange={(value) => setStatus(value)}
                            onTouched={() => false}
                            name="status"
                            value={status}
                        />
                    </div>

                    <div className="itemSelect wfull mr5">
                        <div className="label">Tìm kiếm</div>
                        <InputWraper
                            inputProps={{
                                name: "titleValue",
                                onChange: (e) => {
                                    setTitleValue(e);
                                },
                                onTouched: () => false,
                                value: titleValue,
                            }}
                            renderInput={InputText}
                            placeholder="Tìm kiếm Tên tiêu đề loại câu hỏi"
                        />
                    </div>
                    <div>
                        <div className="btnGroup">
                            <Button
                                label={translate("all.search")}
                                onClick={() => setForceUpdateTable(Math.random)}
                            />
                            <span
                                className="btn btn__edit"
                                onClick={() => {
                                    setTitleValue("");
                                    setCustomerTypeValue(null);
                                    setFeatureType(null);
                                    setStatus(null);
                                }}
                            >
                                <Icon.Remove />
                            </span>
                        </div>
                    </div>
                </div>
                {tableMemo}

                {editData && (
                    <PopupWraper
                        center
                        title={
                            editData.isCreate
                                ? "Thêm loại câu hỏi thường gặp"
                                : "Cập nhật loại câu hỏi thường gặp"
                        }
                        onClose={() => setEditData(null)}
                    >
                        <CreateEditFaqCategory
                            onClose={() => setEditData(null)}
                            data={editData}
                            onFinishEdit={() => {
                                setForceUpdateTable(Math.random);
                                setEditData(null);
                            }}
                        />
                    </PopupWraper>
                )}
                {freezeDataId && (
                    <PopupWraper
                        center
                        title={translate("faq.delete")}
                        onClose={() => setFreezeDataId(null)}
                    >
                        <Ban
                            userId={freezeDataId}
                            onFinishEdit={() => {
                                setForceUpdateTable(Math.random);
                                setFreezeDataId(null);
                            }}
                        />
                    </PopupWraper>
                )}
            </div>
        );
    })
);
