import React, { FC, useState, memo, useEffect } from "react";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImageUploader from "react-images-upload";
import "./index.scss";
import { Button, useForm, InputWraper, CreateAlert } from "../../../../modules";
import { translate } from "../../../../languages";
import * as Yup from "yup";
import {
    InputTextArea,
    InputSelect,
    InputCheckbox,
} from "../../../../components";
import { CmsService, FileService, CategoryService } from "../../../../services";
import { withCmsWraper } from "../../wraper";
import { InputText } from "./input-text";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { useNavigate } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import { ECMSVisiblePage } from "../../enumCMS";

export const CreateEditCms = withCmsWraper(
    memo((props: any) => {
        const {
            categoryId,
            title,
            description,
            content,
            fileId,
            url,
            isActived = true,
            isCreate = true,
            cmsId,
            visibleOnPages,
            isHighlight = false,
        } = props.data || {};
        const navigate = useNavigate();

        const [visibles, setVisibles] = useState(
            (visibleOnPages || []).reduce((obj: any, item: any) => {
                return { ...obj, [item]: true };
            }, {}) || {}
        );
        const [valueCategory, setValueCategory] = useState(null);
        const [valueTitle, setValueTitle] = useState(null);
        const [valueDescription, setValueDescription] = useState(null);
        const [valueContent, setValueContent] = useState(null);
        const [valueHighlight, setValueHighlight] = useState(
            isHighlight || false
        );
        const [valueCheckbox, setValueCheckbox] = useState(isActived || false);
        const [picture, setPicture] = useState<any>(url);

        const { handleSubmit, getInputProps, isSubmitting, setValues, values } =
            useForm({
                structure: [
                    {
                        name: "categoryId",
                        defaultValue: categoryId,
                        validate: Yup.string().required(
                            translate("validate.mustProvided")
                        ),
                    },
                    {
                        name: "title",
                        defaultValue: title,
                        validate: Yup.string().required(
                            translate("validate.mustProvided")
                        ),
                    },
                    {
                        name: "description",
                        defaultValue: description,
                        validate: Yup.string().required(
                            translate("validate.mustProvided")
                        ),
                    },
                    {
                        name: "content",
                        defaultValue: content,
                        validate: Yup.string().required(
                            translate("validate.mustProvided")
                        ),
                    },
                ],
                onSubmit: async (values) => {
                    let res = null;
                    if (!!picture?.[0] && typeof picture?.[0] === "object") {
                        let formData = new FormData();
                        formData.append("file", picture[0]);
                        res = await FileService.uploadFile(formData);
                    }
                    if (isCreate) {
                        return CmsService.createCms({
                            title: values.title,
                            description: values.description,
                            content: values.content,
                            fileId: res?.result?.fileId || fileId,
                            categoryId: values.categoryId,
                            isActived: valueCheckbox,
                            isHighlight: valueHighlight,
                            visibleOnPages: Object.keys(visibles).filter(
                                (item) => visibles[item]
                            ),
                        })
                            .then((result) => {
                                CreateAlert({
                                    message: translate("all.createSuccess"),
                                    type: "success",
                                });
                                navigate("/cms/");
                            })
                            .catch((error) => {
                                CreateAlert({
                                    message: error.message,
                                    type: "danger",
                                });
                            });
                    }
                    return CmsService.updateCms({
                        title: values.title,
                        description: values.description,
                        content: values.content,
                        fileId: res?.result?.fileId || fileId,
                        categoryId: values.categoryId,
                        isActived: valueCheckbox,
                        isHighlight: valueHighlight,
                        visibleOnPages: Object.keys(visibles).filter(
                            (item) => visibles[item]
                        ),
                        cmsId: cmsId,
                    })
                        .then(() => {
                            CreateAlert({
                                message: translate("all.updateSuccess"),
                                type: "success",
                            });
                            props.onFinishEdit();
                        })
                        .catch((error) => {
                            CreateAlert({
                                message: error.message,
                                type: "danger",
                            });
                        });
                },
            });

        const format = (arr: any) => {
            return arr.map((item: any) => {
                return {
                    label: item.categoryName,
                    value: item.categoryId,
                    ...(item.children
                        ? {
                              children: format(item.children),
                          }
                        : {}),
                };
            });
        };

        const [categoryOption, setCategoryOption] = useState([]);

        const getCategoryList = async () => {
            const res = await CategoryService.getCategoryList();
            if (res.data) {
                setCategoryOption(format(res.data));
            }
        };

        useEffect(() => {
            getCategoryList();
        }, []);

        const handleChange = (name: any, value: any) => {
            setValues({ [name]: value });
        };

        return (
            <form className="Edit" onSubmit={handleSubmit}>
                <InputWraper
                    label={"Danh mục *"}
                    inputProps={getInputProps("categoryId")}
                    className="mr5 inputTextRight"
                    renderInput={(inputProps) => {
                        return (
                            <InputSelect
                                {...inputProps}
                                options={categoryOption}
                                onChange={(value) =>
                                    handleChange("categoryId", value)
                                }
                                onTouched={() => false}
                                name="categoryId"
                            />
                        );
                    }}
                />

                <div className="group">
                    <InputCheckbox
                        label={translate("all.active")}
                        onChange={(value) => setValueCheckbox(value)}
                        name="active"
                        value={valueCheckbox}
                        onTouched={() => false}
                    />
                    <InputCheckbox
                        label={"Nổi bật"}
                        onChange={(value) => setValueHighlight(value)}
                        name="active"
                        value={valueHighlight}
                        onTouched={() => false}
                    />
                </div>
                <div className="label">{"Tiêu đề *"}</div>
                <InputWraper
                    className=""
                    inputProps={getInputProps("title")}
                    component={InputText}
                />

                <InputWraper
                    label={"Mô tả ngắn *"}
                    inputProps={{
                        ...getInputProps("description"),

                        onChange: (value) =>
                            setValues({ description: value.substring(0, 500) }),
                    }}
                    component={InputTextArea}
                    className="mb0"
                />
                <p className="mb20">{values.description.length} / 500</p>
                <div className="label">{"Nội dung *"}</div>
                <CKEditor
                    editor={ClassicEditor}
                    data={getInputProps("content").value}
                    onReady={(editor: any) => {}}
                    onChange={(event: any, editor: any) => {
                        handleChange("content", editor.getData());
                    }}
                />
                <div className="label image">Thumbnail</div>
                <div className="group">
                    <ImageUploader
                        singleImage={true}
                        withIcon={false}
                        withPreview={true}
                        buttonText={translate("cms.selectImage")}
                        onChange={(e) => {
                            setPicture(e);
                        }}
                        imgExtension={[".jpg", ".png"]}
                        maxFileSize={5242880}
                    />
                </div>
                {!!picture && typeof picture === "string" && (
                    <div>
                        <img
                            src={picture}
                            alt=""
                            style={{ width: "100%", maxWidth: "500px" }}
                        />
                    </div>
                )}

                <div className="group" style={{ marginTop: "10px" }}>
                    <InputCheckbox
                        label={"Homepage (Giới thiệu)"}
                        name={"homepage"}
                        value={visibles[ECMSVisiblePage.HOME_PAGE.value]}
                        onChange={(value) =>
                            setVisibles((val: any) => ({
                                ...val,
                                [ECMSVisiblePage.HOME_PAGE.value]: value,
                            }))
                        }
                        onTouched={() => false}
                        className="wfull"
                    />
                    <InputCheckbox
                        label={"Tin tức"}
                        name={"news"}
                        value={visibles[ECMSVisiblePage.NEWS_PAGE.value]}
                        onChange={(value) =>
                            setVisibles((val: any) => ({
                                ...val,
                                [ECMSVisiblePage.NEWS_PAGE.value]: value,
                            }))
                        }
                        onTouched={() => false}
                        className="wfull"
                    />
                </div>
                <div className="groupButton">
                    <Button
                        label={translate("all.cancel")}
                        buttonType="danger"
                        onClick={
                            isCreate ? () => navigate("/cms/") : props.onClose
                        }
                    />
                    <Button
                        isLoading={isSubmitting}
                        label={
                            isCreate
                                ? translate("all.create")
                                : translate("all.update")
                        }
                        className="btnBan ml5"
                        type="submit"
                    />
                </div>
            </form>
        );
    })
);
