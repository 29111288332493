import React, { useState, memo, useMemo } from "react";
import {
    ITableStructureItem,
    Button,
    Icon,
    InputWraper,
    Table,
} from "../../../modules";
import { withIpoWraper } from "../wraper";
import { translate } from "../../../languages";
import { PopupWraper } from "../../../components/popup";
import "./FeedbackList.scss";
import { Ban } from "./ban";
import { CreateEdit } from "./CreateEdit";
import { InputToggleSwitch } from "../../../components/toggle-switch";
import { InputSelect, InputText } from "../../../components";
import { FeedbackService } from "../../../services/feedback/feedback.service";
import { Box } from "@mui/material";

export const FeedbackList = withIpoWraper(
    memo(() => {
        const [params, setParams] = useState<any>({});
        const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());

        const structure: ITableStructureItem[] = [
            {
                name: translate("feedback.partnerName"),
                key: "user.fullName",
                render: (item) => {
                    return item.adsUser.nickname || item.adsUser.fullName;
                },
            },
            {
                name: translate("feedback.userName"),
                key: "adsUser.fullName",
                render: (item) => {
                    return item.user.nickname || item.user.fullName;
                },
            },
            {
                name: translate("feedback.category"),
                key: "isPositive",
                render: (item) => {
                    return item.isPositive ? (
                        <Box sx={{ color: "success.main" }}>
                            <i className="fa-solid fa-thumbs-up"></i>
                        </Box>
                    ) : (
                        <Box sx={{ color: "error.main" }}>
                            <i className="fa-solid fa-thumbs-down"></i>
                        </Box>
                    );
                },
            },
            {
                name: translate("feedback.comment"),
                key: "feedbackBody",
            },
            {
                name: translate("feedback.orderCode"),
                key: "ipoOrder.ipoOrderCode",
            },
            {
                name: translate("feedback.status"),
                key: "isDisplay",
                render: (item) => {
                    return (
                        <div className="btnGroup">
                            <InputToggleSwitch
                                onChange={async (value) => {
                                    await FeedbackService.updateDisplay({
                                        ipoFeedbackId: item.ipoFeedbackId,
                                        isDisplay: value,
                                    });
                                    // setForceUpdateTable(Math.random());
                                }}
                                value={item.isDisplay}
                                onTouched={() => false}
                                label=""
                                name="active"
                            />
                        </div>
                    );
                },
            },
        ];

        const tableMemo = useMemo(
            () => (
                <Table
                    structure={structure}
                    fetchData={(paramsTable) => {
                        setParams(paramsTable);
                        return FeedbackService.getFeedbackList({
                            ...params,
                            offset: paramsTable.pageNumber,
                            pageSize: paramsTable.limit,
                        });
                    }}
                />
            ),
            [params]
        );

        return (
            <div className="UserList">
                <FilterComponent
                    onFilter={(newFilter: any) => setParams(newFilter)}
                />

                {tableMemo}
            </div>
        );
    })
);

const FilterComponent = ({ onFilter }: any) => {
    const [filter, setFilter] = useState<any>({});
    const optionStatus = FeedbackService.getListFeedbackStatus();
    const optionCategory = FeedbackService.getListFeedbackCategory();

    return (
        <>
            <div className="group">
                <div className="wfull mr5">
                    <div className="label">{translate("all.keyword")}</div>
                    <InputWraper
                        inputProps={{
                            onChange: (e) =>
                                setFilter({ ...filter, searchKey: e }),
                            value: filter.searchKey,
                            name: "searchKey",
                            onTouched: () => false,
                        }}
                        component={InputText}
                        placeholder="Tìm kiếm theo nickname đối tác, nickname user đánh giá, Mã giao dịch"
                    />
                </div>

                <div className="wfull mr5">
                    <div className="label">
                        {translate("feedback.category")}
                    </div>
                    <InputSelect
                        options={optionCategory}
                        onChange={(e) =>
                            setFilter({ ...filter, isPositive: e })
                        }
                        onTouched={() => false}
                        value={filter.isPositive}
                        name="status"
                    />
                </div>
                <div className="wfull mr5">
                    <div className="label">{translate("feedback.status")}</div>
                    <InputSelect
                        options={optionStatus}
                        onChange={(e) => setFilter({ ...filter, isDisplay: e })}
                        onTouched={() => false}
                        value={filter.isDisplay}
                        name="status"
                    />
                </div>
            </div>
            <div>
                <div className="btnGroup mb20">
                    <Button
                        label={translate("all.search")}
                        onClick={() => onFilter(filter)}
                    />
                    <span
                        className="btn btn__edit"
                        onClick={() => {
                            setFilter(defaultFilter);
                            onFilter(defaultFilter);
                        }}
                    >
                        <Icon.Remove />
                    </span>
                </div>
            </div>
        </>
    );
};

const defaultFilter = {
    searchKey: "",
    isPositive: "",
    isDisplay: "",
};
