import React, { useMemo, useState } from "react";
import XLSX from "xlsx";
import {
    Icon,
    Button,
    CreateAlert,
    ITableStructureItem,
    InputWraper,
    Table,
    DateTimeUtils,
} from "../../../modules";
import { withTradingWraper } from "../wraper";
import { getLocaleKey, translate } from "../../../languages";
import { OrderService } from "../../../services/order/order.service";
import "./index.scss";
import { PopupWraper } from "../../../components/popup";
import { OrderDetail } from "../detail";
import {
    InputDateTimeRange,
    InputNumberRange,
    InputSelect,
    InputText,
} from "../../../components";
import {
    tradingGetOptionLabel,
    tradingOptionsStatus,
    tradingOptionsType,
    tradingStatus,
} from "../enumTrading";

let timer: any;
export const PageTradingTable = withTradingWraper(() => {
    const [params, setParams] = useState<any>({});
    const [dataDetail, setDataDetail] = useState<any>(null);
    const [forceUpdateTable, setForceUpdateTable] = useState(false);
    const optionsStatus = useMemo(() => tradingOptionsStatus(), []);

    const structure: ITableStructureItem[] = [
        {
            name: translate("trading.code"),
            key: "code",
            render: (item) => (
                <a onClick={() => getDetailOrder(item)}>{item.code}</a>
            ),
        },
        {
            name: "IPO",
            key: "ipo",
            render: (item) => item.ads?.ipo?.name,
        },
        {
            name: translate("trading.orderDate"),
            key: "created",
            render: (item) => DateTimeUtils.formatToString(item.created),
        },
        {
            name: "Email",
            key: "email",
        },
        {
            name: translate("user.phone"),
            key: "phoneNumber",
            render: (item) => item.phoneNumber || "",
        },
        {
            name: translate("all.amount"),
            key: "amount",
            render: (item) =>
                (+item.amount).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                }),
        },
        {
            name: translate("all.price"),
            key: "price",
            render: (item) =>
                (+item.price).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                }),
        },
        {
            name: translate("all.totalPrice"),
            key: "totalPrice",
            render: (item) =>
                (+item.totalPrice).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                }),
        },
        {
            name: translate("user.status"),
            key: "status",
            render: (item, fetchData) => {
                return (
                    <div>
                        {item.status === tradingStatus.COMPLAINING ? (
                            <InputSelect
                                isClearable={false}
                                name="status"
                                value={item.status}
                                options={optionsStatus.filter((item) =>
                                    [
                                        tradingStatus.CANCELLED,
                                        tradingStatus.SUCCESSFUL,
                                        tradingStatus.COMPLAINING,
                                    ].includes(item.value)
                                )}
                                onChange={(e) => {
                                    OrderService.updateOrderStatus(item.code, {
                                        status: e,
                                    }).then((res) => {
                                        if (res.success) {
                                            fetchData();
                                        } else {
                                            CreateAlert({
                                                type: "danger",
                                                message: res.message,
                                            });
                                        }
                                    });
                                }}
                                onTouched={() => false}
                            />
                        ) : (
                            tradingGetOptionLabel("status", item.status)?.label
                        )}
                    </div>
                );
            },
        },
        {
            name: translate("all.type"),
            key: "type",
            render: (item) => {
                const temp = tradingGetOptionLabel("type", item.type);
                const className = temp
                    ? temp.value === "SELL"
                        ? "successColor"
                        : "errorColor"
                    : "";

                return <span className={className}>{temp?.label}</span>;
            },
        },
        {
            name: translate("all.actions"),
            key: "actions",
            render: (item) => {
                return (
                    <div className="btnGroup">
                        <span
                            className="btn btn__edit"
                            onClick={() => getDetailOrder(item)}
                        >
                            <Icon.Visible />
                        </span>
                    </div>
                );
            },
        },
    ];

    const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
            try {
                const response = await getListOrders(params);
                const data = response.data;

                const fileHead = structure.map((v) => v.name);
                const dataExport = [
                    fileHead,
                    ...data.map((item: any) =>
                        structure.map((column, index) => {
                            if (!column.key) return "";

                            if (column.key === "userFullName") {
                                return item.ads?.userFullName;
                            } else if (column.key === "ipo")
                                return item.ads?.ipo?.name;

                            return item[column.key];
                        })
                    ),
                ];

                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                const now = new Date();
                XLSX.writeFile(
                    wb,
                    `User List ${now
                        .toLocaleDateString()
                        .replace(/\//g, "-")} ${now
                        .toLocaleTimeString()
                        .replace(/:/g, "-")}.xlsx`,
                    { type: "binary" }
                );

                resolve(
                    CreateAlert({
                        type: "success",
                        message: "Export data success.",
                    })
                );
            } catch (error: any) {
                resolve(
                    CreateAlert({ type: "danger", message: error.message })
                );
            }
        });
    };

    const getDetailOrder = async (item: any) => {
        if (item.code) {
            return new Promise(async (resolve) => {
                try {
                    const res = await OrderService.getDetailOrder(item.code);
                    if (res && res.result) {
                        setDataDetail(res.result);
                    }
                } catch (error: any) {
                    resolve(
                        CreateAlert({ type: "danger", message: error.message })
                    );
                }
            });
        }
        return false;
    };

    const getListOrders = (item: any) => {
        setParams(item);
        return OrderService.getListOrders({
            page: item.pageNumber,
            numberOfIPOOrderPerPage: item.limit,
            ...item,
        });
    };

    const tableMemo = useMemo(
        () => (
            <Table
                structure={structure}
                fetchData={async (paramsTable) => {
                    return getListOrders({ ...params, ...paramsTable });
                }}
            />
        ),
        [forceUpdateTable, setParams]
    );

    return (
        <div className="TradingList">
            <Button
                label="Export to Excel"
                buttonType="success"
                className="mb15"
                onClick={() => handleExportExcel()}
            />

            <FilterComponent
                onFilter={(item: any) => {
                    setParams({ ...params, ...item });
                    setForceUpdateTable(!forceUpdateTable);
                }}
            />

            {tableMemo}

            {dataDetail && (
                <PopupWraper
                    center
                    title={translate("all.detail")}
                    onClose={() => setDataDetail(null)}
                >
                    <OrderDetail
                        data={dataDetail}
                        onClose={() => {
                            setDataDetail(null);
                        }}
                    />
                </PopupWraper>
            )}
        </div>
    );
});

const FilterComponent = ({ onFilter }: any) => {
    const [filter, setFilter] = useState(defaultFilter);
    const optionStatus = useMemo(() => tradingOptionsStatus(), []);
    const optionType = useMemo(() => tradingOptionsType(), []);

    const handleChangeFilter = (newFilter: any, ms: number = 0) => {
        setFilter({ ...filter, ...newFilter });

        clearTimeout(timer);
        timer = setTimeout(() => {
            onFilter({
                ...filter,
                ...newFilter,
                fromDate: newFilter.fromDate
                    ? DateTimeUtils.dateToYMD(newFilter.fromDate)
                    : null,
                toDate: newFilter.toDate
                    ? DateTimeUtils.dateToYMD(newFilter.toDate)
                    : null,
            });
        }, ms);
    };

    return (
        <>
            <div className="group">
                <div className="wfull mr5">
                    <div className="label">{translate("all.search")}</div>
                    <InputWraper
                        inputProps={{
                            onChange: (value) =>
                                handleChangeFilter(
                                    { ...filter, keyword: value },
                                    300
                                ),
                            value: filter.keyword,
                            name: "keyword",
                            onTouched: () => false,
                        }}
                        component={InputText}
                        placeholder={translate("trading.searchText")}
                    />
                </div>

                <div className="wfull mr5">
                    <div className="label">{translate("user.status")}</div>
                    <InputSelect
                        options={optionStatus}
                        onChange={(value) =>
                            handleChangeFilter({
                                ...filter,
                                ipoOrderStatus: value,
                            })
                        }
                        onTouched={() => false}
                        value={filter.ipoOrderStatus}
                        name="ipoOrderStatus"
                    />
                </div>

                <div className="wfull mr5">
                    <div className="label">{translate("all.type")}</div>
                    <InputSelect
                        options={optionType}
                        onChange={(value) =>
                            handleChangeFilter({
                                ...filter,
                                ipoOrderType: value,
                            })
                        }
                        onTouched={() => false}
                        value={filter.ipoOrderType}
                        name="ipoOrderType"
                    />
                </div>
            </div>

            <div className="group">
                <div className="wfull">
                    <div className="label">
                        {" "}
                        {translate("trading.orderDate")}
                    </div>
                    <InputDateTimeRange
                        onChange={(e) => {
                            handleChangeFilter({
                                fromDate: e?.startTime,
                                toDate: e?.endTime,
                            });
                        }}
                        startTimeDefaultValue={filter.fromDate}
                        endTimeDefaultValue={filter.toDate}
                    />
                </div>
                <div className="wfull">
                    <div className="label">
                        {" "}
                        {translate("trading.totalAmount")}
                    </div>
                    <InputNumberRange
                        values={{
                            fromNum: filter.fromTotalPrice,
                            toNum: filter.toTotalPrice,
                        }}
                        onChange={(e) =>
                            handleChangeFilter(
                                {
                                    fromTotalPrice: e?.fromNum,
                                    toTotalPrice: e?.toNum,
                                },
                                300
                            )
                        }
                        onTouched={() => false}
                    />
                </div>
            </div>
        </>
    );
};

const defaultFilter = {
    keyword: "",
    ipoOrderType: "",
    ipoOrderStatus: "",
    fromDate: undefined,
    toDate: undefined,
    fromTotalPrice: undefined,
    toTotalPrice: undefined,
};
