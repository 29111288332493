import { translate } from "../../languages";

export const EFAQCustomerType: any = {
    BEGINNER: { value: "BEGINNER", label: "Người mới bắt đầu" },
    TRADER: {
        value: "TRADER",
        label: "Người quảng cáo",
    },
    ADVANCE: {
        value: "ADVANCE",
        label: "Nâng cao",
    },
};
export const EFAQCustomerTypeList = [
    {
        value: "BEGINNER",
        label: "Người mới bắt đầu",
    },
    {
        value: "TRADER",
        label: "Người quảng cáo",
    },
    {
        value: "ADVANCE",
        label: "Nâng cao",
    },
];

export const EFAQFeatureType: any = {
    ACCOUNT: {
        value: "ACCOUNT",
        label: "Tài khoản",
    },
    BUY_IPO: {
        value: "BUY_IPO",
        label: "Mua IPO",
    },
    SELL_IPO: {
        value: "SELL_IPO",
        label: "Bán IPO",
    },
    REGISTER: {
        value: "REGISTER",
        label: "Đăng ký",
    },
    SECURITY: {
        value: "SECURITY",
        label: "Bảo mật",
    },
    KYC: {
        value: "KYC",
        label: "Xác minh KYC",
    },
    PAYMENT: {
        value: "PAYMENT",
        label: "Phương thức thanh toán",
    },
    PUBLISH_ADS: {
        value: "PUBLISH_ADS",
        label: "Đăng quảng cáo",
    },
    SAFETY_BOX: {
        value: "SAFETY_BOX",
        label: "Két sắt",
    },
    SUPPORT: {
        value: "SUPPORT",
        label: "Hỗ trợ",
    },
};

export const EFAQFeatureTypeList = [
    {
        value: "ACCOUNT",
        label: "Tài khoản",
    },
    {
        value: "BUY_IPO",
        label: "Mua IPO",
    },
    {
        value: "SELL_IPO",
        label: "Bán IPO",
    },
    {
        value: "REGISTER",
        label: "Đăng ký",
    },
    {
        value: "SECURITY",
        label: "Bảo mật",
    },
    {
        value: "KYC",
        label: "Xác minh KYC",
    },
    {
        value: "PAYMENT",
        label: "Phương thức thanh toán",
    },
    {
        value: "PUBLISH_ADS",
        label: "Đăng quảng cáo",
    },
    {
        value: "SAFETY_BOX",
        label: "Két sắt",
    },
    {
        value: "SUPPORT",
        label: "Hỗ trợ",
    },
];

export const EFAQStatus: any = {
    ACTIVE: {
        value: "ACTIVE",
        label: "Hoạt động",
    },
    INACTIVE: {
        value: "INACTIVE",
        label: "không hoạt động",
    },
};
