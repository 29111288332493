import React, { FC, useState } from "react";
import "./index.scss";
import {
    Button,
    useForm,
    InputWraper,
    CreateAlert,
    Regex,
} from "../../../../modules";
import { translate } from "../../../../languages";
import * as Yup from "yup";
import { InputDate, InputText } from "../../..";
import { AdminService } from "../../../../services/admin";

export const CreateEditUser: FC<{ data: any; onFinishEdit: () => void }> = (
    props
) => {
    const {
        email,
        phoneNumber,
        fullName,
        address,
        postCode,
        birthday,
        userId,
        nationalId,
        city,
        password,
    } = props.data;
    const [valueBirth, setValueBirth] = useState(birthday);
    const { handleSubmit, getInputProps, isSubmitting } = useForm({
        structure: [
            {
                name: "email",
                defaultValue: email,
                validate: Yup.string()
                    .required(translate("validate.mustProvided"))
                    .matches(Regex.email, translate("validate.emailRegex")),
                isDisabled: true,
            },
            {
                name: "fullName",
                defaultValue: fullName,
                validate: Yup.string().required(
                    translate("validate.mustProvided")
                ),
            },
            {
                name: "password",
                defaultValue: password,
                validate: Yup.string().matches(
                    Regex.password,
                    translate("validate.passwordRegex")
                ),
            },
            {
                name: "phoneNumber",
                defaultValue: phoneNumber || "",
                isDisabled: true,
            },
            {
                name: "nationalId",
                defaultValue: nationalId || "",
                validate: Yup.string().required(
                    translate("validate.mustProvided")
                ),
            },
            {
                name: "address",
                defaultValue: address || "",
            },
            {
                name: "postCode",
                defaultValue: postCode || "",
            },
            {
                name: "city",
                defaultValue: city || "",
            },
        ],
        onSubmit: async (values) => {
            return AdminService.updateProfileAdmin({
                birthday: valueBirth,
                id: userId,
                fullName: values.fullName,
                nationalId: values.nationalId,
                address: values.address,
                postCode: values.postCode,
                city: values.city,
            })
                .then(() => {
                    CreateAlert({
                        message: translate("all.updateSuccess"),
                        type: "success",
                    });
                    props.onFinishEdit();
                })
                .catch((error) => {
                    CreateAlert({
                        message: error.message,
                        type: "danger",
                    });
                });
        },
    });

    return (
        <form className="Edit" onSubmit={handleSubmit}>
            <InputWraper
                label={translate("user.email")}
                inputProps={getInputProps("email")}
                component={InputText}
            />
            <InputWraper
                label={translate("user.fullname")}
                inputProps={getInputProps("fullName")}
                component={InputText}
            />

            <div className="group">
                <InputWraper
                    label={translate("user.phone")}
                    inputProps={getInputProps("phoneNumber")}
                    component={InputText}
                    className="mr5"
                />
                <InputWraper
                    label={translate("user.cmnd")}
                    inputProps={getInputProps("nationalId")}
                    component={InputText}
                    className="ml5"
                />
            </div>
            <div className="group">
                <div className="wfull">
                    <div className="label">{translate("user.birthdate")}</div>
                    <InputDate
                        onChange={(e) => setValueBirth(e)}
                        onTouched={() => false}
                        value={valueBirth}
                        name="date"
                    />
                </div>
                <InputWraper
                    label={translate("user.city")}
                    inputProps={getInputProps("city")}
                    component={InputText}
                    className="ml5"
                />
            </div>

            <div className="group">
                <InputWraper
                    label={translate("user.address")}
                    inputProps={getInputProps("address")}
                    component={InputText}
                    className="mr5"
                />
                <InputWraper
                    label={translate("user.postcode")}
                    inputProps={getInputProps("postCode")}
                    component={InputText}
                    className="ml5"
                />
            </div>

            <div>
                <Button
                    isLoading={isSubmitting}
                    label={translate("all.update")}
                    isMiddle
                    className="btnBan"
                    type="submit"
                />
            </div>
        </form>
    );
};
