import React, { FC } from "react";
import { Icon } from "../icon";
import { useNavigate } from "react-router-dom";

export const PageHead: FC<{ urlPath?: string; title: string }> = (props) => {
    const navigate = useNavigate();

    return (
        <div className="PageHead">
            <div className="title">
                <div
                    className="btnBack"
                    onClick={() =>
                        props.urlPath ? navigate(props.urlPath) : navigate(-1)
                    }
                >
                    <Icon.ArrowLeft />
                </div>

                {props.title}
            </div>
        </div>
    );
};
