import React, { useState, memo, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import XLSX from "xlsx";
import {
    Table,
    ITableStructureItem,
    CreateAlert,
    Button,
    Icon,
    InputWraper,
} from "../../../modules";
import "./FaqList.scss";
import { FaqService, FAQCategoryService } from "../../../services";
import { withFaqWraper } from "../wraper";
import { translate } from "../../../languages";
import { PopupWraper } from "../../../components/popup";
import { Ban } from "./ban";
import { InputSelect, InputText } from "../../../components";
import { EFAQStatus, EFAQVisiblePage } from "../enumFAQ";
import { CreateEditFaq } from "./CreateEdit";

export const FaqList = withFaqWraper(
    memo(() => {
        const navigate = useNavigate();
        const [params, setParams] = useState([] as any);
        const [editData, setEditData] = useState<any>(null);
        const [freezeDataId, setFreezeDataId] = useState<number | null>(null);
        const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());

        const [enableReinitialize, setEnableReinitialize] =
            useState<boolean>(false);
        const [datas, setDatas] = useState(null as any);

        const [titleValue, setTitleValue] = useState<any>(null);
        const [faqCategoryValue, setFaqCategoryValue] = useState<any>(null);
        const [statusValue, setStatusValue] = useState<any>(null);

        const [optionFaqCategory, setOptionFaqCategory] = useState([]);

        const getFAQCategoryList = async () => {
            const res = await FAQCategoryService.getFAQCategoryList({});
            if (res.data) {
                setOptionFaqCategory(
                    res.data.map((item: any) => {
                        return {
                            label: item.name,
                            value: item.faqCategoryId,
                        };
                    })
                );
            }
        };

        useEffect(() => {
            getFAQCategoryList();
        }, []);

        useEffect(() => {
            if (params && params.limit && params.pageNumber) {
                FaqService.getFaqList({
                    page: params.pageNumber,
                    numberOfItemsPerPage: params.limit,
                    title: titleValue || "",
                    faqCategoryId: faqCategoryValue || "",
                    status: statusValue || "",
                })
                    .then((res) => {
                        if (params.pageNumber === 1) {
                            setEnableReinitialize(true);
                        }
                        setDatas({
                            count: res.count,
                            data: res.data,
                        });
                    })
                    .catch((err) => setDatas({ error: err.error }));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            forceUpdateTable,
            // faqCategoryValue,
            params.limit,
            params.pageNumber,
            // titleValue,
        ]);

        useEffect(() => {
            setEnableReinitialize(false);
        }, [datas]);

        const getDetailFaq = async (item: any) => {
            const res = await FaqService.getFaq(item.faqId);
            setEditData({
                ...res.result,
                isCreate: false,
            });
        };

        const structure: ITableStructureItem[] = [
            {
                name: translate("faq.category"),
                key: "faqCategory.name",
            },
            {
                name: translate("faq.title"),
                key: "title",
            },
            {
                name: "Trang hiển thị",
                key: "visibleOnPages",
                render: (item) => {
                    return (
                        <div>
                            {item.visibleOnPages?.map((visiblePage: any) => {
                                return (
                                    <p>{EFAQVisiblePage[visiblePage].label}</p>
                                );
                            })}
                        </div>
                    );
                },
            },
            {
                name: "Trạng thái",
                key: "status",
                render: (item) => {
                    return EFAQStatus[item.status]?.label;
                },
            },
            {
                name: translate("all.actions"),
                key: "actions",
                render: (item) => {
                    return (
                        <div className="btnGroup">
                            <span
                                className="btn btn__edit"
                                onClick={() => getDetailFaq(item)}
                            >
                                <Icon.Edit />
                            </span>
                            <span
                                className="btn btn__ban"
                                onClick={() => setFreezeDataId(item.faqId)}
                            >
                                <Icon.Trash />
                            </span>
                        </div>
                    );
                },
            },
        ];

        const handleExportExcel = async () => {
            return new Promise(async (resolve) => {
                try {
                    const response = await FaqService.getFaqList({
                        page: params.pageNumber,
                        numberOfItemsPerPage: params.limit,
                        title: titleValue || "",
                        faqCategoryId: faqCategoryValue || "",
                        status: statusValue || "",
                    });

                    const data = response.data;

                    const fileHead = structure.map((v) => v.name);
                    const dataExport = [
                        fileHead,
                        ...data.map((item: any) =>
                            structure.map((column, index) => {
                                if (!column.key) return "";
                                return item[column.key];
                            })
                        ),
                    ];

                    const ws = XLSX.utils.aoa_to_sheet(dataExport);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                    const now = new Date();
                    XLSX.writeFile(
                        wb,
                        `Faq List ${now
                            .toLocaleDateString()
                            .replace(/\//g, "-")} ${now
                            .toLocaleTimeString()
                            .replace(/:/g, "-")}.xlsx`,
                        { type: "binary" }
                    );

                    resolve(
                        CreateAlert({
                            type: "success",
                            message: "Export data success.",
                        })
                    );
                } catch (error: any) {
                    resolve(
                        CreateAlert({ type: "danger", message: error.message })
                    );
                }
            });
        };

        const tableMemo = useMemo(() => {
            return (
                <Table
                    structure={structure}
                    fetchData={async (params) => {
                        setParams(params);

                        return FaqService.getFaqList({
                            page: params.pageNumber,
                            numberOfItemsPerPage: params.limit,
                            title: titleValue || "",
                            faqCategoryId: faqCategoryValue || "",
                            status: statusValue || "",
                        });
                    }}
                />
            );
        }, [forceUpdateTable]);

        return (
            <div className="FaqList">
                <Button
                    className="mb20"
                    label={translate("faq.create")}
                    buttonType="success"
                    onClick={() => navigate("/faq/new")}
                />

                <div className="group mb20">
                    <div className="itemSelect wfull mr5">
                        <div className="label">Loại câu hỏi</div>
                        <InputSelect
                            options={optionFaqCategory}
                            onChange={(e) => {
                                setFaqCategoryValue(e);
                            }}
                            onTouched={() => false}
                            value={faqCategoryValue}
                            name="faqCategoryValue"
                        />
                    </div>

                    <div className="itemSelect wfull mr5">
                        <div className="label">Trạng thái</div>
                        <InputSelect
                            options={Object.values(EFAQStatus)}
                            onChange={(e) => setStatusValue(e)}
                            onTouched={() => false}
                            value={statusValue}
                            name="isActived"
                        />
                    </div>
                    <div className="itemSelect wfull mr5">
                        <div className="label">Tìm kiếm</div>
                        <InputWraper
                            inputProps={{
                                name: "titleValue",
                                onChange: (e) => {
                                    setTitleValue(e);
                                },
                                onTouched: () => false,
                                value: titleValue,
                            }}
                            placeholder="Tìm kiếm theo Tiêu đề"
                            renderInput={InputText}
                        />
                    </div>

                    <div>
                        <div className="btnGroup">
                            <Button
                                label={translate("all.search")}
                                onClick={() => setForceUpdateTable(Math.random)}
                            />
                            <span
                                className="btn btn__edit"
                                onClick={() => {
                                    setTitleValue("");
                                    setFaqCategoryValue(null);
                                    setStatusValue(null);
                                }}
                            >
                                <Icon.Remove />
                            </span>
                        </div>
                    </div>
                </div>

                {tableMemo}
                {editData && (
                    <PopupWraper
                        center
                        title={
                            editData.isCreate
                                ? translate("faq.create")
                                : translate("faq.update")
                        }
                        onClose={() => setEditData(null)}
                    >
                        <CreateEditFaq
                            onClose={() => setEditData(null)}
                            data={editData}
                            onFinishEdit={() => {
                                setForceUpdateTable(Math.random);
                                setEditData(null);
                            }}
                        />
                    </PopupWraper>
                )}
                {freezeDataId && (
                    <PopupWraper
                        center
                        title={translate("faq.delete")}
                        onClose={() => setFreezeDataId(null)}
                    >
                        <Ban
                            userId={freezeDataId}
                            onFinishEdit={() => {
                                setForceUpdateTable(Math.random);
                                setFreezeDataId(null);
                            }}
                        />
                    </PopupWraper>
                )}
            </div>
        );
    })
);
