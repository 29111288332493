import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";

export const withTransactionWraper = (Component: FC) => (props: any) => {
    return (
        <div className="withTransactionWraper">
            <div className="TabLinks">
                <NavLink to={Routes.transactionList.path}>
                    <Icon.Exchange />
                    Transactions
                </NavLink>

                <NavLink to={Routes.transactionDemoList.path}>
                    <Icon.Exchange />
                    Transactions Demo
                </NavLink>

                <NavLink to={Routes.withdraw.path}>
                    <Icon.KYC />
                    Withdraw Progress
                </NavLink>
            </div>

            <Component {...props} />
        </div>
    );
};
