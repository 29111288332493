import { translate } from "../../languages";

export const Permissions = {
    // Account
    LOGIN_ADMIN: "LOGIN_ADMIN",

    // User
    VIEW_USERS: "VIEW_USERS",
    MODIFY_USER: "MODIFY_USER",

    // Profile
    VIEW_PROFILE: "VIEW_PROFILE",
    UPDATE_PROFILE: "UPDATE_PROFILE",

    // Kyc
    KYC_REVIEW: "KYC_REVIEW",

    // IPO
    VIEW_IPO: "VIEW_IPO",
    MODIFY_IPO: "MODIFY_IPO",

    // Ads
    MODIFY_ADS: "MODIFY_ADS",

    // Payment (thông tin thanh toán)
    MODIFY_PAYMENT: "MODIFY_PAYMENT",
    VIEW_PAYMENT_INFO: "VIEW_PAYMENT_INFO",

    // Role
    VIEW_ROLE: "VIEW_ROLE",
    MODIFY_ROLE: "MODIFY_ROLE",

    // Permission
    VIEW_PERMISSION: "VIEW_PERMISSION",
    UPDATE_PERMISSION: "UPDATE_PERMISSION",

    // IPO Order
    VIEW_IPO_ORDER: "VIEW_IPO_ORDER",
    MODIFY_IPO_ORDER: "MODIFY_IPO_ORDER",
    MODIFY_RELOAD_ORDER: "MODIFY_RELOAD_ORDER",

    // Feedback
    MODIFY_IPO_FEEDBACK: "MODIFY_IPO_FEEDBACK",

    // CMS Category
    MODIFY_CMS_CATEGORY: "MODIFY_CMS_CATEGORY",

    // CMS
    MODIFY_CMS: "MODIFY_CMS",

    // FAQ Category
    MODIFY_FAQ_CATEGORY: "MODIFY_FAQ_CATEGORY",

    // FAQ
    CREATE_FAQ: "CREATE_FAQ",
    UPDATE_FAQ: "UPDATE_FAQ",
    DELETE_FAQ: "DELETE_FAQ",

    // Notification (FCM)
    VIEW_PUSH_NOTIF: "VIEW_PUSH_NOTIF",
    MODIFY_PUSH_NOTIF: "MODIFY_PUSH_NOTIF",

    // System
    CHANGE_SERVER_CONFIG: "CHANGE_SERVER_CONFIG",
    SEND_MAIL: "SEND_MAIL",
    SET_SYSTEM_MAINTENANCE: "SET_SYSTEM_MAINTENANCE",

    // Others - Not use in this project attemptly
    LEADER: "LEADER",
    VIEW_TRANSACTIONS: "VIEW_TRANSACTIONS",
    VIEW_WITHDRAW_PROGRESS: "VIEW_WITHDRAW_PROGRESS",
    REVIEW_WITHDRAW: "REVIEW_WITHDRAW",
    VIEW_REPORT: "VIEW_REPORT",
    ENABLE_PARTNER: "ENABLE_PARTNER",
    UPDATE_GAME_SETTING: "UPDATE_GAME_SETTING",
    SET_RANK: "SET_RANK",
    VIEW_ORDERS: "VIEW_ORDERS",
};

// used route: roles/permissions
export const GroupPermission = () => [
    {
        title: "User",
        permission: [
            {
                label: translate("permission.view"),
                value: Permissions.VIEW_USERS,
            },
            {
                label: translate("permission.edit"),
                value: Permissions.MODIFY_USER,
            },
        ],
    },
    {
        title: translate("permission.titleRole"),
        permission: [
            {
                label: translate("permission.view"),
                value: Permissions.VIEW_ROLE,
            },
            {
                label: translate("permission.create"),
                value: Permissions.MODIFY_ROLE,
            },
        ],
    },
    {
        title: translate("permission.titlePermission"),
        permission: [
            {
                label: translate("permission.view"),
                value: Permissions.VIEW_PERMISSION,
            },
            {
                label: translate("permission.edit"),
                value: Permissions.UPDATE_PERMISSION,
            },
        ],
    },
    {
        title: translate("permission.titleIpo"),
        permission: [
            {
                label: translate("permission.view"),
                value: Permissions.VIEW_IPO,
            },
            {
                label: translate("permission.create"),
                value: Permissions.MODIFY_IPO,
            },
        ],
    },
    {
        title: translate("permission.titleAds"),
        permission: [
            {
                label: translate("permission.create"),
                value: Permissions.MODIFY_ADS,
            },
        ],
    },
    {
        title: translate("permission.titleReport"),
        permission: [
            {
                label: translate("permission.view"),
                value: Permissions.VIEW_REPORT,
            },
        ],
    },
    {
        title: translate("permission.titlePayment"),
        permission: [
            {
                label: translate("permission.create"),
                value: Permissions.MODIFY_PAYMENT,
            },
            {
                label: translate("permission.viewPaymentInfo"),
                value: Permissions.VIEW_PAYMENT_INFO,
            },
        ],
    },
    {
        title: translate("permission.titleCms"),
        permission: [
            {
                label: translate("permission.edit"),
                value: Permissions.MODIFY_CMS,
            },
        ],
    },
    {
        title: translate("permission.titleTransaction"),
        permission: [
            {
                label: translate("permission.view"),
                value: Permissions.VIEW_TRANSACTIONS,
            },
        ],
    },
    {
        title: translate("permission.titleFaqCategory"),
        permission: [
            {
                label: translate("permission.edit"),
                value: Permissions.MODIFY_FAQ_CATEGORY,
            },
        ],
    },
    {
        title: translate("permission.titleFaq"),
        permission: [
            {
                label: translate("permission.create"),
                value: Permissions.CREATE_FAQ,
            },
            {
                label: translate("permission.edit"),
                value: Permissions.UPDATE_FAQ,
            },
            {
                label: translate("permission.delete"),
                value: Permissions.DELETE_FAQ,
            },
        ],
    },
    {
        title: translate("permission.titleProfile"),
        permission: [
            {
                label: translate("permission.view"),
                value: Permissions.VIEW_PROFILE,
            },
            {
                label: translate("permission.edit"),
                value: Permissions.UPDATE_PROFILE,
            },
        ],
    },
    {
        title: translate("permission.titleIpoOrder"),
        permission: [
            {
                label: translate("permission.view"),
                value: Permissions.VIEW_IPO_ORDER,
            },
            {
                label: translate("permission.edit"),
                value: Permissions.MODIFY_IPO_ORDER,
            },
        ],
    },
    {
        title: translate("permission.titlePushNotif"),
        permission: [
            {
                label: translate("permission.view"),
                value: Permissions.VIEW_PUSH_NOTIF,
            },
            {
                label: translate("permission.edit"),
                value: Permissions.MODIFY_PUSH_NOTIF,
            },
        ],
    },
    {
        title: translate("permission.titleReloadOrder"),
        permission: [
            {
                label: translate("permission.toggle"),
                value: Permissions.MODIFY_RELOAD_ORDER,
            },
        ],
    },
    {
        title: translate("permission.titleFeedback"),
        permission: [
            {
                label: translate("permission.toggleFeedback"),
                value: Permissions.MODIFY_IPO_FEEDBACK,
            },
        ],
    },
    {
        title: translate("permission.titleOther"),
        permission: [
            {
                label: translate("permission.loginAdmin"),
                value: Permissions.LOGIN_ADMIN,
            },
            {
                label: translate("permission.viewWithdraw"),
                value: Permissions.VIEW_WITHDRAW_PROGRESS,
            },
            {
                label: translate("permission.reviewWithdraw"),
                value: Permissions.REVIEW_WITHDRAW,
            },
            {
                label: translate("permission.changeServerConfig"),
                value: Permissions.CHANGE_SERVER_CONFIG,
            },
            {
                label: translate("permission.systemMaintenance"),
                value: Permissions.SET_SYSTEM_MAINTENANCE,
            },
        ],
    },
];
