import React, { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { translate } from "../../languages";

export const withUserWraper = (Component: FC) => (props: any) => {
    const [checkActive] = useActiveTabLinks()

    return (
        <div className="withUserWraper">
            <div className="TabLinks">
                <NavLink to={Routes.userList.path}
                    className={() => checkActive(Routes.userList.path)}
                >
                    <Icon.User />
                    {translate("user.list")}
                </NavLink>

                <NavLink to={Routes.userKYCList.path}
                    className={() => checkActive(Routes.userKYCList.path)}
                >
                    <Icon.KYC />
                    KYC List
                </NavLink>
            </div>

            <Component {...props} />
        </div>
    );
};

const useActiveTabLinks = () => {
    const location = useLocation()
    const checkActive = (route: any) => {
        if (!location) return "";
        const { pathname } = location;
        return pathname === route ? "active" : "";
    }
    return [checkActive]
}
