import React, { FC, useState } from "react";
import "./index.scss";
import {
    Button,
    useForm,
    InputWraper,
    CreateAlert,
    Icon,
} from "../../../../modules";
import { translate } from "../../../../languages";
import * as Yup from "yup";
import { InputText, InputCheckbox } from "../../../../components";
import { PaymentService } from "../../../../services";
import { ChromePicker } from "react-color";
import { PopupWraper } from "../../../../components/popup";

const defaultPaymentField = {
    fieldName: "",
    isRequired: false,
};

export const CreateEditPayment: FC<{
    data: any;
    onFinishEdit: () => void;
}> = (props) => {
    const {
        paymentId,
        name,
        color,
        isActived,
        isCreate,
        isPriority = false,
        isDefault = true,
    } = props.data;
    const [valueCheckbox, setValueCheckbox] = useState(isActived);
    const [priority, setPriority] = useState(isPriority);
    const [valueDefault, setDefault] = useState(isDefault);
    const [paymentFields, setPaymentFields]: any = useState(
        props.data.paymentFields || []
    );
    const [displayColorPicker, setdisplayColorPicker] = useState(false);

    const handleClose = () => {
        setdisplayColorPicker(false);
    };
    const handleOpen = () => {
        setdisplayColorPicker(true);
    };

    const handleChangeComplete = (e: any) => {
        if (e.hex !== values.color) {
            setValues({ color: e.hex });
        }
    };

    const { handleSubmit, getInputProps, isSubmitting, values, setValues } =
        useForm({
            structure: [
                {
                    name: "name",
                    defaultValue: name,
                    validate: Yup.string().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "color",
                    defaultValue: color,
                    validate: Yup.string().required(
                        translate("validate.mustProvided")
                    ),
                    isDisabled: true,
                },
            ],

            onSubmit: async (values) => {
                if (isCreate) {
                    return PaymentService.createPayment({
                        name: values.name,
                        color: values.color,
                        isActived: valueCheckbox,
                        isPriority: priority,
                        isDefault: valueDefault,
                        paymentFields,
                    })
                        .then((res) => {
                            if (!res.success) {
                                return CreateAlert({
                                    message: res.message,
                                    type: "danger",
                                });
                            }
                            CreateAlert({
                                message: translate("all.createSuccess"),
                                type: "success",
                            });
                            props.onFinishEdit();
                        })
                        .catch((error) => {
                            CreateAlert({
                                message: error.message,
                                type: "danger",
                            });
                        });
                }
                return PaymentService.updatePayment({
                    paymentId: paymentId,
                    name: values.name,
                    color: values.color,
                    isActived: valueCheckbox,
                    isPriority: priority,
                    isDefault: valueDefault,
                    paymentFields,
                })
                    .then(() => {
                        CreateAlert({
                            message: translate("all.updateSuccess"),
                            type: "success",
                        });
                        props.onFinishEdit();
                    })
                    .catch((error) => {
                        CreateAlert({
                            message: error.message,
                            type: "danger",
                        });
                    });
            },
        });

    return (
        <form className="EditPayment edit" onSubmit={handleSubmit}>
            <InputWraper
                label={translate("payment.name")}
                inputProps={getInputProps("name")}
                component={InputText}
            />
            <div className="group">
                <InputWraper
                    label={translate("payment.color")}
                    inputProps={{
                        ...getInputProps("color"),
                    }}
                    component={InputText}
                />

                <div className="itemSelect wfull">
                    <Button
                        label={translate("payment.pickColor")}
                        onClick={handleOpen}
                    />
                    {displayColorPicker && (
                        <PopupWraper
                            center
                            title={translate("payment.pickColor")}
                            onClose={handleClose}
                        >
                            <ChromePicker
                                color={values.color || ""}
                                onChange={handleChangeComplete}
                            />
                        </PopupWraper>
                    )}
                </div>
            </div>

            <InputCheckbox
                label={translate("all.active")}
                onChange={(value) => setValueCheckbox(value)}
                name="active"
                value={valueCheckbox}
                onTouched={() => false}
            />
            <div className="group">
                <InputCheckbox
                    label={"Ưu tiên"}
                    onChange={(value) => setPriority(value)}
                    name="active"
                    value={priority}
                    onTouched={() => false}
                />
                <InputCheckbox
                    label={"Mặc định"}
                    onChange={(value) => setDefault(value)}
                    name="active"
                    value={valueDefault}
                    onTouched={() => false}
                />
            </div>
            <div>
                <Button
                    className="mb20"
                    label={"Thêm trường"}
                    buttonType="success"
                    onClick={
                        () =>
                            setPaymentFields((val: any) => [
                                ...val,
                                defaultPaymentField,
                            ])
                        // navigate(Routes.createPayment.path)
                    }
                />
            </div>
            {paymentFields.map((item: any, index: any) => {
                return (
                    <div className="group">
                        <div className="mr5">
                            <div className="label">
                                {translate("payment.name")}
                            </div>
                            <InputWraper
                                inputProps={{
                                    onChange: (e) =>
                                        setPaymentFields((val: any) => {
                                            val[index] = {
                                                ...val[index],
                                                fieldName: e,
                                            };
                                            return [...val];
                                        }),
                                    value: item.fieldName,
                                    name: "fieldName",
                                    onTouched: () => false,
                                }}
                                component={InputText}
                            />
                        </div>

                        <div className="ml5 mr5 wd120">
                            <InputCheckbox
                                label={"Bắt buộc"}
                                onChange={(value) =>
                                    setPaymentFields((val: any) => {
                                        val[index] = {
                                            ...val[index],
                                            isRequired: value,
                                        };
                                        return [...val];
                                    })
                                }
                                name="active"
                                value={item.isRequired}
                                onTouched={() => false}
                            />
                        </div>

                        <div className="ml5 mr5 wd20 btnGroup">
                            <span
                                className="btn btn__hover"
                                onClick={() => {
                                    setPaymentFields((val: any) => {
                                        return val.filter(
                                            (_: any, idx: any) => idx !== index
                                        );
                                    });
                                }}
                            >
                                <Icon.Remove />
                            </span>
                        </div>
                    </div>
                );
            })}
            <div>
                <Button
                    isLoading={isSubmitting}
                    label={
                        isCreate
                            ? translate("all.create")
                            : translate("all.update")
                    }
                    isMiddle
                    className="btnBan"
                    type="submit"
                />
            </div>
        </form>
    );
};
