export class DateTimeUtils {
    static timeToSeconds(time: any): number {
        time = new Date(time);
        return +Math.floor(time.getTime() / 1000).toFixed(0);
    }

    static secondsToTime(time: any) {
        if (!time) return;
        try {
            return new Date(time * 1000);
        } catch (error) {
            return;
        }
    }

    static formatToShow(seconds: number, isShowTime = true) {
        if (!seconds) return "--";
        const time = new Date(seconds * 1000);
        let hours = time.getHours();
        let min: any = time.getMinutes();
        min = min < 10 ? `0${min}` : min;
        if (!isShowTime) return `${time.toLocaleDateString("en-GB")}`;
        return `${time.toLocaleDateString("en-GB")} - ${hours}:${min}`;
    }
    static dateToYMD(date: any) {
        let dateConvert = new Date(date);
        let d = dateConvert.getDate();
        let m = dateConvert.getMonth() + 1;
        let y = dateConvert.getFullYear();
        return dateConvert;
    }

    static formatToString(date: any, format: string = "DD-MM-YYYY hh:mm:ss") {
        if (!date) return

        const dt = new Date(date);
        const day = (dt.getDate().toString().padStart(2, '0'));
        const month = (dt.getMonth() + 1).toString().padStart(2, '0');
        const year = (dt.getFullYear().toString().padStart(4, '0'));
        const hours = dt.getHours().toString().padStart(2, '0')
        const minutes = dt.getMinutes().toString().padStart(2, '0')
        const second = dt.getSeconds().toString().padStart(2, '0')

        return format.replace("DD", day).replace("MM", month)
            .replace("YYYY", year).replace("hh", hours)
            .replace("mm", minutes).replace("ss", second)
    }
}
