import { translate } from "../../languages";

export const adsEKyc = {
    COMPLETE_KYC: "COMPLETE_KYC",
    REGISTER_DAY_NUMBER: "REGISTER_DAY_NUMBER",
    BALANCE_BTC: "BALANCE_BTC",
};

export const adsPublishType = {
    ONLINE: "ONLINE",
    OFFLINE: "OFFLINE",
    DISABLED: "DISABLED",
};

export const AdsFormType = {
    VIEW: "VIEW",
    ADD: "ADD",
    EDIT: "EDIT",
};

export const adsOptionsPaymentLimitTime = () => {
    const sMinutes = translate("all.minutes");
    return [
        { label: `15 ${sMinutes}`, value: 15 },
        { label: `30 ${sMinutes}`, value: 30 },
        { label: `45 ${sMinutes}`, value: 45 },
        { label: `60 ${sMinutes}`, value: 60 },
    ];
};

export const adsOptionsType = () => [
    { value: "", label: translate("all.all") },
    { value: "BUY", label: translate("all.buy") },
    { value: "SELL", label: translate("all.sell") },
];

export const adsOptionsPublistType = () => [
    { label: translate("all.all"), value: "" },
    { label: translate("all.online"), value: adsPublishType.ONLINE },
    { label: translate("all.offline"), value: adsPublishType.OFFLINE },
    { label: translate("all.close"), value: adsPublishType.DISABLED },
];
