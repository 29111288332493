/* eslint-disable @typescript-eslint/no-unused-expressions */
import _ from "lodash";
import en from "./lang/en-US";
import vi from "./lang/vi-VN";
import id from "./lang/id-ID";
import ja from "./lang/ja-JP";
import ms from "./lang/ms-MY";
import th from "./lang/th-TH";
import zh from "./lang/zh-CN";
import { CookieService, ECookieVariable } from "./services/cookie";
import { ELocale } from "./types";

export type TLocale = {
    id: number;
    key: ELocale;
    label: string;
    isActive: boolean;
    dictionary: any;
};

export const locales: TLocale[] = [
    {
        id: 1,
        key: ELocale.ENGLISH,
        label: "English",
        isActive: true,
        dictionary: en,
    },

    {
        id: 2,
        key: ELocale.CHINA,
        label: "中文",
        isActive: true,
        dictionary: zh,
    },
    {
        id: 3,
        key: ELocale.JAPAN,
        label: "Japan",
        isActive: true,
        dictionary: ja,
    },
    {
        id: 4,
        key: ELocale.THAILAN,
        label: "Thailand",
        isActive: true,
        dictionary: th,
    },
    {
        id: 5,
        key: ELocale.INDONESIA,
        label: "Bahasa Indonesia",
        isActive: true,
        dictionary: id,
    },
    {
        id: 6,
        key: ELocale.MALAYSIA,
        label: "Bahasa Malaysia",
        isActive: true,
        dictionary: ms,
    },
    {
        id: 7,
        key: ELocale.VIETNAM,
        label: "Việt Nam",
        isActive: true,
        dictionary: vi,
    },
];

export const defaultLocale = locales[0];

export const setLocale = (locale: ELocale): void => {
    CookieService.set(ECookieVariable.USER_LOCALE, locale);
    window.location.reload();
};

export const getLocale = (): TLocale => {
    let locale: TLocale;

    const fromCookie = CookieService.get(ECookieVariable.USER_LOCALE);
    const currentLocale = locales.find((item) => item.key === fromCookie);
    if (!currentLocale) {
        locale = defaultLocale;
        CookieService.set(ECookieVariable.USER_LOCALE, locale.key);
    } else {
        locale = currentLocale;
    }

    return locale;
};

export const getLocaleKey = (): string => getLocale().key;

export const translate = (id: any, values?: any): string => {
    // const locale: string = getLocaleKey();
    const locale: string = "vi-VN";
    let sentence: string;
    let getDictionary = locales.find((item) => item.key === locale);
    // @ts-ignore
    if (getDictionary) {
        sentence = _.get(getDictionary?.dictionary, id);
    } else {
        console.warn(`Don't have any messages match with id: "${id}"`);
        return id;
    }
    // if (dictionary[id] && dictionary[id][locale]) {
    //     // @ts-ignore
    //     sentence = dictionary[id][locale];
    // }

    // Match values
    if (typeof values === "object") {
        (Object.entries(values) as any).map((item: any) => {
            // @ts-ignore
            sentence = sentence.replace(
                new RegExp(`{${item[0]}}`, "g"),
                item[1]
            );
            return item;
        });
    }
    return sentence;
};
