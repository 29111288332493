import React, { FC, useEffect, useMemo, useState } from "react";
import "./index.scss";
import * as Yup from "yup";
import { useForm, InputWraper, Button, CreateAlert } from "../../../../modules";
import { getLocaleKey, translate } from "../../../../languages";
import {
    InputCheckbox,
    InputNumber,
    InputNumberUpDown,
    InputSelect,
    InputSelectAsync,
    InputText,
    InputTextArea,
} from "../../../../components";
import { AdsService, IpoService } from "../../../../services";
import { AdminService } from "../../../../services/admin";
import {
    adsEKyc,
    adsOptionsPaymentLimitTime,
    adsOptionsType,
    adsPublishType,
    AdsFormType,
} from "../../enumAds";

let timer: any;
export const DetailAds: FC<{
    data: any;
    onFinishEdit: () => void;
    onClose: () => void;
}> = (props) => {
    const {
        formType,
        adsId,
        userId,
        userFullName,
        totalAmount,
        publishType,
        priceType,
        minimumOrderPrice,
        maximumOrderPrice,
        type,
        price,
        terms,
        autoReply,
        paymentLimitTime,
        ipo, // object
        fiat, // object
        paymentInfos, // array[object]
        conditions, // array[object]
    } = props.data;

    const optionsType = useMemo(() => adsOptionsType(), [getLocaleKey()]);
    const optionsPaymentLimitTime = useMemo(
        () => adsOptionsPaymentLimitTime(),
        [getLocaleKey()]
    );
    const isDisabled = formType === AdsFormType.VIEW ? true : false;

    const [maxPriceByIPO, setMaxPriceByIPO] = useState();
    const [optionsPaymentList, setOptionsPaymentList] = useState([]);
    const [optionsIPOList, setOptionsIPOList] = useState([]);

    const { handleSubmit, getInputProps, isSubmitting, setValues, values } =
        useForm({
            structure: [
                {
                    name: "id",
                    defaultValue: adsId,
                    isDisabled: formType === AdsFormType.ADD ? false : true,
                },
                {
                    name: "userId",
                    defaultValue: userId,
                    isDisabled: formType === AdsFormType.ADD ? false : true,
                    validate: Yup.number().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "publishType",
                    defaultValue: publishType,
                    isDisabled: true,
                },
                {
                    name: "priceType",
                    defaultValue: priceType,
                    isDisabled: true,
                },
                {
                    name: "minimumOrderPrice",
                    defaultValue: minimumOrderPrice,
                    isDisabled: isDisabled,
                    validate: Yup.number()
                        .positive(translate("validate.mustProvided"))
                        .required(translate("validate.mustProvided")),
                },
                {
                    name: "maximumOrderPrice",
                    defaultValue: maximumOrderPrice,
                    isDisabled: isDisabled,
                    validate: Yup.number()
                        .positive(translate("validate.mustProvided"))
                        .required(translate("validate.mustProvided")),
                },
                {
                    name: "type",
                    defaultValue: type,
                    isDisabled: isDisabled,
                    validate: Yup.string().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "price",
                    defaultValue: price,
                    isDisabled: isDisabled,
                    validate: Yup.number()
                        .positive(translate("validate.mustProvided"))
                        .required(translate("validate.mustProvided")),
                },
                {
                    name: "maxPriceByIPO",
                    defaultValue: 0,
                    isDisabled: true,
                },
                {
                    name: "totalQuantity",
                    defaultValue:
                        price > 0
                            ? Number((totalAmount * price).toFixed(3))
                            : 0,
                    isDisabled: true,
                },
                {
                    name: "totalAmount",
                    defaultValue: totalAmount,
                    isDisabled: isDisabled,
                    validate: Yup.number()
                        .positive(translate("validate.mustProvided"))
                        .required(translate("validate.mustProvided")),
                },
                {
                    name: "terms",
                    defaultValue: terms || "",
                    isDisabled: isDisabled,
                },
                {
                    name: "autoReply",
                    defaultValue: autoReply || "",
                    isDisabled: isDisabled,
                },
                {
                    name: "paymentInfoIds",
                    defaultValue: paymentInfos?.map(
                        (t: any) => t.paymentInfoId
                    ),
                    isDisabled: isDisabled,
                    validate: Yup.array().min(
                        1,
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "paymentLimitTime",
                    defaultValue: paymentLimitTime,
                    isDisabled: isDisabled,
                },
                {
                    name: "ipoId",
                    defaultValue: ipo?.ipoManagerId,
                    isDisabled: isDisabled,
                    validate: Yup.number().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "fiatId",
                    defaultValue: props.data?.fiatId || fiat?.fiatId,
                    isDisabled: isDisabled,
                },
                {
                    name: "fiatCode",
                    defaultValue: fiat?.code || "VND",
                    isDisabled: true,
                },
                {
                    name: "conditions",
                    defaultValue: conditions,
                    isDisabled: isDisabled,
                },
            ],
            validate: (items: any) => {
                const errors: any = {};

                if (
                    items.minimumOrderPrice > items.maximumOrderPrice ||
                    // items.totalQuantity < items.minimumOrderPrice ||
                    items.totalQuantity < items.maximumOrderPrice
                ) {
                    errors["minimumOrderPrice"] = translate(
                        "ads.validTotalAmount"
                    );
                    errors["maximumOrderPrice"] = translate(
                        "ads.validTotalAmount"
                    );
                }

                return errors;
            },
            onSubmit: async (items) => {
                if (formType === AdsFormType.ADD) {
                    delete items.id;
                    AdsService.createAds(items)
                        .then((res) => {
                            if (res.success) {
                                CreateAlert({
                                    message: translate("all.updateSuccess"),
                                    type: "success",
                                });
                                props.onFinishEdit();
                            } else {
                                CreateAlert({
                                    message: res.message,
                                    type: "danger",
                                });
                            }
                        })
                        .catch((error) => {
                            CreateAlert({
                                message: error.message,
                                type: "danger",
                            });
                        });
                } else if (formType === AdsFormType.EDIT) {
                    AdsService.updateAds(items)
                        .then((res) => {
                            if (res.success) {
                                CreateAlert({
                                    message: translate("all.updateSuccess"),
                                    type: "success",
                                });
                                props.onFinishEdit();
                            } else {
                                CreateAlert({
                                    message: res.message,
                                    type: "danger",
                                });
                            }
                        })
                        .catch((error) => {
                            CreateAlert({
                                message: error.message,
                                type: "danger",
                            });
                        });
                }
            },
        });

    useEffect(() => {
        if (formType === AdsFormType.ADD) {
            // default value when ADD
            getData();
        } else {
            // VIEW, EDIT
            getData([
                AdminService.getPaymentListByUser({ userId: userId }),
                AdsService.getReferencePrice({
                    id: adsId,
                    userId: userId,
                    ipoId: ipo?.ipoManagerId,
                    commlType: type,
                }),
            ]);
        }
    }, []);

    const getData = (promiseArr?: any) => {
        Promise.all(
            [
                IpoService.getListIpo({
                    isGetAll: true,
                    page: 1,
                    numberOfIPOPerPage: 1,
                    isActive: true,
                }),
            ].concat(promiseArr)
        )
            .then((datas: any) => {
                setOptionsIPOList(
                    datas[0]?.data?.map((t: any) => ({
                        value: t.ipoManagerId,
                        label: t.symbol,
                    }))
                );

                if (datas.length > 1) {
                    if (datas[1]?.success) {
                        const optionsPayment = datas[1].result?.map(
                            (item: any) => ({
                                value: item?.paymentInfoId,
                                label: item?.payment.name,
                            })
                        );
                        setOptionsPaymentList(optionsPayment);
                    }
                }

                if (datas.length > 2) {
                    if (datas[2]?.success) {
                        setMaxPriceByIPO(datas[2]?.result);
                    }
                }
            })
            .catch((err) => {
                CreateAlert({ type: "danger", message: err.message });
            });
    };

    const calcTotalAmount = ({ value, name }: any) => {
        let nTotalQuantity = 0;
        if (name === "price") {
            nTotalQuantity = value * values?.totalAmount;
        } else if (name === "totalAmount") {
            nTotalQuantity = value * values?.price;
        }
        setValues({ [name]: value, totalQuantity: nTotalQuantity });
    };

    const checkboxConditions = (type?: string) => {
        return values.conditions?.find((t: any) => t.type === type);
    };

    const getUserByKeyword = (inputValue: string) => {
        return new Promise<any>((resolve) => {
            if (inputValue.length >= 5) {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    AdminService.getListUser({
                        page: 1,
                        numberOfItemsPerPage: 10,
                        nickname: inputValue,
                        accountType: "BUSINESS",
                        isVerifiedKyc: true,
                    }).then((res: any) => {
                        const data = res.data?.map((t: any) => {
                            return {
                                value: t.userId,
                                label: t.fullName,
                            };
                        });
                        resolve(data);
                    });
                }, 300);
            } else {
                resolve([]);
            }
        });
    };

    const handleChangeIPO = (value: any) => {
        setValues({ ipoId: value });
        getMaxPriceIPO({ ipoId: value });
    };

    const handleChangeUser = (value: any) => {
        setValues({ userId: value, paymentInfoIds: [] });
        getMaxPriceIPO(
            { userId: value },
            AdminService.getPaymentListByUser({ userId: value })
        );
    };

    const handleChangeType = (value: any) => {
        setValues({ type: value });
        getMaxPriceIPO({ adsType: value });
    };

    const getMaxPriceIPO = (newPrams: any, promiseArr?: any) => {
        const params = {
            id: adsId,
            userId: values.userId,
            ipoId: values.ipoId,
            adsType: values.type,
        };

        Promise.all(
            [AdsService.getReferencePrice({ ...params, ...newPrams })].concat(
                promiseArr
            )
        )
            .then((res: any) => {
                if (res.length > 0 && res[0]?.success) {
                    setMaxPriceByIPO(res[0].result);
                }

                if (res[1]) {
                    if (res[1]?.success) {
                        const optionsPayment = res[1].result?.map(
                            (item: any) => ({
                                value: item?.paymentInfoId,
                                label: item?.payment.name,
                            })
                        );
                        setOptionsPaymentList(optionsPayment);
                    } else {
                        setOptionsPaymentList([]);
                    }
                }
            })
            .catch((error) => {
                CreateAlert({ type: "danger", message: error?.message });
            });
    };

    return (
        <form className="Edit" onSubmit={handleSubmit}>
            <div className="group">
                <InputWraper
                    label={translate("ads.userName")}
                    inputProps={getInputProps("userId")}
                    renderInput={(inputProps) => {
                        return (
                            <InputSelectAsync
                                {...inputProps}
                                onChange={handleChangeUser}
                                onTouched={() => false}
                                name="userId"
                                loadOptions={getUserByKeyword}
                                isDisabled={false}
                                defaultOptions={[
                                    {
                                        label: userFullName,
                                        value: userId,
                                    },
                                ]}
                                defaultValue={
                                    userId
                                        ? {
                                              label: userFullName,
                                              value: userId,
                                          }
                                        : null
                                }
                                placeholder="Nhập nickname user để chọn người đăng quảng cáo"
                            />
                        );
                    }}
                />
                {formType === AdsFormType.VIEW && (
                    <InputWraper
                        label={translate("ads.publishType")}
                        inputProps={getInputProps("publishType")}
                        component={InputText}
                        className="ml10"
                    />
                )}
            </div>

            <div className="textBoldItalic">{translate("ads.step1")}</div>

            <div className="group">
                <InputWraper
                    label={translate("all.type")}
                    inputProps={getInputProps("type")}
                    renderInput={(inputProps) => (
                        <InputSelect
                            {...inputProps}
                            options={optionsType}
                            onChange={handleChangeType}
                            onTouched={() => false}
                            name="type"
                        />
                    )}
                    className="mr5"
                />

                <InputWraper
                    label={translate("ads.selectFiat")}
                    inputProps={getInputProps("fiatCode")}
                    component={InputText}
                    className="ml5"
                />
            </div>

            <div className="group">
                <InputWraper
                    label={"IPO"}
                    inputProps={getInputProps("ipoId")}
                    renderInput={(inputProps) => (
                        <InputSelect
                            {...inputProps}
                            options={optionsIPOList}
                            onChange={handleChangeIPO}
                            onTouched={() => false}
                            name="ipoId"
                        />
                    )}
                />
            </div>

            <div className="group">
                <InputWraper
                    label={translate("ads.priceType")}
                    inputProps={{
                        ...getInputProps("priceType"),
                        value: "Cố định",
                    }}
                    component={InputText}
                    className="mr5"
                />

                <div className="wfull mr5 ml5">
                    <div className="label">{translate("ads.priceOfYou")}</div>

                    <InputWraper
                        inputProps={getInputProps("price")}
                        className="numberCenter"
                        renderInput={(inputProps) => (
                            <InputNumberUpDown
                                {...inputProps}
                                stepNumber={1000}
                                onChange={(value) =>
                                    calcTotalAmount({
                                        name: "price",
                                        value: value,
                                    })
                                }
                                thousandSeparator={true}
                            />
                        )}
                    />
                </div>

                <InputWraper
                    label={translate("ads.maxPriceByIPO")}
                    inputProps={getInputProps("maxPriceByIPO")}
                    renderInput={(inputProps) => (
                        <InputText
                            {...inputProps}
                            value={maxPriceByIPO}
                            name="maxPriceByIPO"
                        />
                    )}
                    className="ml5 inputTextRight"
                />
            </div>

            <div className="textBoldItalic">{translate("ads.step2")}</div>

            <div className="group">
                <InputWraper
                    label={translate("ads.totalQuantity")}
                    inputProps={getInputProps("totalAmount")}
                    renderInput={(inputProps) => (
                        <InputNumber
                            {...inputProps}
                            onChange={(value) =>
                                calcTotalAmount({
                                    name: "totalAmount",
                                    value: value,
                                })
                            }
                            suffix={ipo?.name ? ` ${ipo?.symbol}` : ""}
                            thousandSeparator={true}
                        />
                    )}
                    className="mr5 inputTextRight"
                />

                <InputWraper
                    label={translate("ads.totalAmount")}
                    inputProps={getInputProps("totalQuantity")}
                    renderInput={(inputProps) => (
                        <InputNumber
                            {...inputProps}
                            suffix={fiat?.code ? ` ${fiat?.code}` : ""}
                            thousandSeparator={true}
                        />
                    )}
                    className="ml5 inputTextRight"
                />
            </div>

            <div className="group">
                <InputWraper
                    label={translate("ads.minimumOrderPrice")}
                    inputProps={getInputProps("minimumOrderPrice")}
                    renderInput={(inputProps) => (
                        <InputNumber
                            {...inputProps}
                            name="minimumOrderPrice"
                            suffix={fiat?.code ? ` ${fiat?.code}` : ""}
                            thousandSeparator={true}
                        />
                    )}
                    className="mr5 inputTextRight"
                />

                <InputWraper
                    label={translate("ads.maximumOrderPrice")}
                    inputProps={getInputProps("maximumOrderPrice")}
                    renderInput={(inputProps) => (
                        <InputNumber
                            {...inputProps}
                            suffix={fiat?.code ? ` ${fiat?.code}` : ""}
                            thousandSeparator={true}
                        />
                    )}
                    className="ml5 inputTextRight"
                />
            </div>

            <div className="group">
                <InputWraper
                    label={translate("payment.name")}
                    inputProps={getInputProps("paymentInfoIds")}
                    className="mr5"
                    renderInput={(inputProps) => (
                        <InputSelect
                            {...inputProps}
                            isMulti
                            options={optionsPaymentList}
                            onChange={(value) => {
                                if (value.length < 4) {
                                    setValues({ paymentInfoIds: value });
                                }
                            }}
                            onTouched={() => false}
                            name="paymentInfoIds"
                        />
                    )}
                />
                <InputWraper
                    label={translate("ads.paymentLimitTime")}
                    inputProps={getInputProps("paymentLimitTime")}
                    className="ml5"
                    renderInput={(inputProps) => (
                        <InputSelect
                            {...inputProps}
                            options={optionsPaymentLimitTime}
                            onChange={(value) =>
                                setValues({ paymentLimitTime: value })
                            }
                            onTouched={() => false}
                            name="paymentLimitTime"
                        />
                    )}
                />
            </div>

            <div className="textBoldItalic">{translate("ads.step3")}</div>

            <div className="group">
                <InputWraper
                    label={"Điều kiện"}
                    inputProps={{
                        ...getInputProps("terms"),
                        onChange: (value) =>
                            setValues({ terms: value.substring(0, 1000) }),
                    }}
                    component={InputTextArea}
                    className="mb0"
                />
            </div>
            <p className="mb20">{getInputProps("terms").value.length} / 1000</p>

            <div className="group">
                <InputWraper
                    label={translate("ads.autoReply")}
                    inputProps={{
                        ...getInputProps("autoReply"),
                        onChange: (value) =>
                            setValues({ autoReply: value.substring(0, 200) }),
                    }}
                    component={InputTextArea}
                    className="mb0"
                />
            </div>
            <p className="mb20">
                {getInputProps("autoReply").value.length} / 200
            </p>

            <div className="label">{translate("ads.conditions")}</div>

            <div className="group">
                <InputCheckbox
                    isDisabled={true}
                    label={translate("ads.kycSuccess")}
                    name={"kycSuccess"}
                    value={
                        checkboxConditions(adsEKyc.COMPLETE_KYC) ? true : false
                    }
                    onChange={() => false}
                    onTouched={() => false}
                />
            </div>

            <div className="chkRegister">
                <InputCheckbox
                    isDisabled={isDisabled}
                    label={""}
                    name={"register"}
                    value={
                        checkboxConditions(adsEKyc.REGISTER_DAY_NUMBER)
                            ? true
                            : false
                    }
                    onChange={(value) => {
                        let newConditions = values.conditions || [];
                        if (value) {
                            newConditions = newConditions.concat({
                                type: adsEKyc.REGISTER_DAY_NUMBER,
                                value: 0,
                            });
                        } else {
                            newConditions = newConditions.filter(
                                (t: any) =>
                                    t.type !== adsEKyc.REGISTER_DAY_NUMBER
                            );
                        }
                        setValues({ conditions: newConditions });
                    }}
                    onTouched={() => false}
                />
                <div className="label">
                    <span>{translate("ads.registered")} </span>
                    <input
                        type={"number"}
                        min="0"
                        step="1"
                        className="input"
                        readOnly={isDisabled}
                        value={
                            checkboxConditions(adsEKyc.REGISTER_DAY_NUMBER)
                                ?.value || 0
                        }
                        onChange={(e) => {
                            const editCondition = checkboxConditions(
                                adsEKyc.REGISTER_DAY_NUMBER
                            );
                            if (editCondition) {
                                editCondition.value = e.target.value;
                                setValues({
                                    conditions: values.conditions.map(
                                        (item: any) =>
                                            item.type ===
                                            adsEKyc.REGISTER_DAY_NUMBER
                                                ? editCondition
                                                : item
                                    ),
                                });
                            }
                        }}
                    />
                    <span> {translate("ads.registeredLast")} </span>
                </div>
            </div>

            <div className="label">{translate("all.status")}</div>

            <div className="group">
                <InputCheckbox
                    isDisabled={isDisabled}
                    isRequired={true}
                    label={translate("ads.online")}
                    name={"online"}
                    type="radio"
                    value={values.publishType === adsPublishType.ONLINE}
                    onChange={() => {
                        setValues({ publishType: adsPublishType.ONLINE });
                    }}
                    onTouched={() => false}
                />
            </div>

            <div className="group">
                <InputCheckbox
                    isDisabled={isDisabled}
                    isRequired={true}
                    label={translate("ads.offline")}
                    name={"offline"}
                    type="radio"
                    value={values.publishType === adsPublishType.OFFLINE}
                    onChange={() =>
                        setValues({ publishType: adsPublishType.OFFLINE })
                    }
                    onTouched={() => false}
                />
            </div>

            <div className="groupButton">
                <Button
                    label={translate("all.cancel")}
                    buttonType="danger"
                    onClick={props.onClose}
                />

                {formType !== AdsFormType.VIEW && (
                    <Button
                        isLoading={isSubmitting}
                        label={
                            formType === AdsFormType.ADD
                                ? translate("all.create")
                                : translate("all.update")
                        }
                        // isMiddle
                        type="submit"
                        className={"ml5"}
                    />
                )}
            </div>
        </form>
    );
};
