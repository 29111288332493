import React, { FC, useState } from "react";
import {
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Button,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { AccountCircle, VpnKey } from "@mui/icons-material";
import { AdminService } from "../../services/admin";
import { PopupWraper } from "../../components/popup";
import { CreateEditUser } from "./update-user/update-info";
import { UpdatePasswordUser } from "./update-user/update-password";
import { translate } from "../../languages";
import { useSelector } from "../../store";
import { useTradeCoinWithBalance } from "../../hook";
import { Image } from "../image";
import { ClassNames, DateTimeUtils, CreateAlert } from "../../modules";
import { Icon } from "../icon";
import { getLocaleKey } from "../../languages";
import { SettingBox } from "../setting-box";

export const UserHead: FC = () => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const user = useSelector((state) => state.user);

    const [isShowChangePass, setShowChangePass] = useState(false);

    const showChangePass = () => {
        setOpen(false);
        setShowChangePass(!isShowChangePass);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === "Escape") {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const [editData, setEditData] = useState<any>(null);
    const getDetailUser = async () => {
        return new Promise(async (resolve) => {
            try {
                setOpen(false);
                const res = await AdminService.getUser(user.userId);
                if (res && res.success) {
                    setEditData({
                        ...res.result,
                        birthday: DateTimeUtils.dateToYMD(res.result.birthday),
                    });
                }
            } catch (error: any) {
                resolve(
                    CreateAlert({ type: "danger", message: error.message })
                );
            }
        });
    };

    return (
        <div className="UserHead">
            <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <div className="profile">
                    <Image src={user.avatar} type="avatar" />

                    <div className="info">
                        <div className="name">{user.fullName}</div>
                        <div className="email">{user.email}</div>
                    </div>
                </div>
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="right-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "right-start"
                                    ? "right top"
                                    : "right bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    style={{
                                        background: "#1a2138",
                                        color: "#8f9bb3",
                                    }}
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem onClick={getDetailUser}>
                                        <ListItemIcon>
                                            <AccountCircle
                                                style={{ color: "#8f9bb3" }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {translate("user.updateProfile")}
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={showChangePass}>
                                        <ListItemIcon>
                                            <VpnKey
                                                style={{ color: "#8f9bb3" }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {translate("user.changePassword")}
                                        </ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <div className="ctas">
                <div className="mr15">
                    <SettingBox />
                </div>
            </div>

            {isShowChangePass && (
                <PopupWraper
                    center
                    title={translate("user.changePassword")}
                    onClose={() => setShowChangePass(!isShowChangePass)}
                >
                    <UpdatePasswordUser
                        data={{ oldPassword: "", newPassword: "" }}
                        onFinishEdit={() => {
                            setShowChangePass(false);
                        }}
                    />
                </PopupWraper>
            )}

            {editData && (
                <PopupWraper
                    center
                    title={translate("user.update")}
                    onClose={() => setEditData(null)}
                >
                    <CreateEditUser
                        data={editData}
                        onFinishEdit={() => {
                            setEditData(null);
                        }}
                    />
                </PopupWraper>
            )}
        </div>
    );
};

export const ButtonSwitchAccount: FC<{ onChange?: () => void }> = (props) => {
    const {
        data,
        toggleChangeTradeCoin: handleChangeTradeCoin,
        coinActive,
    } = useTradeCoinWithBalance();

    return (
        <button type="button" className="btn dropdowns">
            <div className="info">
                <div className="label">{coinActive.label}</div>
                <div className="amount">
                    $
                    {coinActive.amount.toLocaleString(getLocaleKey(), {
                        maximumFractionDigits: 0,
                    })}
                </div>
            </div>

            <div className="toggleIcon">
                <Icon.ArrowDown />
            </div>

            <div className="options">
                <div className="wraper">
                    {data.map((item, key) => {
                        const isActive = item.isActive;

                        return (
                            <div
                                key={key}
                                className={ClassNames({
                                    item: true,
                                    active: item.isActive,
                                })}
                                onClick={() => {
                                    if (!isActive) {
                                        handleChangeTradeCoin();
                                        props.onChange && props.onChange();
                                    }
                                }}
                            >
                                <div className="type">{item.label}</div>
                                <div className="amount">
                                    $
                                    {(+item.amount).toLocaleString(
                                        getLocaleKey(),
                                        { maximumFractionDigits: 0 }
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </button>
    );
};
