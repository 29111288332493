import React, { FC } from "react";
import { UserService } from "../../services/user";
import { translate } from "../../languages";
import { Icon } from "../icon";
import { NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";

export const Navigator: FC = () => {
    return (
        <div className="Navigator">
            <img
                src="/assets/images/logo-symbol-white.png"
                alt=""
                className="logo"
            />
            <MenuLinks />
        </div>
    );
};

const MenuLinks: FC = () => {
    return (
        <div className="menu">
            <NavLink to={Routes.dashboard.path}>
                <Icon.Dashboard />
                {translate("dashboard.name")}
            </NavLink>

            <NavLink to={Routes.userList.path}>
                <Icon.User />
                {translate("user.name")}
            </NavLink>

            <NavLink to={Routes.roleList.path}>
                <Icon.Dashboard />
                {translate("role.name")}
            </NavLink>

            <NavLink to={Routes.ipoList.path}>
                <Icon.Exchange />
                {translate("ipo.name")}
            </NavLink>
            <NavLink to={Routes.adsList.path}>
                <Icon.Exchange />
                {translate("ads.name")}
            </NavLink>
            {/* <NavLink to={Routes.transactionList.path}>
                <Icon.Exchange />
                {translate("transactions.name")}
            </NavLink> */}

            <NavLink to={Routes.tradingTable.path}>
                <Icon.Affiliate />
                {translate("trading.name")}
            </NavLink>

            <NavLink to={Routes.paymentList.path}>
                <Icon.Setting />
                {translate("payment.name")}
            </NavLink>

            <NavLink to={Routes.feedbackList.path}>
                <Icon.Report />
                {translate("feedback.name")}
            </NavLink>

            <NavLink to={Routes.cmsList.path}>
                <Icon.Report />
                {translate("cms.name")}
            </NavLink>

            <NavLink to={Routes.faqList.path}>
                <Icon.Affiliate />
                {translate("faq.name")}
            </NavLink>

            <NavLink to={Routes.faqCategory.path}>
                <Icon.Affiliate />
                {translate("faqCategory.name")}
            </NavLink>

            <NavLink to={Routes.setting.path}>
                <Icon.Setting />
                {translate("setting.name")}
            </NavLink>

            <div className="btnLogout" onClick={() => UserService.logout()}>
                <Icon.Logout />
                {translate("all.logout")}
            </div>
        </div>
    );
};
