import { RequestMainService } from "../request";

export class OrderChatService {
    static async getListOrderChats(ipo_order_id: any, params: any = {}) {
        return RequestMainService.get(
            `/order-chat/conversation/${ipo_order_id}`,
            params
        ).then(({ result }) => ({
            count: result.total,
            data: result.orderChatMessages,
        }));
    }
}
