import { translate } from "../../languages"

export const tradingStatus = {
    NEW: "NEW",
    TRANSFERRED: "TRANSFERRED",
    SUCCESSFUL: "SUCCESSFUL",
    CANCELLED: "CANCELLED",
    COMPLAINING: "COMPLAINING"
}

export const tradingOptionsStatus = () => ([
    { value: "NEW", label: translate("trading.newStatus") },
    { value: "TRANSFERRED", label: translate("trading.transferStatus") },
    { value: "SUCCESSFUL", label: translate("trading.successStatus") },
    { value: "CANCELLED", label: translate("trading.cancelStatus") },
    { value: "COMPLAINING", label: translate("trading.complainStatus") }
])

export const tradingOptionsType = () => ([
    { value: "BUY", label: `${translate("all.buy")}` },
    { value: "SELL", label: `${translate("all.sell")}` }
])

export const tradingGetOptionLabel = (type: "status" | "type", value: any) => {
    if (!value) return

    if (type === "status") return tradingOptionsStatus().find(t => t.value === value)
    else if (type === "type") return tradingOptionsType().find(t => t.value === value)
}