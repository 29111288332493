import React from "react";
import { withFranchiseWraper } from "../wraper";
import { translate } from "../../../languages";
import { Button, ITableStructureItem } from "../../../modules";
import { CustomTable } from "../../../modules/table/CustomTable";
// import { TableFilterInputText } from "../../../components/table-filter-inputs";
import { AdminService } from "../../../services/admin";
import { InputToggleSwitch } from "../../../components/toggle-switch";

export const PageSettingNoti = withFranchiseWraper(() => {
    const structure: ITableStructureItem[] = [
        {
            name: translate("title"),
            key: "title",
        },
        {
            name: translate("content"),
            key: "content",
        },
        {
            name: translate("DATE"),
            key: "date",
        },
        {
            name: translate("actions"),
            key: "actions",
            render: (item) => {
                return (
                    <div className="btnGroup">
                        <InputToggleSwitch
                            onChange={async (value) => {}}
                            value={item.isFrozenTransfer}
                            onTouched={() => false}
                            label=""
                            name="active"
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <div className="PageSettingNoti">
            <div className="PageSettingNoti__Head mb20">
                <div className="PageSettingNoti__Head__Title">
                    {translate("notification")}
                </div>
                <div className="PageSettingNoti__Head__GroupAction">
                    <Button
                        className="mr5"
                        type="button"
                        buttonType="primary"
                        label="Create"
                    />
                    <Button
                        className="mr5"
                        type="button"
                        buttonType="grey"
                        label="Edit"
                    />
                    <Button type="button" buttonType="danger" label="Delete" />
                </div>
            </div>
            <div className="PageSettingNoti__Body">
                <CustomTable
                    isHighLightHeader
                    isTextCenter
                    hasOrderColumn
                    structure={structure}
                    fetchData={async (params) => {
                        // setParams(params);
                        return AdminService.getListUser({
                            page: params.pageNumber,
                            numberOfItemsPerPage: params.limit,
                            ...params,
                        });
                    }}
                />
            </div>
        </div>
    );
});
