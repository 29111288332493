import React, { useState, FC } from "react";
import * as Yup from "yup";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { UserService } from "../../../services/user";
import {
    useForm,
    InputWraper,
    Button,
    CreateAlert,
    Regex,
} from "../../../modules";
import { InputText, InputPassword } from "../../../components";
import { AuthWraper } from "../authWraper";
import { translate } from "../../../languages";

export const PageAuthLogin: FC = () => {
    const loginType = "EMAIL";
    const [recaptcha, setRecaptcha] = useState("");
    const { executeRecaptcha } = useGoogleReCaptcha() as any;

    const { handleSubmit, getInputProps, isSubmitting } = useForm({
        structure: [
            {
                name: "email",
                validate: Yup.string()
                    .required(translate("validate.emailEmpty"))
                    .matches(Regex.email, translate("validate.emailRegex")),
            },
            {
                name: "password",
                validate: Yup.string().required(
                    translate("validate.passwordEmpty")
                ),
            },
        ],
        onSubmit: async (values) => {
            let captcha = recaptcha;

            try {
                if (!recaptcha) captcha = await executeRecaptcha("login");
            } catch (error) {
                console.log(error);
            }

            return UserService.login(
                values.email,
                values.password,
                captcha,
                loginType
            ).catch((err) => {
                console.log(err)
                setRecaptcha("");
                CreateAlert({ message: err.message, type: "danger" });
                throw err;
            });
        },
    });

    return (
        <AuthWraper>
            <div className="PageAuth login">
                <img
                    src="/assets/images/maps.png"
                    className="background"
                    alt=""
                />

                <form onSubmit={handleSubmit}>
                    <div className="head">
                        <img
                            className="logo"
                            src="/assets/images/logo-white.png"
                            alt=""
                        />
                        <div className="title">
                            {translate("login-to-your-account")}
                        </div>
                    </div>

                    <GoogleReCaptcha
                        onVerify={(token) => setRecaptcha(token)}
                    />

                    <InputWraper
                        label={translate("login.email")}
                        inputProps={getInputProps("email")}
                        component={InputText}
                    />

                    <InputWraper
                        label={translate("login.password")}
                        inputProps={getInputProps("password")}
                        component={InputPassword}
                    />

                    <Button
                        label={translate("all.login")}
                        type="submit"
                        isLoading={isSubmitting}
                    />
                </form>
            </div>
        </AuthWraper>
    );
};
