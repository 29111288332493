import React, { FC, useState, useEffect } from 'react'
// @ts-ignore
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';

import 'react-calendar/dist/Calendar.css';
import 'react-datetime-picker/dist/DateTimePicker.css';

import { IInputProps } from '../../../modules';

type IDateTimeProps = {
    onChange: (time: any) => void,
    maxDetail?: string,
    isSecondTime?: boolean,
    locale?: string,
    value?: any,
    minDate?: number,
    maxDate?: number,
    className?: string,
    disabled?: boolean,
    onBlur?: ((event?: React.FocusEvent<HTMLInputElement>) => void) | undefined,
    onFocus?: ((event?: React.FocusEvent<HTMLInputElement>) => void) | undefined,
    defaultValue?: number,
}
export const InputDateTime: FC<IDateTimeProps> = (props) => {
    const [value, setValue] = useState(null) as any;

    const handleChange = (date: any) => {
        let time: number = 0;
        if (date) time = new Date(date).getTime();

        setValue(time);
        props.onChange(new Date(time).toUTCString());
    }

    const convertTime = (time: any) => {
        if (!time) return;
        return new Date(time);
    }

    useEffect(() => {
        setValue(props.defaultValue);
    }, [props.defaultValue, setValue])

    return <DateTimePicker
        className="InputDateTime"
        calendarClassName="InputDateTimeCalendar"
        clockClassName="InputDateTimeClock"
        // maxDetail={maxDetail}
        disableClock={true}
        onChange={handleChange}
        // minDate={convertTime(minDate)}
        // maxDate={convertTime(maxDate)}
        value={convertTime(value)}
        locale="en-GB"
        disabled={props.disabled}
        isSecondTime={props.isSecondTime}
        // onCalendarClose={() => props.onTouched()}
        // onBlur={() => props.onTouched()}
    />
}