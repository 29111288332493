import React, { FC, useEffect, useState } from "react";

import { ClassNames, IInputProps } from "../../modules";

export interface IInputToggleSwitchProps extends IInputProps {
    type?: "rectangular" | "round";
    label: string;
}

export const InputToggleSwitch: FC<IInputToggleSwitchProps> = (props) => {
    const [defaultValue, setDefaultValue] = useState(props.value || false)
    useEffect(() => {
        setDefaultValue(props.value)
    }, [props.value])
    
    return (
        <div className="InputToggleSwitch">
            <div className="label">{props.label}</div>

            <label className="switch">
                <input
                    type="checkbox"
                    checked={defaultValue}
                    onChange={() => {
                        props.onChange(!defaultValue)
                        setDefaultValue((val:any) => !val)
                    }}
                />
                <span
                    className={ClassNames({
                        slider: true,
                        [props.type as string]: !!props.type,
                    })}
                />
            </label>
        </div>
    );
};

InputToggleSwitch.defaultProps = {
    type: "round",
};
