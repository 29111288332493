import { RequestMainService } from "../request";

export class AdsService {
    static async getAds(id: any) {
        return RequestMainService.get(`/comml/${id}`);
    }

    static async getListAds(params: any = {}) {
        return RequestMainService.get(`/comml`, params).then(({ result }) => ({
            count: result.total,
            data: result.adsList,
        }));
    }
    static async createAds(params: any) {
        return RequestMainService.post(`/comml`, params);
    }

    static async updateAds(params: any) {
        return RequestMainService.put(`/comml/${params.id}`, params);
    }

    static async deleteAds(id: any) {
        return RequestMainService.delete(`/comml/${id}`);
    }

    static async updateActiveStatusAds(params: any) {
        return RequestMainService.put(`/comml/toggle/${params.id}`, params);
    }

    static async getReferencePrice(params: any) {
        return RequestMainService.get(`/comml/reference-price/get`, params);
    }
}
