/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import "./index.scss";
import { Button, CreateAlert } from "../../../../modules";
import { InputToggleSwitch } from "../../../../components/toggle-switch";
import { AdminService } from "../../../../services/admin";
import { IpoService } from "../../../../services";
import { translate } from "../../../../languages";

export const Ban: FC<{ ipoId: number; onFinishEdit: () => void }> = (props) => {
    const confirmDelete = async () => {
        await IpoService.deleteIpo(props.ipoId)
            .then(() => {
                CreateAlert({
                    message: translate("all.deleteSuccess"),
                    type: "success",
                });
                props.onFinishEdit();
            })
            .catch((error) => {
                CreateAlert({
                    message: error.message,
                    type: "danger",
                });
            });
    };

    return (
        <div className="Info">
            <div className="mb20">{translate("ipo.deleteConfirm")}</div>
            <Button
                // className="mb20"
                label={translate("all.confirm")}
                onClick={() => confirmDelete()}
                isMiddle
                // buttonType="warning"
                className="btnBan"
                type="submit"
            />
        </div>
    );
};
