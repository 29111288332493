import React, { useState, memo, FC, useMemo } from "react";
import {
    ITableStructureItem,
    CreateAlert,
    Button,
    Icon,
    InputWraper,
    Table,
} from "../../../modules";
import "./PaymentList.scss";
import { PaymentService } from "../../../services";
import { translate } from "../../../languages";
import { PopupWraper } from "../../../components/popup";
import { CreateEditPayment } from "./CreateEdit";
import { InputSelect, InputText } from "../../../components";
import { paymentGetOption, paymentOptionsStatus } from "../enumPayment";
import { Routes } from "../../../AppRoutes";
import { Navigate, useNavigate } from "react-router-dom";

export const PaymentList = memo(() => {
    const [params, setParams] = useState([] as any[]);
    const [editData, setEditData] = useState<any>(null);
    const [freezeDataId, setFreezeDataId] = useState<number | null>(null);
    const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
    const navigate = useNavigate();

    const handleEditData = async (id: any) => {
        const res = await PaymentService.getPaymentById(id);
        console.log(res);

        setEditData({ ...res.result, isCreate: false });
    };

    const structure: ITableStructureItem[] = [
        {
            name: translate("payment.name"),
            key: "name",
        },
        {
            name: translate("all.status"),
            key: "isActived",
            render: (item) =>
                paymentGetOption("status", item.isActived?.toString())?.label,
        },
        {
            name: translate("all.actions"),
            key: "actions",
            render: (item) => {
                return (
                    <div className="btnGroup">
                        <span
                            className="btn btn__hover"
                            onClick={() => handleEditData(item.paymentId)}
                        >
                            <Icon.Edit />
                        </span>
                        <span
                            className="btn btn__hover"
                            onClick={() => setFreezeDataId(item.paymentId)}
                        >
                            <Icon.Trash />
                        </span>
                    </div>
                );
            },
        },
    ];

    const tableMemo = useMemo(() => {
        return (
            <Table
                structure={structure}
                fetchData={async (paramsTable) => {
                    return PaymentService.getPaymentList(params);
                }}
            />
        );
    }, [forceUpdateTable, setParams]);

    return (
        <div className="PaymentList">
            <Button
                className="mb20"
                label={translate("payment.create")}
                buttonType="success"
                onClick={() => {
                    setEditData({ isActived: true, isCreate: true });
                    // navigate(Routes.createPayment.path);
                }}
            />

            <FilterComponent
                onFilter={(item: any) => {
                    setParams(item);
                    setForceUpdateTable(Math.random());
                }}
            />

            {tableMemo}

            {editData && (
                <PopupWraper
                    center
                    title={
                        editData.isCreate
                            ? translate("payment.create")
                            : translate("payment.update")
                    }
                    onClose={() => setEditData(null)}
                    className="modalDetail"
                >
                    <CreateEditPayment
                        data={editData}
                        onFinishEdit={() => {
                            setForceUpdateTable(Math.random);
                            setEditData(null);
                        }}
                    />
                </PopupWraper>
            )}

            {freezeDataId && (
                <PopupWraper
                    center
                    title={translate("payment.delete")}
                    onClose={() => setFreezeDataId(null)}
                >
                    <DeletePopup
                        userId={freezeDataId}
                        onFinishEdit={() => {
                            setForceUpdateTable(Math.random);
                            setFreezeDataId(null);
                        }}
                    />
                </PopupWraper>
            )}
        </div>
    );
});

const FilterComponent = ({ onFilter }: any) => {
    const [filter, setFilter] = useState<any>({});

    return (
        <div className="group">
            <div className="mr5">
                <div className="label">{translate("payment.name")}</div>
                <InputWraper
                    inputProps={{
                        onChange: (e) =>
                            setFilter((val: any) => ({
                                ...val,
                                paymentName: e,
                            })),
                        value: filter.paymentName,
                        name: "paymentName",
                        onTouched: () => false,
                    }}
                    component={InputText}
                />
            </div>

            <div className="ml5 mr5 wd250">
                <div className="label">{translate("all.status")}</div>
                <InputWraper
                    inputProps={{
                        onChange: (e) =>
                            setFilter((val: any) => ({ ...val, isActived: e })),
                        value: filter.isActived,
                        name: "isActived",
                        onTouched: () => false,
                    }}
                    renderInput={(inputProps) => (
                        <InputSelect
                            {...inputProps}
                            options={paymentOptionsStatus()}
                        />
                    )}
                />
            </div>

            <div className="btnGroup ml5">
                <Button
                    label={translate("all.search")}
                    onClick={() => onFilter(filter)}
                />
                <span
                    className="btn"
                    onClick={() => {
                        setFilter(defaultFilterPayment);
                        onFilter(defaultFilterPayment);
                    }}
                >
                    <Icon.Remove />
                </span>
            </div>
        </div>
    );
};

const DeletePopup: FC<{ userId: number; onFinishEdit: () => void }> = (
    props
) => {
    const confirmDelete = async () => {
        await PaymentService.deletePayment(props.userId)
            .then(() => {
                CreateAlert({
                    message: translate("all.deleteSuccess"),
                    type: "success",
                });
                props.onFinishEdit();
            })
            .catch((error) => {
                CreateAlert({
                    message: error.message,
                    type: "danger",
                });
            });
    };
    return (
        <div className="Info">
            <div className="mb20">{translate("payment.deleteConfirm")}</div>
            <Button
                label={translate("all.confirm")}
                onClick={() => confirmDelete()}
                isMiddle
                className="btnBan"
                type="submit"
            />
        </div>
    );
};

const defaultFilterPayment = { paymentName: "", status: "" };
