import React, { FC, useEffect, Fragment } from "react";

import { useSelector } from "../../store";
import { Routes } from "../../AppRoutes";
import { useNavigate } from "react-router-dom";

export const AuthWraper: FC = (props) => {
    const navigate = useNavigate();
    const auth = useSelector((state) => state.user);

    useEffect(() => {
        if (auth) {
            navigate(
                localStorage.getItem("auth-redirect-to") ||
                    Routes.dashboard.path
            );
            localStorage.removeItem("auth-redirect-to");
        }
    }, [auth, navigate]);

    return <Fragment>{props.children}</Fragment>;
};
