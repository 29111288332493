import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { translate } from "../../languages";

export const withIpoWraper = (Component: FC) => (props: any) => {
    return (
        <div className="withIpoWraper">
            <div className="TabLinks">
                <NavLink to={Routes.ipoList.path}>
                    <Icon.User />
                    {translate("ipo.list")}
                </NavLink>
            </div>

            <Component {...props} />
        </div>
    );
};
