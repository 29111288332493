import { RequestMainService } from "../request";

export class FAQCategoryService {
    static async getFAQCategoryList(params: any = {}) {
        return RequestMainService.get(`/admin/faq-category`, params).then(
            ({ result }) => ({
                data: result.faqCategories,
                count: result.total,
            })
        );
    }

    static async createFaqCategory(params: any) {
        return RequestMainService.post(`/admin/faq-category`, params);
    }

    static async updateFaqCategory(params: any) {
        return RequestMainService.put(
            `/admin/faq-category/${params.faqCategoryId}`,
            params
        );
    }

    static async deleteFaqCategory(id: any) {
        return RequestMainService.delete(`/admin/faq-category/${id}`);
    }
}
