import React, { FC, memo, useEffect, useState } from "react";
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async/fixed";

import { EOrderType } from "../../types";
import { getLocaleKey, translate } from "../../languages";
import { ITableStructureItem, ObjectUtils } from "../../modules";
import { OrderService } from "../../services/order/order.service";
import { tradingStatus } from "../../pages/trading/enumTrading";

export const OpennedOrders: FC = memo(() => {
    const [openedOrders, setOpenedOrders] = useState([] as any[]);
    const [count, setCount] = useState(0);
    const soundWin = new Audio("/assets/sound/new-order.wav");
    const playSoundWin = () => {
        const isEnableSound = localStorage.getItem("sound") !== "off";
        if (!isEnableSound) return;
        soundWin.muted = false;
        soundWin.play();
    };

    let buyOrders: any[] = openedOrders
        .filter((item: any) => item.type === EOrderType.BUY)
        .sort((a, b) => b.amount - a.amount);
    let sellOrders: any[] = openedOrders
        .filter((item: any) => item.type === EOrderType.SELL)
        .sort((a, b) => b.amount - a.amount);

    useEffect(() => {
        try {
            if (count !== 0) {
                playSoundWin();
            }
        } catch (error) {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    useEffect(() => {
        let interval: any;
        OrderService.getListOrders({
            page: 1,
            numberOfIPOOrderPerPage: 10,
            ipoOrderStatuses: JSON.stringify([
                tradingStatus.NEW,
                tradingStatus.TRANSFERRED,
                tradingStatus.COMPLAINING,
            ]),
        }).then((data) => {
            setOpenedOrders(data.data);
            setCount(data.count);

            interval = setIntervalAsync(async () => {
                return OrderService.getListOrders({
                    page: 1,
                    numberOfIPOOrderPerPage: 10,
                    ipoOrderStatuses: JSON.stringify([
                        tradingStatus.NEW,
                        tradingStatus.TRANSFERRED,
                        tradingStatus.COMPLAINING,
                    ]),
                }).then((newData) => {
                    setOpenedOrders(newData.data);
                    setCount(newData.count);
                });
            }, 5000);
        });

        return () => {
            try {
                if (interval.stopped !== undefined) {
                    clearIntervalAsync(interval);
                }
            } catch (error) {}
        };
        // eslint-disable-next-line
    }, []);

    const structure: ITableStructureItem[] = [
        {
            name: "S",
            render: (item) => new Date(item.created).getSeconds(),
        },
        {
            name: translate("user.fullname"),
            render: (item) => item.ads?.userFullName,
        },
        {
            name: translate("all.amount"),
            render: (item) =>
                (+item.amount).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                }),
        },
        {
            name: translate("all.price"),
            render: (item) =>
                (+item.price).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                }),
        },
        {
            name: translate("all.totalPrice"),
            render: (item) =>
                (+item.totalPrice).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                }),
        },
    ];

    return (
        <div className="OpennedOrders">
            <div className="title">{translate("dashboard.listOrder")}</div>
            <div className="tabs">
                <div className="item down">
                    <div className="label">{translate("all.sell")}</div>
                    <div className="content">
                        <div className="Table">
                            <table>
                                <thead className="Table__Head">
                                    <tr>
                                        {structure.map((item, key) => (
                                            <th key={key}>{item.name}</th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody className="Table__Body">
                                    {sellOrders.map((item, itemKey) => {
                                        return (
                                            <tr key={itemKey}>
                                                {structure.map(
                                                    (column, columnKey) => {
                                                        return (
                                                            <td
                                                                className={
                                                                    column.className ||
                                                                    ""
                                                                }
                                                                style={
                                                                    column.style
                                                                }
                                                                key={columnKey}
                                                            >
                                                                {(() => {
                                                                    if (
                                                                        column.render
                                                                    )
                                                                        return column.render(
                                                                            item,
                                                                            () =>
                                                                                false,
                                                                            column
                                                                        );
                                                                    if (
                                                                        column.key
                                                                    )
                                                                        return `${ObjectUtils.getIn(
                                                                            item,
                                                                            column.key,
                                                                            ""
                                                                        )}`;
                                                                    return "--";
                                                                })()}
                                                            </td>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="item up">
                    <div className="label">{translate("all.buy")}</div>

                    <div className="content">
                        <div className="Table">
                            <table>
                                <thead className="Table__Head">
                                    <tr>
                                        {structure.map((item, key) => (
                                            <th key={key}>{item.name}</th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody className="Table__Body">
                                    {buyOrders.map((item, itemKey) => {
                                        return (
                                            <tr key={itemKey}>
                                                {structure.map(
                                                    (column, columnKey) => {
                                                        return (
                                                            <td
                                                                className={
                                                                    column.className ||
                                                                    ""
                                                                }
                                                                style={
                                                                    column.style
                                                                }
                                                                key={columnKey}
                                                            >
                                                                {(() => {
                                                                    if (
                                                                        column.render
                                                                    )
                                                                        return column.render(
                                                                            item,
                                                                            () =>
                                                                                false,
                                                                            column
                                                                        );
                                                                    if (
                                                                        column.key
                                                                    )
                                                                        return `${ObjectUtils.getIn(
                                                                            item,
                                                                            column.key,
                                                                            ""
                                                                        )}`;
                                                                    return "--";
                                                                })()}
                                                            </td>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
