import React, { useState, memo, useEffect, useMemo } from "react";
import XLSX from "xlsx";
import {
    ITableStructureItem,
    CreateAlert,
    Button,
    Icon,
    InputWraper,
    Table,
    DateTimeUtils,
} from "../../../modules";
import "./AdsList.scss";
import { DetailAds } from "./Detail";
import { withAdsWraper } from "../wraper";
import { getLocaleKey, translate } from "../../../languages";
import { PopupWraper } from "../../../components/popup";
import { AdsService, IpoService, PaymentService } from "../../../services";
import {
    InputSelect,
    InputNumber,
    InputText,
    InputDateTimeRange,
} from "../../../components";
import {
    adsEKyc,
    AdsFormType,
    adsOptionsPublistType,
    adsOptionsType,
    adsPublishType,
} from "../enumAds";

let timer: any;
export const AdsList = withAdsWraper(
    memo(() => {
        const [params, setParams] = useState([] as any);
        const [editData, setEditData] = useState<any>(null);
        const [closeAds, setCloseAds] = useState<any>(null);
        const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());

        const structure: ITableStructureItem[] = [
            {
                name: translate("ads.userName"),
                key: "user.nickname",
            },

            {
                name: `Mã quảng cáo, ${translate(
                    "ads.orderType"
                )}, IPO/${translate("ads.selectFiat")}`,
                key: "adsId",
                render: (item) => {
                    return (
                        <>
                            <div>{item.adsId}</div>
                            <div>{item.type}</div>
                            <div>
                                {item.ipo?.symbol} / {item.fiat?.code}
                            </div>
                        </>
                    );
                },
            },

            {
                name: `Số lượng quảng cáo/ Đã hoàn tất/ Giới hạn`,
                key: "minimumOrderPrice",
                render: (item) => {
                    return (
                        <>
                            <div>{item.totalAmount}</div>
                            <div>{item.totalOrderSuccessfulIPOAmount}</div>
                            <div>
                                {item.minimumOrderPrice.toLocaleString(
                                    getLocaleKey(),
                                    {
                                        maximumFractionDigits: 2,
                                    }
                                )}{" "}
                                -{" "}
                                {item.maximumOrderPrice.toLocaleString(
                                    getLocaleKey(),
                                    {
                                        maximumFractionDigits: 2,
                                    }
                                )}
                            </div>
                        </>
                    );
                },
            },

            {
                name: translate("ads.price"),
                key: "price",
                render: (item) =>
                    item.price.toLocaleString(getLocaleKey(), {
                        maximumFractionDigits: 2,
                    }),
            },
            {
                name: translate("payment.name"),
                key: "paymentInfos",
                render: (item) => {
                    return RemoveDuplicateElement(
                        item.paymentInfos,
                        "name"
                    ).map((paymentInfo: any) => {
                        return (
                            paymentInfo.name && (
                                <div key={paymentInfo.name}>
                                    {paymentInfo.name}
                                </div>
                            )
                        );
                    });
                },
            },

            {
                name: `${translate("ads.modifiedTime")} / ${translate(
                    "ads.createdTime"
                )}`,
                key: "created",
                render: (item) => {
                    const createdSeconds = DateTimeUtils.timeToSeconds(
                        item.created
                    );
                    const ModifiedSeconds = DateTimeUtils.timeToSeconds(
                        item.modified
                    );
                    return (
                        <>
                            <div>
                                {DateTimeUtils.formatToShow(ModifiedSeconds)}
                            </div>
                            <div>
                                {DateTimeUtils.formatToShow(createdSeconds)}
                            </div>
                        </>
                    );
                },
            },

            {
                name: translate("all.status"),
                key: "publishType",
            },

            {
                name: translate("all.actions"),
                key: "actions",
                render: (item) => {
                    return (
                        <div className="btnGroup">
                            <span
                                className="btn btn__detail"
                                onClick={() => handleOnlOffAds(item)}
                            >
                                <Icon.Power />
                            </span>
                            <span
                                className="btn btn__detail"
                                onClick={() =>
                                    getDetailAds(item, AdsFormType.EDIT)
                                }
                            >
                                <Icon.Edit />
                            </span>
                            {item.publishType === adsPublishType.DISABLED ? (
                                <span
                                    className="btn btn__detail"
                                    onClick={() =>
                                        getDetailAds(item, AdsFormType.VIEW)
                                    }
                                >
                                    <Icon.Visible />
                                </span>
                            ) : (
                                <>
                                    <span
                                        className="btn btn__detail"
                                        onClick={() => setCloseAds(item)}
                                    >
                                        <Icon.Close />
                                    </span>
                                </>
                            )}
                        </div>
                    );
                },
            },
        ];

        const getDetailAds = async (item: any, formType: string) => {
            try {
                const res = await AdsService.getAds(item.adsId);
                if (res && res.result) {
                    setEditData({
                        ...res.result,
                        userFullName: item.userFullName,
                        formType: formType,
                    });
                }
            } catch (error: any) {
                CreateAlert({ type: "danger", message: error.message });
            }
        };

        const handleExportExcel = async () => {
            return new Promise(async (resolve) => {
                try {
                    const response = await AdsService.getListAds({
                        ...params,
                        page: 1,
                        numberOfItemsPerPage: 1000,
                    });

                    const data = response.data;

                    const fileHead = structure.map((v) => v.name);
                    const dataExport = [
                        fileHead,
                        ...data.map((item: any) =>
                            structure.map((column, index) => {
                                if (!column.key) return "";
                                return item[column.key];
                            })
                        ),
                    ];

                    const ws = XLSX.utils.aoa_to_sheet(dataExport);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                    const now = new Date();
                    XLSX.writeFile(
                        wb,
                        `Ads List ${now
                            .toLocaleDateString()
                            .replace(/\//g, "-")} ${now
                            .toLocaleTimeString()
                            .replace(/:/g, "-")}.xlsx`,
                        { type: "binary" }
                    );

                    resolve(
                        CreateAlert({
                            type: "success",
                            message: "Export data success.",
                        })
                    );
                } catch (error: any) {
                    resolve(
                        CreateAlert({ type: "danger", message: error.message })
                    );
                }
            });
        };

        const handleOnlOffAds = async (item: any) => {
            try {
                const publishType =
                    item.publishType === adsPublishType.OFFLINE
                        ? adsPublishType.ONLINE
                        : adsPublishType.OFFLINE;

                const res = await AdsService.updateActiveStatusAds({
                    publishType: publishType,
                    id: item.adsId,
                });

                if (res?.success) {
                    setForceUpdateTable(Math.random());
                }
            } catch (error: any) {
                CreateAlert({ type: "danger", message: error?.message });
            }
        };

        const tableMemo = useMemo(() => {
            return (
                <Table
                    structure={structure}
                    fetchData={async (paramsTable) => {
                        return AdsService.getListAds({
                            page: paramsTable.pageNumber,
                            numberOfCommlPerPage: paramsTable.limit,
                            ...params,
                        });
                    }}
                />
            );
        }, [params, forceUpdateTable]);

        return (
            <div className="AdsList">
                {/* <Button
                    className="mb20 mr20"
                    label={translate("all.exportExcel")}
                    buttonType="success"
                    onClick={handleExportExcel}
                /> */}

                <Button
                    className="mb20"
                    label={translate("ads.create")}
                    buttonType="success"
                    onClick={() => setEditData(defaultItemAds)}
                />

                <FilterComponent
                    onFilter={(item: any) => setParams({ ...params, ...item })}
                />

                {tableMemo}

                {editData && (
                    <PopupWraper
                        center
                        title={
                            editData.formType === AdsFormType.ADD
                                ? translate("ads.create")
                                : editData.formType === AdsFormType.EDIT
                                ? translate("ads.update")
                                : translate("ads.detail")
                        }
                        onClose={() => setEditData(null)}
                        className="modalDetail"
                        shouldCloseOnOverlayClick={false}
                    >
                        <DetailAds
                            data={editData}
                            onFinishEdit={() => {
                                setEditData(null);
                                setForceUpdateTable(Math.random);
                            }}
                            onClose={() => setEditData(null)}
                        />
                    </PopupWraper>
                )}

                {closeAds && (
                    <PopupWraper
                        center
                        title={translate("ads.close")}
                        onClose={() => setCloseAds(null)}
                    >
                        <ConfirmClose
                            item={closeAds}
                            onConfirm={() => {
                                setCloseAds(null);
                                setForceUpdateTable(Math.random);
                            }}
                        />
                    </PopupWraper>
                )}
            </div>
        );
    })
);

const FilterComponent = ({ onFilter }: any) => {
    const [optionIpoManagerId, setOptionIpoManagerId] = useState([]);
    const [optionPaymentId, setOptionPaymentId] = useState([]);
    const optionsPublishType = useMemo(() => adsOptionsPublistType(), []);
    const optionsType = useMemo(() => adsOptionsType(), []);

    const [filter, setFilter] = useState<any>(defaultFilterAds);
    const {
        keyword,
        ipoManagerId,
        type,
        paymentId,
        publishType,
        price,
        fromCreatedDate,
        toCreatedDate,
        fromModifiedDate,
        toModifiedDate,
    } = filter;

    useEffect(() => {
        Promise.all([
            IpoService.getListIpo({
                page: 1,
                numberOfIPOPerPage: 1,
                isGetAll: true,
            }),
            PaymentService.getPaymentList(),
        ])
            .then((res) => {
                if (res.length > 0) {
                    setOptionIpoManagerId(
                        res[0].data?.map((item: any) => ({
                            label: item.symbol,
                            value: item.ipoManagerId,
                        }))
                    );
                }
                if (res.length > 1) {
                    setOptionPaymentId(
                        res[1].data.map((item: any) => ({
                            value: item.paymentId,
                            label: item.name,
                        }))
                    );
                }
            })
            .catch((res) => {});
    }, []);

    const handleFilterChange = (newFilter: any, timeout: number = 0) => {
        const params = { ...filter, ...newFilter };
        setFilter(params);

        // clearTimeout(timer);
        // timer = setTimeout(() => {
        //     onFilter({ ...params });
        // }, timeout);
    };

    return (
        <>
            <div className="group">
                <div className="wfull mr5">
                    <div className="label">{translate("all.search")}</div>

                    <InputWraper
                        inputProps={{
                            name: "keyword",
                            onChange: (e) =>
                                handleFilterChange({ keyword: e }, 300),
                            onTouched: () => false,
                            value: keyword,
                        }}
                        renderInput={InputText}
                        placeholder={`${translate("ads.search")}`}
                    />
                </div>

                <div className="wfull mr5">
                    <div className="label">{translate("ads.selectIPO")}</div>
                    <InputSelect
                        options={optionIpoManagerId}
                        onChange={(e) =>
                            handleFilterChange({ ipoManagerId: e })
                        }
                        onTouched={() => false}
                        value={ipoManagerId}
                        name="ipoManagerId"
                    />
                </div>

                <div className="wfull mr5">
                    <div className="label">{translate("ads.orderType")}</div>
                    <InputSelect
                        options={optionsType}
                        onChange={(e) => handleFilterChange({ type: e })}
                        onTouched={() => false}
                        value={type}
                        name="type"
                    />
                </div>

                <div className="wfull mr5">
                    <div className="label">
                        {translate("ads.selectPayment")}
                    </div>
                    <InputSelect
                        options={optionPaymentId}
                        onChange={(e) => handleFilterChange({ paymentId: e })}
                        onTouched={() => false}
                        value={paymentId}
                        name="paymentId"
                    />
                </div>

                <div className="wfull mr5">
                    <div className="label">{translate("ads.selectActive")}</div>
                    <InputSelect
                        options={optionsPublishType}
                        onChange={(e) => handleFilterChange({ publishType: e })}
                        onTouched={() => false}
                        value={publishType}
                        name="publishType"
                    />
                </div>

                {/* <div className="wfull">
                <div className="label">
                    {translate("ads.selectPrice")}
                </div>
                <InputWraper
                    inputProps={{
                        name: "price",
                        onChange: (e) => handleFilterChange({ price: e > 0 ? e : null }, 300),
                        onTouched: () => false,
                        value: price
                    }}
                    renderInput={(inputProps) => <InputNumber {...inputProps} className="textRight" thousandSeparator />}
                />
            </div> */}
            </div>

            <div className="group">
                <div className="wfull mr5">
                    <div className="label"> {translate("ads.createdTime")}</div>
                    <InputDateTimeRange
                        onChange={(e) =>
                            handleFilterChange(
                                {
                                    fromCreatedDate: e?.startTime
                                        ? DateTimeUtils.dateToYMD(e?.startTime)
                                        : undefined,
                                    toCreatedDate: e?.endTime
                                        ? DateTimeUtils.dateToYMD(e?.endTime)
                                        : undefined,
                                },
                                300
                            )
                        }
                        startTimeDefaultValue={fromCreatedDate}
                        endTimeDefaultValue={toCreatedDate}
                    />
                </div>

                <div className="wfull">
                    <div className="label">
                        {" "}
                        {translate("ads.modifiedTime")}
                    </div>
                    <InputDateTimeRange
                        onChange={(e) =>
                            handleFilterChange(
                                {
                                    fromModifiedDate: e?.startTime
                                        ? DateTimeUtils.dateToYMD(e?.startTime)
                                        : undefined,
                                    toModifiedDate: e?.endTime
                                        ? DateTimeUtils.dateToYMD(e?.endTime)
                                        : undefined,
                                },
                                300
                            )
                        }
                        startTimeDefaultValue={fromModifiedDate}
                        endTimeDefaultValue={toModifiedDate}
                    />
                </div>
            </div>

            <div>
                <div className="btnGroup">
                    <Button
                        label={translate("all.search")}
                        onClick={() => onFilter(filter)}
                    />
                    <span
                        className="btn btn__edit"
                        onClick={() => {
                            setFilter(defaultFilterAds);
                            onFilter(defaultFilterAds);
                        }}
                    >
                        <Icon.Remove />
                    </span>
                </div>
            </div>
        </>
    );
};

const ConfirmClose = ({ item, onConfirm }: any) => {
    const confirmDelete = async () => {
        const res = await AdsService.updateActiveStatusAds({
            publishType: adsPublishType.DISABLED,
            id: item.adsId,
        });

        if (res?.success) {
            onConfirm();
        }
    };

    return (
        <div className="Info">
            <div className="mb20">{translate("ads.closeConfirm")}</div>
            <Button
                label={translate("all.confirm")}
                onClick={confirmDelete}
                isMiddle
                className="btnBan"
                type="submit"
            />
        </div>
    );
};

function RemoveDuplicateElement(array: any, keyName: string) {
    return array.filter(
        (item: any, index: number, self: any) =>
            index === self.findIndex((t: any) => t[keyName] === item[keyName])
    );
}

const defaultItemAds = {
    formType: AdsFormType.ADD,
    type: "BUY",
    priceType: "FIXED",
    paymentLimitTime: 15,
    paymentInfoIds: [],
    fiatId: 1, // VND
    publishType: adsPublishType.ONLINE,
    conditions: [{ type: adsEKyc.COMPLETE_KYC }],
    price: 0,
    minimumOrderPrice: 0,
    maximumOrderPrice: 0,
    totalAmount: 0,
    totalQuantity: 0,
};

const defaultFilterAds = {};
