import React, { FC, useState } from "react";
import "./index.scss";
import { Button, useForm, InputWraper, CreateAlert } from "../../../../modules";
import { translate } from "../../../../languages";
import * as Yup from "yup";
import {
    InputText,
    InputCheckbox,
    InputSelectAsync,
    InputDateTime,
    InputTextArea,
    InputNumber,
} from "../../../../components";
import { AdminService } from "../../../../services/admin";
import { IpoService } from "../../../../services";
import ImageUploader from "react-images-upload";
import { EDigitalType, EInvestorCategory } from "../../enumIPO";
import { useNavigate } from "react-router-dom";

const digitalTypes = [
    {
        value: EDigitalType.EQUITIZATION,
        label: "Cổ phần hoá",
    },
    {
        value: EDigitalType.CERTIFICATIONS,
        label: "Chứng chỉ hoá",
    },
    {
        value: EDigitalType.BIOCHEMICAL_DERIVATION,
        label: "Phái sinh hoá",
    },
    {
        value: EDigitalType.SECURITIZATION,
        label: "Chứng khoán hoá",
    },
];
const investorCategories = [
    {
        value: EInvestorCategory.CODE,
        label: "Mã số",
    },
    {
        value: EInvestorCategory.LEGAL_ENTITY_NAME,
        label: "Tên cá nhân/Pháp nhân",
    },
    {
        value: EInvestorCategory.TAX_CODE,
        label: "Mã số pháp lý/Thuế (ĐKKD)",
    },
    {
        value: EInvestorCategory.PERMANENT_ADDRESS,
        label: "Địa chỉ thường trú/Trụ sở",
    },
    {
        value: EInvestorCategory.WEBSITE_ADDRESS,
        label: "Địa chỉ web site",
    },
];

export const CreateEdit: FC<{
    data: any;
    onFinishEdit: () => void;
    onClose: () => void;
}> = (props) => {
    const {
        isCreate,
        name,
        symbol,
        description,
        digitalType,
        ipoDate,
        listingNumber,
        publishedPrice,
        isActive,
        ipoManagerId,
        investor = {},
    } = props.data;
    const navigate = useNavigate();

    const [valueCheckbox, setValueCheckbox] = useState(isActive);
    const [picture, setPicture] = useState<any>([]);
    const { handleSubmit, getInputProps, isSubmitting, setValues, values } =
        useForm({
            structure: [
                {
                    name: "name",
                    defaultValue: name,
                    validate: Yup.string().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "symbol",
                    defaultValue: symbol,
                    validate: Yup.string().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "description",
                    defaultValue: description,
                    validate: Yup.string(),
                },
                {
                    name: "digitalType",
                    defaultValue: digitalType || EDigitalType.EQUITIZATION,
                    validate: Yup.string().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "ipoDate",
                    defaultValue: ipoDate,
                    validate: Yup.string().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "listingNumber",
                    defaultValue: listingNumber,
                    validate: Yup.number().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "publishedPrice",
                    defaultValue: publishedPrice,
                    validate: Yup.number().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "investorCode",
                    defaultValue: investor.code,
                    validate: Yup.string().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "investorCategory",
                    defaultValue: investor.category,
                    validate: Yup.string().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "investorStatementAddress",
                    defaultValue: investor.statementAddress,
                    validate: Yup.string().required(
                        translate("validate.mustProvided")
                    ),
                },
                {
                    name: "investorPublishedAddress",
                    defaultValue: investor.publishedAddress,
                    validate: Yup.string().required(
                        translate("validate.mustProvided")
                    ),
                },
            ],
            onSubmit: async (values) => {
                if (isCreate) {
                    return IpoService.createIpo({
                        name: values.name,
                        symbol: values.symbol,
                        description: values.description,
                        digitalType: values.digitalType,
                        ipoDate: values.ipoDate,
                        listingNumber: values.listingNumber,
                        publishedPrice: values.publishedPrice,
                        investorCode: values.investorCode,
                        investorCategory: values.investorCategory,
                        investorStatementAddress:
                            values.investorStatementAddress,
                        investorPublishedAddress:
                            values.investorPublishedAddress,
                        isActive: valueCheckbox,
                    })
                        .then(() => {
                            CreateAlert({
                                message: translate("all.createSuccess"),
                                type: "success",
                            });
                            props.onFinishEdit();
                        })
                        .catch((error) => {
                            CreateAlert({
                                message: error.message,
                                type: "danger",
                            });
                        });
                }
                return IpoService.updateIpo({
                    name: values.name,
                    symbol: values.symbol,
                    description: values.description,
                    digitalType: values.digitalType,
                    ipoDate: values.ipoDate,
                    listingNumber: values.listingNumber,
                    publishedPrice: values.publishedPrice,
                    investorCode: values.investorCode,
                    investorCategory: values.investorCategory,
                    investorStatementAddress: values.investorStatementAddress,
                    investorPublishedAddress: values.investorPublishedAddress,
                    isActive: valueCheckbox,
                    id: ipoManagerId,
                })
                    .then(() => {
                        CreateAlert({
                            message: translate("all.updateSuccess"),
                            type: "success",
                        });
                        props.onFinishEdit();
                    })
                    .catch((error) => {
                        CreateAlert({
                            message: error.message,
                            type: "danger",
                        });
                    });
            },
        });

    const handleChange = (name: any, value: any) => {
        setValues({ [name]: value });
    };

    return (
        <form className="Edit" onSubmit={handleSubmit}>
            <h3>Thông tin IPO</h3>

            <div className="label image">{translate("cms.image")}</div>
            <div className="group">
                <ImageUploader
                    singleImage={true}
                    withIcon={false}
                    withPreview={true}
                    buttonText={translate("cms.selectImage")}
                    onChange={(e) => {
                        setPicture(e);
                    }}
                    imgExtension={[".jpg", ".png"]}
                    maxFileSize={5242880}
                />
            </div>
            <div className="group">
                <InputCheckbox
                    label={translate("all.active")}
                    onChange={(value) => setValueCheckbox(value)}
                    name="active"
                    value={valueCheckbox}
                    onTouched={() => false}
                />
            </div>
            <div className="group">
                <InputWraper
                    label={translate("all.name") + " *"}
                    inputProps={getInputProps("name")}
                    component={InputText}
                    className="mr5"
                />

                <InputWraper
                    label={"Số hóa *"}
                    inputProps={getInputProps("digitalType")}
                    className="ml5"
                    renderInput={(inputProps) => {
                        return (
                            <InputSelectAsync
                                {...inputProps}
                                onChange={(value) =>
                                    handleChange("digitalType", value)
                                }
                                onTouched={() => false}
                                name="digitalType"
                                defaultOptions={digitalTypes}
                                defaultValue={digitalTypes.find(
                                    (item: any) =>
                                        item.value === values["digitalType"]
                                )}
                            />
                        );
                    }}
                />
            </div>
            <div className="group">
                <InputWraper
                    label={translate("ipo.symbol") + " *"}
                    inputProps={getInputProps("symbol")}
                    component={InputText}
                    className="mr5"
                />
                <div className="wfull ml5">
                    <div className="label">Ngày IPO *</div>
                    <InputDateTime
                        defaultValue={values["ipoDate"]}
                        isSecondTime
                        // minDate={isSecondTime && minDate ? minDate * 1000 : minDate}
                        onChange={(date: any) => handleChange("ipoDate", date)}
                    />
                </div>
            </div>
            <div className="group">
                <InputWraper
                    label={"Số lượng niêm yết *"}
                    inputProps={getInputProps("listingNumber")}
                    renderInput={(inputProps) => (
                        <InputNumber
                            {...inputProps}
                            suffix={""}
                            thousandSeparator={true}
                        />
                    )}
                    className="mr5"
                />
                <InputWraper
                    label={"Mệnh giá phát hành *"}
                    inputProps={getInputProps("publishedPrice")}
                    renderInput={(inputProps) => (
                        <InputNumber
                            {...inputProps}
                            suffix={"VND"}
                            thousandSeparator={true}
                        />
                    )}
                    className="ml5"
                />
            </div>

            <div className="group">
                <InputWraper
                    label={"Mô tả"}
                    inputProps={getInputProps("description")}
                    component={InputTextArea}
                />
            </div>
            <h3>Thông tin chủ đầu tư</h3>

            <div className="group">
                <InputWraper
                    label={"Danh mục chủ đầu tư *"}
                    inputProps={getInputProps("investorCategory")}
                    className="mr5"
                    renderInput={(inputProps) => {
                        return (
                            <InputSelectAsync
                                {...inputProps}
                                onChange={(value) =>
                                    handleChange("investorCategory", value)
                                }
                                onTouched={() => false}
                                name="investorCategory"
                                defaultOptions={investorCategories}
                                defaultValue={investorCategories.find(
                                    (item: any) =>
                                        item.value ===
                                        values["investorCategory"]
                                )}
                            />
                        );
                    }}
                />
                <InputWraper
                    label={"Mã chủ đầu tư *"}
                    inputProps={getInputProps("investorCode")}
                    component={InputText}
                    className="ml5"
                />
            </div>
            <div className="group">
                <InputWraper
                    label={"Địa chỉ bản cáo hạch *"}
                    inputProps={getInputProps("investorStatementAddress")}
                    component={InputText}
                    className="mr5"
                />
                <InputWraper
                    label={"Địa chỉ phát hành *"}
                    inputProps={getInputProps("investorPublishedAddress")}
                    component={InputText}
                    className="ml5"
                />
            </div>

            <div className="groupButton">
                <Button
                    label={translate("all.cancel")}
                    buttonType="danger"
                    onClick={props.onClose}
                />
                <Button
                    // className="mb20"
                    isLoading={isSubmitting}
                    label={
                        isCreate
                            ? translate("all.create")
                            : translate("all.update")
                    }
                    className="btnBan ml5"
                    type="submit"
                />
            </div>
        </form>
    );
};
