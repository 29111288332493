import React, { FC, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { IInputProps } from "../../../modules";

interface IInputSelectAsync extends IInputProps {
    options?: any;
    defaultOptions?: any;
    isClearable?: boolean;
    isMulti?: boolean;
    loadOptions?: any;
    isDisabled?: boolean;
}

export const InputSelectAsync: FC<IInputSelectAsync> = (props) => {
    const { loadOptions, defaultOptions, placeholder } = props;

    const generalProps = {
        isMulti: props.isMulti,
        className: "InputSelect",
        classNamePrefix: "InputSelect",
        isSearchable: true,
        placeholder: placeholder || "-- Select --",
        onChange: (data: any) => {
            if (props.isMulti)
                props.onChange((data || []).map((v: any) => v.value));
            else props.onChange(data?.value);
        },
        defaultValue: props.defaultValue,
        onBlur: () => {
            setTimeout(() => props.onTouched(), 500);
        },
        isClearable:
            typeof props.isClearable === "boolean" ? props.isClearable : true,
        isDisabled: props.isDisabled,
        autoFocus: false,
        cacheOptions: true,
    };

    useEffect(() => {}, [props.value, props.defaultValue]);

    return (
        <AsyncSelect
            {...generalProps}
            loadOptions={loadOptions}
            defaultOptions={defaultOptions}
        />
    );
};

InputSelectAsync.defaultProps = {
    isMulti: false,
};
