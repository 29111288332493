import { RequestMainService } from "../request";

export class PaymentService {
    static async getPaymentList(params?: any) {
        return RequestMainService.get(`/payment`, params).then(
            ({ result }) => ({
                data: result,
                count: result.length,
            })
        );
    }

    static async getPaymentById(id: any) {
        return RequestMainService.get(`/payment/${id}`);
    }

    static async createPayment(params: any) {
        return RequestMainService.post(`/admin/payment`, params);
    }

    static async updatePayment(params: any) {
        return RequestMainService.put(
            `/admin/payment/${params.paymentId}`,
            params
        );
    }

    static async deletePayment(id: any) {
        return RequestMainService.delete(`/admin/payment/${id}`);
    }
}
