import { RequestMainService } from "../request";

export class OrderService {
    static async getListOrders(params: any = {}) {
        return RequestMainService.get(`/admin/ipo-order`, params).then(
            ({ result }) => ({
                count: result.total,
                data: result.ipoOrders,
            })
        );
    }
    static async getDetailOrder(id: any) {
        return RequestMainService.get(`/admin/ipo-order/${id}`);
    }

    static async updateOrderStatus(id: any, params: any) {
        return RequestMainService.put(`/ipo-order/${id}`, params);
    }
}
