import React, { memo, useMemo, useState } from "react";
import { withUserWraper } from "../wraper";
import {
    Table,
    ITableStructureItem,
    Button,
    onError,
    InputWraper,
    CreateAlert,
    DateTimeUtils,
} from "../../../modules";
import { AdminService } from "../../../services/admin";
import { onImageGallery } from "../../../components/image-gallery";
import { translate } from "../../../languages";
import { Icon, InputText, InputTextArea } from "../../../components";
import { PopupWraper } from "../../../components/popup";
import { enumUser } from "../enumUser";
import { Check, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const PageUserKYCList = withUserWraper(
    memo(() => {
        const [params, setParams] = useState<{ keyword: string }>(
            defaultFilter
        );
        const [forceUpdateTable, setForceUpdateTable] = useState(false);

        return (
            <div className="UserKYCList">
                <FilterComponent
                    onFilter={(item: any) => {
                        setParams(item);
                        setForceUpdateTable(!forceUpdateTable);
                    }}
                />
                <TableComponent
                    filter={params}
                    setForceUpdateTable={setForceUpdateTable}
                    forceUpdateTable={forceUpdateTable}
                />
            </div>
        );
    })
);

const TableComponent = memo(
    ({ filter, forceUpdateTable, setForceUpdateTable }: any) => {
        const [rejectKyc, setRejectKyc] = useState<any>();

        const structure: ITableStructureItem[] = [
            {
                name: "Email",
                key: "email",
            },
            {
                name: translate("all.name"),
                key: "firstName",
            },
            {
                name: translate("all.lastName"),
                key: "lastName",
            },
            {
                name: translate("user.documentType"),
                key: "documentType",
                render: (item) => {
                    return enumUser.documentKycType.IDENTITY_CARD ===
                        item.documentType
                        ? translate("all.idCard")
                        : enumUser.documentKycType.DRIVER_LICENSE ===
                          item.documentType
                        ? translate("all.driverLicense")
                        : enumUser.documentKycType.PASSPORT ===
                          item.documentType
                        ? translate("all.passport")
                        : "";
                },
            },
            {
                name: translate("all.birthday"),
                key: "birthday",
                render: (item) =>
                    DateTimeUtils.formatToString(item.birthday, "DD-MM-YYYY"),
            },
            {
                name: translate("user.address"),
                key: "address",
            },
            {
                name: translate("user.city"),
                key: "city",
            },
            {
                name: translate("user.avatar"),
                render: (item) => createImageGallery(item, "avartarUrl"),
            },
            {
                name: translate("user.imageCardFrontOf"),
                render: (item) => createImageGallery(item, "frontOfCardUrl"),
            },
            {
                name: translate("user.imageCardBefore"),
                render: (item) => createImageGallery(item, "backOfCardUrl"),
            },
            // {
            //     name: translate("kyc.status"),
            //     key: "status",
            // },
            {
                name: translate("all.actions"),
                render: (item, fetchData) => {
                    return item.status === "PENDING" ? (
                        <div style={{ display: "flex" }}>
                            <IconButton
                                className="__hover"
                                onClick={() => {
                                    AdminService.kycApprove(item.kycId)
                                        .then(() => {
                                            CreateAlert({
                                                type: "success",
                                                message:
                                                    translate("all.success"),
                                            });
                                            fetchData();
                                        })
                                        .catch(onError);
                                }}
                            >
                                <Check fontSize="large" color="success" />
                            </IconButton>

                            <IconButton
                                className="__hover"
                                onClick={() => setRejectKyc(item)}
                            >
                                <Close fontSize={"large"} color="error" />
                            </IconButton>
                        </div>
                    ) : (
                        ""
                    );
                },
            },
        ];

        const createImageGallery = (item: any, field: string) => {
            const dataImage: any = [];
            if (item.avartarUrl) dataImage.push({ src: item.avartarUrl });
            if (item.backOfCardUrl) dataImage.push({ src: item.backOfCardUrl });
            if (item.frontOfCardUrl)
                dataImage.push({ src: item.frontOfCardUrl });

            return item[field] ? (
                <img
                    onClick={() =>
                        onImageGallery(
                            dataImage.map((v: any) => ({
                                ...v,
                                thumbnail: v.src,
                            })),
                            0
                        )
                    }
                    src={item[field]}
                    style={{
                        cursor: "pointer",
                        height: "100px",
                        width: "200px",
                        objectFit: "contain",
                    }}
                    alt=""
                />
            ) : (
                translate("user.noImage")
            );
        };

        const tableMemo = useMemo(() => {
            return (
                <Table
                    structure={structure}
                    fetchData={async (params) => {
                        return AdminService.getPendingKYC({
                            page: params.pageNumber,
                            numberOfKYCPerPage: params.limit,
                            ...params,
                            ...filter,
                        });
                    }}
                />
            );
        }, [filter, forceUpdateTable]);

        return (
            <>
                {tableMemo}

                {rejectKyc && (
                    <PopupWraper
                        center
                        title={translate("user.rejectReason")}
                        onClose={() => setRejectKyc(null)}
                    >
                        <RejectKycComponent
                            item={rejectKyc}
                            onClose={() => setRejectKyc(null)}
                            onConfirm={() => {
                                setRejectKyc(null);
                                setForceUpdateTable(!forceUpdateTable);
                            }}
                        />
                    </PopupWraper>
                )}
            </>
        );
    }
);

const FilterComponent = ({ onFilter }: any) => {
    const [filter, setFilter] = useState<{ keyword: string }>(defaultFilter);

    return (
        <div className="group">
            <div className="mr5 widthSearch">
                <div className="label">{translate("all.search")}</div>
                <InputWraper
                    inputProps={{
                        value: filter.keyword,
                        name: "keyword",
                        onTouched: () => false,
                        onChange: (value) => setFilter({ keyword: value }),
                    }}
                    component={InputText}
                    placeholder={translate("user.searchKyc")}
                    className="search"
                />
            </div>

            <div className="btnGroup ml5">
                <Button
                    label={translate("all.search")}
                    onClick={() => onFilter(filter)}
                />
                <span
                    className="btn pdl10"
                    onClick={() => {
                        setFilter(defaultFilter);
                        onFilter(defaultFilter);
                    }}
                >
                    <Icon.Remove />
                </span>
            </div>
        </div>
    );
};

const RejectKycComponent = ({ item, onClose, onConfirm }: any) => {
    const [reason, setReason] = useState("");

    const handleRejectKyc = () => {
        if (reason.trim()) {
            AdminService.kycReject(item.kycId, { rejectedReason: reason })
                .then(() => {
                    onConfirm();
                })
                .catch(onError);
        } else {
            CreateAlert({
                type: "danger",
                message: translate("user.requiredRejectReason"),
            });
        }
    };

    return (
        <>
            <div className="group">
                <InputWraper
                    inputProps={{
                        value: reason,
                        name: "reason",
                        onTouched: () => false,
                        onChange: (value) => setReason(value),
                    }}
                    component={InputTextArea}
                />
            </div>

            <div className="groupButton">
                <Button
                    label={translate("all.close")}
                    buttonType="danger"
                    onClick={onClose}
                    className="mr5"
                />
                <Button
                    label={translate("all.confirm")}
                    buttonType="warning"
                    onClick={handleRejectKyc}
                    className="ml5"
                />
            </div>
        </>
    );
};

const defaultFilter = { keyword: "" };
