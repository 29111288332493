import { RequestMainService } from "../request";

const endpoint = "/admin/role-permission";
export class RolePermissionService {
    static async getByRoleId(roleId: any) {
        return RequestMainService.get(`${endpoint}/${roleId}`);
    }

    static async update(params: any) {
        // {id: roleId, permissions: []}
        return RequestMainService.put(`${endpoint}/${params.id}`, params);
    }
}