import React, { useState, memo, useMemo, useImperativeHandle, forwardRef, useRef } from "react";
import {
    ITableStructureItem,
    CreateAlert,
    Button,
    Icon,
    InputWraper,
    Table,
} from "../../../modules";
import { withRoleWraper } from "../wraper";
import { translate } from "../../../languages";
import { PopupWraper } from "../../../components/popup";
import { CreateEditRole } from "./CreateEdit";
import { InputText } from "../../../components";
import { RoleService } from "../../../services/role";
import "./RoleList.scss";
import { roleFormType, roleEnumStatus } from "../enumRole";
import { InputToggleSwitch } from "../../../components/toggle-switch";
import { AdminService } from "../../../services/admin";

export const RoleList = withRoleWraper(
    memo(() => {
        const refTable = useRef<any>();
        const [params, setParams] = useState<any>({});
        const [forceUpdateTable, setForceUpdateTable] = useState(0);

        return (
            <div className="RoleList">
                <Button
                    className="mb20"
                    label={translate("role.addRole")}
                    buttonType="success"
                    onClick={() => refTable.current?.openModalAdd()}
                />

                <FilterComponent
                    onFilter={(newParams: any) => {
                        setParams(newParams)
                        setForceUpdateTable(Math.random())
                    }}
                />

                <TableComponent
                    ref={refTable}
                    filter={params}
                    forceUpdateTable={forceUpdateTable}
                    reRender={() => setForceUpdateTable(Math.random())}
                    setParams={setParams}
                />
            </div>
        );
    })
);

const TableComponent = forwardRef(({ filter, forceUpdateTable, reRender, setParams }: any, ref: any) => {
    const [currentRole, setCurrentRole] = useState<any>(null);

    const openModalEdit = async (item: any, action: string) => {
        if (action === roleFormType.REMOVE) {
            const userRole = await AdminService.getListUser({
                page: 1,
                numberOfItemsPerPage: 10,
                roleId: item.roleId
            });

            const temp = { ...item, formType: action, isRemove: false }
            if (userRole.data.length === 0) temp.isRemove = true
            setCurrentRole(temp)

        } else {
            try {
                const res = await RoleService.getById(item.roleId);
                if (res.success) {
                    setCurrentRole({ ...res.result, formType: action })
                } else {
                    CreateAlert({ type: "danger", message: res.message })
                }
            } catch (error: any) {
                CreateAlert({ type: "danger", message: error.message })
            }
        }
    };

    const structure: ITableStructureItem[] = [
        {
            name: "Role Id",
            key: "roleId",
        },
        {
            name: translate("role.nameRole"),
            key: "roleName",
        },
        {
            name: translate("user.status"),
            key: "status",
            render: (item, fetchData) => {
                return (
                    <div className="btnGroup">
                        <InputToggleSwitch
                            onChange={async (value) => {
                                const res = await RoleService.update({
                                    id: item.roleId,
                                    roleName: item.roleName,
                                    roleStatus: value ? roleEnumStatus.ACTIVE : roleEnumStatus.INACTIVE,
                                });

                                if (res?.success) {
                                    fetchData();
                                } else {
                                    CreateAlert({ type: "danger", message: res.message })
                                }
                            }}
                            value={item.status === roleEnumStatus.ACTIVE ? true : false}
                            onTouched={() => false}
                            label=""
                            name="roleStatus"
                        />
                    </div>
                );
            }
        },
        {
            name: translate("all.actions"),
            key: "actions",
            render: (item) => {
                return (
                    <div className="btnGroup">
                        <span
                            className="btn btn__edit"
                            onClick={() => openModalEdit(item, roleFormType.EDIT)}
                        >
                            <Icon.Edit />
                        </span>
                        <span
                            className="btn btn__edit"
                            onClick={() => openModalEdit(item, roleFormType.REMOVE)}
                        >
                            <Icon.Trash />
                        </span>
                    </div>
                );
            },
        },
    ];

    const tableMemo = useMemo(() => (
        <Table
            structure={structure}
            fetchData={async (params) => {
                setParams(params)
                return RoleService.get({
                    page: params.pageNumber,
                    numberOfRolePerPage: params.limit,
                    ...filter
                });
            }}
        />
    ), [filter, forceUpdateTable])

    useImperativeHandle(ref, () => ({
        openModalAdd: () => setCurrentRole({ formType: roleFormType.ADD, status: roleEnumStatus.ACTIVE })
    }));

    return <>
        {tableMemo}

        {(
            currentRole?.formType === roleFormType.ADD
            || currentRole?.formType === roleFormType.EDIT
        ) && (
                <PopupWraper
                    center
                    title={
                        currentRole.formType === roleFormType.ADD
                            ? translate("role.create")
                            : translate("role.update")
                    }
                    onClose={() => setCurrentRole(null)}
                >
                    <CreateEditRole
                        data={currentRole}
                        onClose={() => setCurrentRole(null)}
                        onFinishEdit={() => {
                            reRender();
                            setCurrentRole(null);
                        }}
                    />
                </PopupWraper>
            )}

        {(
            currentRole?.formType === roleFormType.REMOVE
        ) && (
                <PopupWraper
                    center
                    title={currentRole.isRemove ? translate("role.removeConfirmTitle") : translate("role.removeDenialTitle")}
                    onClose={() => setCurrentRole(null)}
                >
                    <ConfirmRemoveRole
                        data={currentRole}
                        onClose={() => setCurrentRole(null)}
                        onFinishEdit={() => {
                            reRender();
                            setCurrentRole(null);
                        }}
                    />
                </PopupWraper>
            )}
    </>
})

const FilterComponent = ({ onFilter }: any) => {
    const [filter, setFilter] = useState<any>({});
    const { roleName } = filter

    return <div style={{ alignItems: "center", display: "flex" }}>
        <div style={{ width: "200px" }} className="itemSelect mr5">
            <div className="label">
                {translate("role.nameRole")}
            </div>
            <InputWraper
                inputProps={{
                    onChange: (e) => setFilter({ roleName: e }),
                    value: roleName,
                    name: "name",
                    onTouched: () => false,
                }}
                component={InputText}
            />
        </div>
        <div>
            <div className="btnGroup">
                <Button
                    label={translate("all.search")}
                    onClick={() => onFilter(filter)}
                />
                <span
                    className="btn btn__edit"
                    onClick={() => {
                        setFilter({ roleName: "" })
                        onFilter({ roleName: "" })
                    }}
                >
                    <Icon.Remove />
                </span>
            </div>
        </div>
    </div>
}

const ConfirmRemoveRole = ({ data, onClose, onFinishEdit }: any) => {
    const { roleId, isRemove } = data
    if (isRemove) {
        return <>
            {translate("role.removeConfirmTitle")}?
            <Button
                isMiddle
                className="mt20"
                label={translate("all.confirm")}
                onClick={() => {
                    RoleService.delete(roleId)
                        .then((res) => {
                            if (res.success) {
                                CreateAlert({ type: "success", message: "Success!" })
                                onFinishEdit()
                            } else {
                                CreateAlert({ type: "danger", message: res.message })
                            }
                        })
                        .catch(error => {
                            CreateAlert({ type: "danger", message: error?.message })
                        })
                }}
            />
        </>
    }
    return <>
        {translate("role.removeDenialContent")}
        <Button
            isMiddle
            label={translate("all.close")}
            buttonType="danger"
            onClick={onClose}
            className="mt20"
        />
    </>
}
