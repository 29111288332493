import React, { FC, useEffect, useState } from "react";
import { InputCheckbox, InputText } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";

import { withFranchiseWraper } from "../wraper";
import * as Yup from "yup";
import { SettingService } from "../../../services/setting";
import { Box } from "@mui/material";

export const PageSetting = withFranchiseWraper((props) => {
    const [valueCheckbox, setValueCheckbox] = useState(false);
    const { handleSubmit, getInputProps, isSubmitting, setValues } = useForm({
        structure: [
            {
                name: "reloadTimes",
                validate: Yup.string()
                    .required(translate("validate.mustProvided"))
                    .test(
                        "checkDuplEmail",
                        "Chỉ được nhập giá trị số và dấu phẩy",
                        async (value) => {
                            return !value
                                ?.split(",")
                                ?.some((item) => isNaN(+item));
                        }
                    ),
            },
        ],
        onSubmit: async (values) => {
            const [res, resMaintenance] = await Promise.all([
                SettingService.updateReloadOrder({
                    reloadTimes: values.reloadTimes
                        .replaceAll(" ", "")
                        .split(","),
                }),
                SettingService.updateMaintenance({
                    isMaintenancing: valueCheckbox,
                }),
            ]);

            if (res.success && resMaintenance.success) {
                CreateAlert({
                    message: translate("all.updateSuccess"),
                    type: "success",
                });
            }

            if (!res.success) {
                CreateAlert({
                    message: res.message,
                    type: "danger",
                });
            }
            if (!resMaintenance.success) {
                CreateAlert({
                    message: resMaintenance.message,
                    type: "danger",
                });
            }
        },
    });

    useEffect(() => {
        (async () => {
            const [res, resMaintenance] = await Promise.all([
                SettingService.getReloadOrder({
                    getConfigOnly: true,
                }),
                SettingService.getMaintenance({
                    getConfigOnly: true,
                }),
            ]);
            setValues({
                reloadTimes:
                    res.result.config.configuration.reloadTimes.join(", "),
            });
            setValueCheckbox(
                !!resMaintenance.result?.config?.configuration?.isMaintenancing
            );
        })();
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <InputWraper
                label={translate("setting.reloadTimes")}
                inputProps={getInputProps("reloadTimes")}
                component={InputText}
                placeholder="Nhập giá trị thời gian vào, cách nhau bằng dấu phẩy"
            />
            <InputCheckbox
                label={translate("setting.maintenance")}
                onChange={(value) => setValueCheckbox(value)}
                name="active"
                value={valueCheckbox}
                onTouched={() => false}
            />
            <Button
                // className="mb20"
                isLoading={isSubmitting}
                label={translate("all.save")}
                // onClick={props.onClick}
                isMiddle
                // buttonType="warning"
                className="btnBan"
                type="submit"
            />
        </form>
    );
});
