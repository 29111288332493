import React, { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { translate } from "../../languages";

export const withRoleWraper = (Component: FC) => (props: any) => {
    const location = useLocation()
    const checkActive = (route: any) => {
        if (!location) return "";
        const { pathname } = location;
        return pathname === route ? "active" : "";
    }

    return (
        <div className="withUserWraper">
            <div className="TabLinks">
                <NavLink to={Routes.roleList.path}
                 className={() => checkActive(Routes.roleList.path)}
                >
                    <Icon.User />
                    {translate("role.list")}
                </NavLink>

                <NavLink to={Routes.permission.path}
                    className={() => checkActive(Routes.permission.path)}
                >
                    <Icon.KYC />
                    {translate("role.name")}
                </NavLink>
            </div>

            <Component {...props} />
        </div>
    );
};
