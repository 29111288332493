import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { translate } from "../../languages";

export const withCmsWraper = (Component: FC) => (props: any) => {
    return (
        <div className="withCmsWraper">
            {/* <div className="TabLinks">
                <NavLink to={Routes.cmsList.path}>
                    <Icon.User />
                    {translate("cms.list")}
                </NavLink>

                <NavLink to={Routes.cmsList.path.concat("/new")}>
                    <Icon.KYC />
                    {translate("cms.create")}
                </NavLink>
            </div> */}

            <Component {...props} />
        </div>
    );
};
