import { Box } from '@mui/material';
import React, { FC } from 'react'
import NumberFormat from 'react-number-format';
import { IInputProps } from '../../../modules';

interface IInputNumber extends IInputProps {
    suffix?: string;
    thousandSeparator?: string | boolean;
    decimalScale?: number;
    className?: string;
}
export const InputNumber: FC<IInputNumber> = ({ value, onChange, onTouched,
    suffix, thousandSeparator, decimalScale, className }) => {
    return (
        <NumberFormat
            fixedDecimalScale={false}
            value={typeof value === 'number' ? value : undefined}
            onValueChange={({ floatValue }) => {
                const data = floatValue || 0;
                onChange(data);
            }}
            onBlur={() => setTimeout(() => onTouched(), 500)}
            suffix={suffix}
            decimalScale={decimalScale}
            thousandSeparator={thousandSeparator}
            className={className}
        />
    )
}

interface IInputNumberUpDown extends IInputNumber {
    stepNumber?: number;
}

export const InputNumberUpDown: FC<IInputNumberUpDown> = ({ value, onChange, onTouched,
    stepNumber = 1, suffix, thousandSeparator, decimalScale }) => {
    const handleUpDown = (type: string) => {
        let newValue = value || 0;
        if (type === "DOWN") {
            newValue -= (stepNumber || 1)
        } else {
            newValue += (stepNumber || 1)
        }
        onChange(newValue || 0);
    }

    return <>
        <Box fontSize={25} onClick={() => handleUpDown("DOWN")}
            style={{ alignSelf: "center", cursor: "pointer" }}
        >
            <i className="fa-solid fa-minus"></i>
        </Box>
        <NumberFormat
            fixedDecimalScale={false}
            value={typeof value === 'number' ? value : undefined}
            onValueChange={({ floatValue }) => {
                const data = floatValue || 0;
                onChange(data);
            }}
            onBlur={() => setTimeout(() => onTouched(), 500)}
            suffix={suffix}
            decimalScale={decimalScale}
            thousandSeparator={thousandSeparator}
        />
        <Box fontSize={25} onClick={() => handleUpDown("UP")}
            style={{ alignSelf: "center", cursor: "pointer" }}
        >
            <i className="fa-solid fa-plus"></i>
        </Box>
    </>
}

InputNumberUpDown.defaultProps = {
    stepNumber: 1
}