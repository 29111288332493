import { RequestMainService } from "../request";

export class FeedbackService {
    static async getFeedbackList(params: any = {}) {
        return RequestMainService.get(`/admin/ipo-feedback`, params).then(
            ({ result }) => ({
                count: result.total,
                data: result.list,
            })
        );
    }
    static async updateDisplay(params: any = {}) {
        return RequestMainService.post(`/ipo-feedback/toggle-display`, params);
    }
    static getListFeedbackStatus() {
        return [
            { label: "Ẩn", value: "false" },
            { label: "Hiện", value: "true" },
        ];
    }
    static getListFeedbackCategory() {
        return [
            { label: "Like", value: "true" },
            { label: "Dislike", value: "false" },
        ];
    }
}
