import { RequestMainService } from "../request";
import { CookieService, ECookieVariable } from "../cookie";
import { ObjectUtils } from "../../modules";

export class SettingService {
    static async updateReloadOrder(params: any) {
        return RequestMainService.post(
            `/system-config/reload-order/upsert`,
            params
        );
    }
    static async updateMaintenance(params: any) {
        return RequestMainService.post(
            `/system-config/system-maintenance`,
            params
        );
    }
    static async getMaintenance(params: any = {}) {
        return RequestMainService.get(
            `/system-config/system-maintenance`,
            params
        );
    }
    static async getReloadOrder(params: any = {}) {
        return RequestMainService.get(`/system-config/reload-order`, params);
    }
}
