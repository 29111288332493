import React, { FC } from "react";

export const withReportWraper = (Component: FC) => (props: any) => {
    return (
        <div className="withReportWraper">
            {/* <div className="TabLinks">
                <NavLink to={Routes.transactionList.path} exact={true}>
                    <Icon.Exchange />

                    Transactions
                </NavLink>

                <NavLink to={Routes.withdraw.path} exact={true}>
                    <Icon.KYC />

                    Withdraw Progress
                </NavLink>
            </div> */}

            <Component {...props} />
        </div>
    );
};
