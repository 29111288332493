import React, { useState, useEffect, FC, memo } from 'react'
import XLSX from 'xlsx'

import { withReportWraper } from '../wraper'
import { AdminService } from '../../../services/admin';
import { InputDateTimeRange } from '../../../components/table-filter-inputs';
import { Message, Table, Button, ObjectUtils, CreateAlert, ITableStructureItem } from '../../../modules';
import { getLocaleKey } from '../../../languages';
import { Icon, InputSelect } from '../../../components';

export const ReportSystem = withReportWraper(memo(() => {
    const [rangeTime, setRangeTime] = useState({
        startTime: Date.now() - 1000 * 60 * 60 * 24 * 30,
        endTime: Date.now(),
    } as any);

    const [reports, setReports] = useState(null as any);
    const [systemReportDetail, setSystemReportDetail] = useState(null as any);
    const [modeOption, setModeOption] = useState('');

    useEffect(() => {
        if (!rangeTime) {
            setReports(null);
        } else {
            setReports(null);

            AdminService.getSytemReport({
                fromDate: new Date(rangeTime.startTime),
                toDate: new Date(rangeTime.endTime),
            })
                .then(res => {
                    const sumReports = Object.keys(res[0]).reduce((ouput: any, key: string) => {
                        if (typeof res[0][key] === 'number') ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                        return ouput;
                    }, {})

                    setReports({
                        count: res.length,
                        data: res.sort((a: any, b: any) => b.start - a.start),
                        sumReports
                    });
                })
                .catch(err => setReports({ error: err.error }));
        }
    }, [rangeTime])

    const ModeOptions = [
        { label: 'Balance', value: 'userBalance' },
        { label: 'Hot Wallet', value: 'hotWallet' },
        { label: 'Withdraw', value: 'withdraw' },
        { label: 'Deposit', value: 'deposit' },
        { label: 'Safe', value: 'safe' },
    ]

    const sumMiddleware = (func: (item: any) => any) => (item: any, fetchData: any, column: ITableStructureItem) => {
        if (item.isSum) {
            if (column.key === 'date') return 'SUM';
            if (column.key && reports && reports.sumReports && typeof reports.sumReports[column.key] === 'number') {
                if (Number.isNaN(reports.sumReports[column.key])) return '--';
                const value = (+reports.sumReports[column.key] || 0).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 });
                if (column.key === 'lostVolume') return `-$${value}`;
                return `$${value}`;
            }

            return '--';
        }

        return func(item);
    }

    const structure = [
        {
            name: 'Date',
            key: 'date',
            render: sumMiddleware((item) => {
                return <div>
                    {new Date(item.start).toLocaleDateString(getLocaleKey(), { hour12: false })}
                </div>
            }),
            sort: {
                key: 'sort-date',
            }
        },
        {
            name: 'Won volume',
            key: 'wonVolume',
            // className: 'textSuccess',
            render: sumMiddleware((item: any) => {
                return `$${(+item.wonVolume).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'sort-won-volume',
            }
        },
        {
            name: 'Lose volume',
            // className: 'textDanger',
            key: 'lostVolume',
            render: sumMiddleware((item: any) => {
                const value = +item.lostVolume;
                return value ? `-$${(+item.lostVolume).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}` : 0
            }),
            sort: {
                key: 'sort-lost-volume',
            }
        },
        {
            name: 'Hot Wallet BOF',
            key: 'hotWalletBof',
            render: sumMiddleware((item: any) => {
                return `$${(+item.hotWalletBof).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'hotWalletBof',
            }
        },
        {
            name: 'Hot Wallet ETH',
            key: 'hotWalletEth',
            render: sumMiddleware((item: any) => {
                return `$${(+item.hotWalletEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'hotWalletEth',
            }
        },
        {
            name: 'Hot Wallet Usdp',
            key: 'hotWalletUsdp',
            render: sumMiddleware((item: any) => {
                return `$${(+item.hotWalletUsdp).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'hotWalletUsdp',
            }
        },
        {
            name: 'Hot Wallet Usdt',
            key: 'hotWalletUsdt',
            render: sumMiddleware((item: any) => {
                return `$${(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'hotWalletUsdt',
            }
        },
        {
            name: 'Deposit BOF',
            key: 'depositBof',
            render: sumMiddleware((item: any) => {
                return `$${(+item.depositBof).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'depositBof',
            }
        },
        {
            name: 'Deposit ETH',
            key: 'depositEth',
            render: sumMiddleware((item: any) => {
                return `$${(+item.depositEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'depositEth',
            }
        },
        {
            name: 'Deposit Usdp',
            key: 'depositUsdp',
            render: sumMiddleware((item: any) => {
                return `$${(+item.depositUsdp).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'depositUsdp',
            }
        },
        {
            name: 'Deposit Usdt',
            key: 'depositUsdt',
            render: sumMiddleware((item: any) => {
                return `$${(+item.depositUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'depositUsdt',
            }
        },
        {
            name: 'Withdraw BOF',
            key: 'withdrawBof',
            render: sumMiddleware((item: any) => {
                return `$${(+item.withdrawBof).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'withdrawBof',
            }
        },
        {
            name: 'Withdraw ETH',
            key: 'withdrawEth',
            render: sumMiddleware((item: any) => {
                return `$${(+item.withdrawEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'withdrawEth',
            }
        },
        {
            name: 'Withdraw Usdp',
            key: 'withdrawUsdp',
            render: sumMiddleware((item: any) => {
                return `$${(+item.withdrawUsdp).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'withdrawUsdp',
            }
        },
        {
            name: 'Withdraw Usdt',
            key: 'withdrawUsdt',
            render: sumMiddleware((item: any) => {
                return `$${(+item.withdrawUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'withdrawUsdt',
            }
        },
        {
            name: 'User Blanace BOF',
            key: 'userBalanceBof',
            render: sumMiddleware((item: any) => {
                return `$${(+item.userBalanceBof).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'userBalanceBof',
            }
        },
        {
            name: 'User Blanace ETH',
            key: 'userBalanceEth',
            render: sumMiddleware((item: any) => {
                return `$${(+item.userBalanceEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'userBalanceEth',
            }
        },
        {
            name: 'User Blanace Usdp',
            key: 'userBalanceUsdp',
            render: sumMiddleware((item: any) => {
                return `$${(+item.userBalanceUsdp).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'userBalanceUsdp',
            }
        },
        {
            name: 'User Blanace Usdt',
            key: 'userBalanceUsdt',
            render: sumMiddleware((item: any) => {
                return `$${(+item.userBalanceUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'userBalanceUsdt',
            }
        },
        {
            name: 'Total order',
            key: 'orderCount',
            render: sumMiddleware((item: any) => {
                return (+item.orderCount).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })
            }),
            sort: {
                key: 'sort-total-order',
            }
        },
        {
            name: 'Login Count',
            key: 'loginCount',
            render: sumMiddleware((item: any) => {
                return (+item.loginCount).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })
            }),
            sort: {
                key: 'sort-login-count',
            }
        },
        {
            name: 'Safe BOF',
            key: 'safeBof',
            render: sumMiddleware((item: any) => {
                return `$${(+item.safeBof).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'safeBof',
            }
        },
        {
            name: 'Safe ETH',
            key: 'safeEth',
            render: sumMiddleware((item: any) => {
                return `$${(+item.safeEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'safeEth',
            }
        },
        {
            name: 'Safe Usdp',
            key: 'safeUsdp',
            render: sumMiddleware((item: any) => {
                return `$${(+item.safeUsdp).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'safeUsdp',
            }
        },
        {
            name: 'Safe Usdt',
            key: 'safeUsdt',
            render: sumMiddleware((item: any) => {
                return `$${(+item.safeUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}`
            }),
            sort: {
                key: 'safeUsdt',
            }
        },
    ].filter(v => {
        if (modeOption === 'hotWallet') return ['date', 'hotWalletBof', 'hotWalletEth', 'hotWalletUsdp', 'hotWalletUsdt'].includes(v.key);
        if (modeOption === 'deposit') return ['date', 'depositBof', 'depositEth', 'depositUsdp', 'depositUsdt'].includes(v.key);
        if (modeOption === 'withdraw') return ['date', 'withdrawBof', 'withdrawEth', 'withdrawUsdp', 'withdrawUsdt'].includes(v.key);
        if (modeOption === 'userBalance') return ['date', 'userBalanceBof', 'userBalanceEth', 'userBalanceUsdp', 'userBalanceUsdt'].includes(v.key);
        if (modeOption === 'safe') return ['date', 'safeBof', 'safeEth', 'safeUsdp', 'safeUsdt'].includes(v.key);
        return true;
    })

    const handleExportExcel = async () => {
        return new Promise((resolve) => {
            try {
                const reportData = ObjectUtils.getIn(reports, 'data', []);
                const fileHead = structure.map(v => v.name);
                const data = reportData.map((item: any) => structure.map((column, index) => {
                    if (index === 0) return new Date(item['start']).toLocaleString(getLocaleKey());
                    return item[column.key];
                }));

                const dataExport = [
                    fileHead,
                    ...data,
                    structure.map((column, index) => {
                        if (index === 0) return 'SUM';
                        return reports.sumReports[column.key] || '';
                    })
                ]

                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                const now = new Date();
                XLSX.writeFile(wb, `System report ${now.toLocaleDateString().replace(/\//g, '-')} ${now.toLocaleTimeString().replace(/:/g, '-')}.xlsx`, { type: 'binary' })

                resolve(CreateAlert({ type: 'success', message: 'Export data success.' }))
            } catch (error:any) {
                resolve(CreateAlert({ type: "danger", message: error.message }));
            }
        })
    }

    return (
        <section className="box">
            <div className="title">
                System report
            </div>

            <SystemReportDetailPopup
                detail={systemReportDetail}
                onClose={() => setSystemReportDetail(null)}
            />

            <div className="SystemReport">
                <div className="SystemReport__Head">
                    <div className="item">
                        <div className="label">Range time</div>
                        <InputDateTimeRange
                            onChange={e => setRangeTime(e)}
                            startTimeDefaultValue={rangeTime?.startTime}
                            endTimeDefaultValue={rangeTime?.endTime}
                        />
                    </div>
                    <div className="item">
                        <div className="label">Mode</div>
                        <InputSelect options={ModeOptions} onChange={e => setModeOption(e)} onTouched={() => false} value={modeOption} name="" />
                    </div>

                    <div className="item">
                        <div className="label">Export</div>
                        <Button
                            label="Excel"
                            buttonType="success"
                            disabled={!reports || (reports.data && reports.data.length === 0)}
                            onClick={handleExportExcel}
                        />
                    </div>
                </div>

                {(() => {
                    if (!rangeTime) return <Message type="info" message="You have to select range time to export report" />
                    if (!reports) return <Message type="loading" />
                    if (reports.error) return <Message type="error" {...reports.error} />

                    return <Table
                        className="overload hideFooter"
                        structure={structure}
                        fetchData={async (params) => {
                            let data = [...reports.data];

                            if (params['sort-date'] === 'increase') data = data.sort((a: any, b: any) => a.start - b.start);
                            if (params['sort-date'] === 'descrease') data = data.sort((a: any, b: any) => b.start - a.start);

                            if (params['sort-won-volume'] === 'increase') data = data.sort((a: any, b: any) => a.wonVolume - b.wonVolume);
                            if (params['sort-won-volume'] === 'descrease') data = data.sort((a: any, b: any) => b.wonVolume - a.wonVolume);

                            if (params['sort-lost-volume'] === 'increase') data = data.sort((a: any, b: any) => a.loseVolume - b.loseVolume);
                            if (params['sort-lost-volume'] === 'descrease') data = data.sort((a: any, b: any) => b.loseVolume - a.loseVolume);

                            if (params['sort-total-order'] === 'increase') data = data.sort((a: any, b: any) => a.orderCount - b.orderCount);
                            if (params['sort-total-order'] === 'descrease') data = data.sort((a: any, b: any) => b.orderCount - a.orderCount);

                            if (params['sort-login-count'] === 'increase') data = data.sort((a: any, b: any) => a.loginCount - b.loginCount);
                            if (params['sort-login-count'] === 'descrease') data = data.sort((a: any, b: any) => b.loginCount - a.loginCount);

                            if (params['hotWalletBof'] === 'increase') data = data.sort((a: any, b: any) => a.hotWalletBof - b.hotWalletBof);
                            if (params['hotWalletBof'] === 'descrease') data = data.sort((a: any, b: any) => b.hotWalletBof - a.hotWalletBof);

                            if (params['hotWalletEth'] === 'increase') data = data.sort((a: any, b: any) => a.hotWalletEth - b.hotWalletEth);
                            if (params['hotWalletEth'] === 'descrease') data = data.sort((a: any, b: any) => b.hotWalletEth - a.hotWalletEth);

                            if (params['hotWalletUsdp'] === 'increase') data = data.sort((a: any, b: any) => a.hotWalletUsdp - b.hotWalletUsdp);
                            if (params['hotWalletUsdp'] === 'descrease') data = data.sort((a: any, b: any) => b.hotWalletUsdp - a.hotWalletUsdp);

                            if (params['hotWalletUsdt'] === 'increase') data = data.sort((a: any, b: any) => a.hotWalletUsdt - b.hotWalletUsdt);
                            if (params['hotWalletUsdt'] === 'descrease') data = data.sort((a: any, b: any) => b.hotWalletUsdt - a.hotWalletUsdt);

                            if (params['depositBof'] === 'increase') data = data.sort((a: any, b: any) => a.depositBof - b.depositBof);
                            if (params['depositBof'] === 'descrease') data = data.sort((a: any, b: any) => b.depositBof - a.depositBof);

                            if (params['depositEth'] === 'increase') data = data.sort((a: any, b: any) => a.depositEth - b.depositEth);
                            if (params['depositEth'] === 'descrease') data = data.sort((a: any, b: any) => b.depositEth - a.depositEth);

                            if (params['depositUsdp'] === 'increase') data = data.sort((a: any, b: any) => a.depositUsdp - b.depositUsdp);
                            if (params['depositUsdp'] === 'descrease') data = data.sort((a: any, b: any) => b.depositUsdp - a.depositUsdp);

                            if (params['depositUsdt'] === 'increase') data = data.sort((a: any, b: any) => a.depositUsdt - b.depositUsdt);
                            if (params['depositUsdt'] === 'descrease') data = data.sort((a: any, b: any) => b.depositUsdt - a.depositUsdt);

                            if (params['withdrawBof'] === 'increase') data = data.sort((a: any, b: any) => a.withdrawBof - b.withdrawBof);
                            if (params['withdrawBof'] === 'descrease') data = data.sort((a: any, b: any) => b.withdrawBof - a.withdrawBof);

                            if (params['withdrawEth'] === 'increase') data = data.sort((a: any, b: any) => a.withdrawEth - b.withdrawEth);
                            if (params['withdrawEth'] === 'descrease') data = data.sort((a: any, b: any) => b.withdrawEth - a.withdrawEth);

                            if (params['withdrawUsdp'] === 'increase') data = data.sort((a: any, b: any) => a.withdrawUsdp - b.withdrawUsdp);
                            if (params['withdrawUsdp'] === 'descrease') data = data.sort((a: any, b: any) => b.withdrawUsdp - a.withdrawUsdp);

                            if (params['withdrawUsdt'] === 'increase') data = data.sort((a: any, b: any) => a.withdrawUsdt - b.withdrawUsdt);
                            if (params['withdrawUsdt'] === 'descrease') data = data.sort((a: any, b: any) => b.withdrawUsdt - a.withdrawUsdt);

                            if (params['userBalanceBof'] === 'increase') data = data.sort((a: any, b: any) => a.userBalanceBof - b.userBalanceBof);
                            if (params['userBalanceBof'] === 'descrease') data = data.sort((a: any, b: any) => b.userBalanceBof - a.userBalanceBof);

                            if (params['userBalanceEth'] === 'increase') data = data.sort((a: any, b: any) => a.userBalanceEth - b.userBalanceEth);
                            if (params['userBalanceEth'] === 'descrease') data = data.sort((a: any, b: any) => b.userBalanceEth - a.userBalanceEth);

                            if (params['userBalanceUsdp'] === 'increase') data = data.sort((a: any, b: any) => a.userBalanceUsdp - b.userBalanceUsdp);
                            if (params['userBalanceUsdp'] === 'descrease') data = data.sort((a: any, b: any) => b.userBalanceUsdp - a.userBalanceUsdp);

                            if (params['userBalanceUsdt'] === 'increase') data = data.sort((a: any, b: any) => a.userBalanceUsdt - b.userBalanceUsdt);
                            if (params['userBalanceUsdt'] === 'descrease') data = data.sort((a: any, b: any) => b.userBalanceUsdt - a.userBalanceUsdt);

                            if (params['safeBof'] === 'increase') data = data.sort((a: any, b: any) => a.safeBof - b.safeBof);
                            if (params['safeBof'] === 'descrease') data = data.sort((a: any, b: any) => b.safeBof - a.safeBof);

                            if (params['safeEth'] === 'increase') data = data.sort((a: any, b: any) => a.safeEth - b.safeEth);
                            if (params['safeEth'] === 'descrease') data = data.sort((a: any, b: any) => b.safeEth - a.safeEth);

                            if (params['safeUsdp'] === 'increase') data = data.sort((a: any, b: any) => a.safeUsdp - b.safeUsdp);
                            if (params['safeUsdp'] === 'descrease') data = data.sort((a: any, b: any) => b.safeUsdp - a.safeUsdp);

                            if (params['safeUsdt'] === 'increase') data = data.sort((a: any, b: any) => a.safeUsdt - b.safeUsdt);
                            if (params['safeUsdt'] === 'descrease') data = data.sort((a: any, b: any) => b.safeUsdt - a.safeUsdt);


                            return {
                                data: [{ isSum: true }, ...data],
                                count: data.length
                            }
                        }}
                    />
                })()}
            </div>
        </section>
    )
}, () => true))

export const SystemReportDetailPopup: FC<{ detail: any, onClose: () => void }> = (props) => {

    if (!props.detail) return null

    const item = props.detail;

    const data = [
        { label: 'Start', value: `${new Date(item.start).toLocaleDateString(getLocaleKey(), { hour12: false })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), { hour12: false })}` },
        { label: 'End', value: `${new Date(item.end).toLocaleDateString(getLocaleKey(), { hour12: false })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), { hour12: false })}` },
        { label: 'Modified', value: `${new Date(item.modified).toLocaleDateString(getLocaleKey(), { hour12: false })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), { hour12: false })}` },
        { label: 'Total won/lose volume', value: `${(+item.wonVolume).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}/${(+item.lostVolume).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}` },
        {
            label: 'Hot Wallet', value: <span>
                BOF: {(+item.hotWalletBof).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
            ETH: {(+item.hotWalletEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
            USDP: {(+item.hotWalletUsdp).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
            USDT: {(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}
            </span>
        },
        {
            label: 'Deposit', value: <span>
                BOF: {(+item.depositBof).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                ETH: {(+item.depositEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                USDP: {(+item.depositUsdp).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                USDT: {(+item.depositUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}
            </span>
        },
        {
            label: 'Withdraw', value: <span>
                BOF: {(+item.withdrawBof).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                ETH: {(+item.withdrawEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                USDP: {(+item.withdrawUsdp).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                USDT: {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}
            </span>
        },
        {
            label: 'User \'s Balance', value: <span>
                BOF: {(+item.userBalanceBof).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                ETH: {(+item.userBalanceEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                USDP: {(+item.userBalanceUsdp).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                USDT: {(+item.userBalanceUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}
            </span>
        },
        {
            label: 'Safe', value: <span>
                BOF: {(+item.safeBof).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                ETH: {(+item.safeEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                USDP: {(+item.safeUsdp).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })} <br />
                USDT: {(+item.safeUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })}
            </span>
        },
        {
            label: 'Login Count',
            key: 'loginCount',
            value: item.loginCount
        },
        {
            label: 'Direct Commission',
            key: 'directCommission',
            value: item.directCommission
        },
        {
            label: 'CashBack',
            key: 'cashBack',
            value: (+item.cashBack).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 })
        },
        {
            label: 'New Package',
            key: 'newPackage',
            value: item.newPackage
        },
        {
            label: 'Rank Commission',
            key: 'rankCommission',
            value: item.rankCommission
        },
    ]

    return <div
        className="SystemReport__DetailPopup" id="SystemReport__DetailPopup"
        onClick={(e: any) => e.target.id === 'SystemReport__DetailPopup' ? props.onClose() : null}
    >
        <div className="box">
            <div className="boxTitle">
                <span>Detail Report</span>
                <div className="btnClose" onClick={() => props.onClose()}>
                    <Icon.Close />
                </div>
            </div>
            <div className="content">
                <table>
                    <tbody>
                        {data.map((row, key) => (
                            <tr key={key}>
                                <td>{row.label}</td>
                                <td>{row.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}