import { RequestMainService } from "../request";

const endpoint = "/admin/role";
export class RoleService {
    static async get(params: any = {}) {
        return RequestMainService.get(`${endpoint}`, params)
            .then(({ result }) => ({
                count: result.total,
                data: result.ipos,
            }));
    }

    static async getById(id: any) {
        return RequestMainService.get(`${endpoint}/${id}`);
    }

    static async create(params: any) {
        return RequestMainService.post(`${endpoint}`, params);
    }

    static async update(params: any) {
        return RequestMainService.put(`${endpoint}/${params.id}`, params);
    }

    static async delete(id: any) {
        return RequestMainService.delete(`${endpoint}/${id}`);
    }
}
