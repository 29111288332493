import { translate } from "../../languages";

export const ECMSVisiblePage: any = {
    HOME_PAGE: {
        value: "HOME_PAGE",
        label: "Homepage",
    },
    NEWS_PAGE: {
        value: "NEWS_PAGE",
        label: "Tin tức",
    },
};

export const ECMSStatus = () => [
    { value: "true", label: translate("all.active") },
    { value: "false", label: translate("all.deactive") },
];

export const getStatusOption = (type: "status", value: any) => {
    if (!value) return;
    if (type === "status")
        return ECMSStatus().find((t: any) => t.value === value);
};
