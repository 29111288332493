import React, { FC, useEffect, useState } from "react";
import "./index.scss";
import { DateTimeUtils, Button, NumberUtils } from "../../../modules";
import { translate } from "../../../languages";
import { tradingGetOptionLabel } from "../enumTrading";
import { Box } from "@mui/material";
import { OrderChatService } from "../../../services";

export const OrderDetail: FC<{ data: any; onClose: () => void }> = (props) => {
    const {
        code,
        email,
        amount,
        created,
        price,
        status,
        totalPrice,
        type,
        ads,
        ipoFeedback,
        ipoOrderId,
        userId,
        paymentInfo,
    } = props.data;
    const [orderChat, setOrderChat] = useState([]);

    const optionType = tradingGetOptionLabel("type", type);
    const optionStatus = tradingGetOptionLabel("status", status);
    const classNameType = type
        ? type === "SELL"
            ? "successColor"
            : "errorColor"
        : "";
    const bankInfo = ads?.paymentInfos
        ?.map((item: any) => `${item.payment.name}`)
        ?.toString();
    const phoneNumber = ads?.phone
        ? `+${ads?.phone.phoneNumberPrefix} ${ads?.phone.phoneNumber}`
        : "";

    useEffect(() => {
        (async () => {
            const res = await OrderChatService.getListOrderChats(ipoOrderId, {
                isGetAll: true,
            });
            setOrderChat(res.data);
        })();
    }, [ipoOrderId]);

    return (
        <div className="TrangdingDetail">
            <div>
                {translate("trading.code")}: {code}
            </div>
            <div>
                {translate("trading.type")}:{" "}
                <span className={classNameType}>{optionType?.label}</span>
            </div>
            <div>
                {translate("trading.created")}:{" "}
                {DateTimeUtils.formatToString(created)}
            </div>

            <div className="gridContainer">
                <div>
                    <h3 className="mt20 mb20">{translate("trading.info")}</h3>

                    <div>IPO: {ads?.ipo?.name}</div>
                    <div>
                        {translate("all.price")}:{" "}
                        {NumberUtils.toCurrency(price, "")} {ads?.fiat?.name}
                    </div>
                    <div>
                        {translate("all.amount")}:{" "}
                        {NumberUtils.toCurrency(amount, "")} {ads?.ipo?.symbol}
                    </div>
                    <div>
                        {translate("trading.totalAmount")}:{" "}
                        {NumberUtils.toCurrency(totalPrice, "")}{" "}
                        {ads?.fiat?.symbol}
                    </div>
                    <div>
                        {translate("all.status")}: {optionStatus?.label}
                    </div>
                </div>

                <div>
                    <h3 className="mt20 mb20">
                        {translate("trading.partnerInfo")}
                    </h3>
                    <div>
                        {translate("user.phone")}: {phoneNumber}
                    </div>
                    <div>Email: {ads.user.email}</div>
                    <div>
                        {translate("trading.accountBank")}:{" "}
                        {paymentInfo?.payment?.name}
                    </div>
                    {!!paymentInfo?.paymentInfoValues && (
                        <div style={{ marginLeft: "1rem" }}>
                            {paymentInfo?.paymentInfoValues?.map(
                                (info: {
                                    fieldName: string;
                                    value: string;
                                }) => {
                                    return (
                                        <p>
                                            {info.fieldName}: {info.value}
                                        </p>
                                    );
                                }
                            )}
                        </div>
                    )}
                </div>
            </div>

            <hr className="mb20 mt20" />

            <h3 className="mb20">{translate("trading.feedback")}</h3>

            {ipoFeedback ? (
                <div>
                    <div>{ipoFeedback?.fullName}</div>
                    <div>
                        {DateTimeUtils.formatToShow(
                            DateTimeUtils.timeToSeconds(ipoFeedback?.created)
                        )}
                    </div>

                    <div style={{ display: "inline-flex" }}>
                        <Box sx={{ color: "success.main" }} fontSize={25}>
                            <i className="fa-regular fa-thumbs-up"></i>
                        </Box>
                        <div className="feedback ml20">
                            {ipoFeedback?.feedbackBody}
                        </div>
                    </div>
                </div>
            ) : (
                <div>{translate("trading.noFeedback")}</div>
            )}

            <div
                style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    overflow: "auto",
                    background: "white",
                    padding: "1rem",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    borderRadius: "1rem",
                }}
            >
                {orderChat.map((item: any) => {
                    const isRespond = item.userId === userId;
                    const isAdmin = item.userId === null;

                    return (
                        <div
                            style={{
                                marginTop: "1rem",
                            }}
                        >
                            <p
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                {DateTimeUtils.formatToString(item.created)}
                            </p>
                            {isAdmin ? (
                                <p
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    {item.message}
                                </p>
                            ) : (
                                <>
                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            textAlign: isRespond
                                                ? "right"
                                                : "left",
                                        }}
                                    >
                                        {item.fullName}
                                    </p>
                                    <p
                                        style={{
                                            textAlign: isRespond
                                                ? "right"
                                                : "left",
                                        }}
                                    >
                                        {item.message}
                                    </p>
                                </>
                            )}
                        </div>
                    );
                    // if (isAdmin) {
                    //     return (
                    //         <div
                    //             style={{
                    //                 background: "rgb(254, 252, 232)",
                    //                 marginTop: "1rem",
                    //                 textAlign: "center",
                    //             }}
                    //         >
                    //             <p>
                    //                 {DateTimeUtils.formatToString(item.created)}
                    //             </p>
                    //             <p className="mt2">{item.message}</p>
                    //         </div>
                    //     );
                    // }
                    // if (isRespond) {
                    //     return (
                    //         <div
                    //             style={{
                    //                 textAlign: "right",
                    //                 background: "white",
                    //                 padding: "1rem",
                    //                 marginTop: "1rem",
                    //             }}
                    //         >
                    //             {item.message}
                    //         </div>
                    //     );
                    // }
                    // return (
                    //     <div
                    //         style={{
                    //             background: "white",
                    //             padding: "1rem",
                    //             marginTop: "1rem",
                    //         }}
                    //     >
                    //         <p
                    //             style={{
                    //                 textAlign: "center",
                    //             }}
                    //         >
                    //             {DateTimeUtils.formatToString(item.created)}
                    //         </p>
                    //         <p style={{ fontWeight: "bold" }}>
                    //             {item.fullName}
                    //         </p>
                    //         <p>{item.message}</p>
                    //     </div>
                    // );
                })}
            </div>

            <div className="btnGroup">
                <Button
                    label={translate("all.close")}
                    buttonType="danger"
                    type="submit"
                    onClick={props.onClose}
                />
            </div>
        </div>
    );
};
