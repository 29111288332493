import React, { FC } from "react";
import "./index.scss";
import {
    Button,
    useForm,
    InputWraper,
    CreateAlert,
    Regex,
} from "../../../../modules";
import { translate } from "../../../../languages";
import * as Yup from "yup";
import { InputPassword } from "../../..";
import { AdminService } from "../../../../services/admin";

export const UpdatePasswordUser: FC<{ data: any; onFinishEdit: () => void }> = (
    props
) => {
    const { oldPassword, newPassword } = props.data;
    const { handleSubmit, getInputProps, isSubmitting } = useForm({
        structure: [
            {
                name: "oldPassword",
                defaultValue: oldPassword,
                validate: Yup.string().required(
                    translate("validate.mustProvided")
                ),
            },
            {
                name: "newPassword",
                defaultValue: newPassword,
                validate: Yup.string()
                    .required(translate("validate.mustProvided"))
                    .matches(
                        Regex.password,
                        translate("validate.passwordRegex")
                    ),
            },
        ],
        onSubmit: async (values) => {
            return AdminService.changePassword({
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
            })
                .then((rs) => {
                    if (rs && rs.success) {
                        CreateAlert({
                            message: translate("all.updateSuccess"),
                            type: "success",
                        });
                        props.onFinishEdit();
                    } else {
                        CreateAlert({
                            message: rs.message,
                            type: "danger",
                        });
                    }
                })
                .catch((error) => {
                    CreateAlert({
                        message: error.message,
                        type: "danger",
                    });
                });
        },
    });

    return (
        <form className="Edit" onSubmit={handleSubmit}>
            <InputWraper
                label={translate("user.oldPassword")}
                inputProps={getInputProps("oldPassword")}
                component={InputPassword}
            />
            <InputWraper
                label={translate("user.newPassword")}
                inputProps={getInputProps("newPassword")}
                component={InputPassword}
            />

            <div>
                <Button
                    isLoading={isSubmitting}
                    label={translate("all.update")}
                    isMiddle
                    className="btnBan"
                    type="submit"
                />
            </div>
        </form>
    );
};
