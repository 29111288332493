import { translate } from "../../languages";

export const enumUser = {
    optionsKycStatus: () => [
        { value: "", label: translate("all.all") },
        { value: "NOTYET", label: translate("user.notyetKyc") },
        { value: "PENDING", label: translate("user.pendingKyc") },
        { value: "APPROVED", label: translate("user.approvedKyc") },
        { value: "REJECTED", label: translate("user.rejectedKyc") },
    ],
    optionsActive: () => [
        { value: "true", label: translate("all.active") },
        { value: "false", label: translate("all.deactive") },
    ],
    documentKycType: {
        IDENTITY_CARD: "IDENTITY_CARD",
        DRIVER_LICENSE: "DRIVER_LICENSE",
        PASSPORT: "PASSPORT",
    },
};

export const userGetOptionEnum = (type: "kycStatus" | "active", value: any) => {
    if (type === "kycStatus")
        return enumUser.optionsKycStatus().find((t) => t.value === value);
    else if (type === "active")
        return enumUser.optionsActive().find((t) => t.value === value);
};
