import { RequestMainService } from "../request";

export class CmsService {
    static async getCmsList(params: any) {
        return RequestMainService.get(`/cms`, params).then(({ result }) => ({
            data: result.list,
            count: result.total,
        }));
    }

    static async getCms(id: any) {
        return RequestMainService.get(`/cms/${id}`);
    }

    static async createCms(params: any) {
        return RequestMainService.post(`/cms`, params);
    }

    static async updateCms(params: any) {
        return RequestMainService.put(`/cms/${params.cmsId}`, params);
    }

    static async deleteCms(id: any) {
        return RequestMainService.delete(`/cms/${id}`);
    }
}
