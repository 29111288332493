import React, { FC } from "react";
import {
    BrowserRouter as Router,
    Routes as Switch,
    Route,
} from "react-router-dom";

import "./main.scss";
import "react-calendar/dist/Calendar.css";
import "react-datetime-picker/dist/DateTimePicker.css";

import { PageAuthLogin } from "./pages/auth";
import { WithPageWraper } from "./pages/wraper";
import { PageDashboard } from "./pages/dashboard";
import { UserList } from "./pages/user";
import { TransactionList } from "./pages/transaction/transaction/TransactionList";
import { PageKYC } from "./pages/user/kyc";
import { UserDetail } from "./pages/user/user/UserDetail";
import { WithdrawProgress } from "./pages/transaction/withdraw/WithdrawProgress";
import { PageUserKYCList } from "./pages/user/kyc/KYCList";
import { PageUserAffiliation } from "./pages/user/affiliation";
import { ReportSystem } from "./pages/report/system";
import { TransactionDemoList } from "./pages/transaction/transaction/TransactionDemoList";
import { PageSettingNoti } from "./pages/setting/noti";
import { PageSetting } from "./pages/setting/setting";
import { IpoList } from "./pages/ipo";
import { AdsList } from "./pages/ads";
import { PaymentList } from "./pages/payment";
import { PageTradingTable } from "./pages/trading/table";
import { FeedbackList } from "./pages/feedback";
import { Permission, RoleList } from "./pages/role";
import { CmsList } from "./pages/cms";
import { CreateEditCms } from "./pages/cms/cms-list/CreateEdit";
import { FaqList } from "./pages/faq";
import { FaqCategoryList } from "./pages/faq-category";
import { CreateEditFaqCategory } from "./pages/faq-category/faq-category/CreateEdit";
import { CreateEditFaq } from "./pages/faq/faq/CreateEdit";

export const Routes = {
    dashboard: {
        path: "/",
        component: PageDashboard,
    },
    userList: {
        path: "/users",
        component: UserList,
    },
    userKYCList: {
        path: "/users/kycs",
        component: PageUserKYCList,
    },
    userAffiliation: {
        path: "/users/affiliation",
        component: PageUserAffiliation,
    },
    tradingTable: {
        path: "/trading",
        component: PageTradingTable,
    },
    userDetail: {
        path: "/users/:email",
        component: UserDetail,
        renderPath: (email: string) => `/users/${email}`,
    },
    transactionList: {
        path: "/transactions",
        component: TransactionList,
    },
    transactionDemoList: {
        path: "/transactions/demo",
        component: TransactionDemoList,
    },
    withdraw: {
        path: "/transactions/withdraws",
        component: WithdrawProgress,
    },
    kyc: {
        path: "/kyc",
        component: PageKYC,
    },
    reportSystem: {
        path: "/reports/system",
        component: ReportSystem,
    },
    setting: {
        path: "/setting",
        component: PageSetting,
    },
    settingNoti: {
        path: "/setting/notify",
        component: PageSettingNoti,
    },
    ipoList: {
        path: "/ipos",
        component: IpoList,
    },
    feedbackList: {
        path: "/feedbacks",
        component: FeedbackList,
    },
    adsList: {
        path: "/ads",
        component: AdsList,
    },
    paymentList: {
        path: "/payment",
        component: PaymentList,
    },
    roleList: {
        path: "/roles",
        component: RoleList,
    },
    permission: {
        path: "/roles/permissions",
        component: Permission,
    },
    cmsList: {
        path: "/cms",
        component: CmsList,
    },
    newCms: {
        path: "/cms/new",
        component: CreateEditCms,
    },
    faqList: {
        path: "/faq",
        component: FaqList,
    },
    newFaq: {
        path: "/faq/new",
        component: CreateEditFaq,
    },
    faqCategory: {
        path: "/faq-category",
        component: FaqCategoryList,
    },
    newFaqCategory: {
        path: "/faq-category/new",
        component: CreateEditFaqCategory,
    },
};

export const AppRoutes: FC = () => {
    return (
        <Router>
            <Switch>
                {Object.values(Routes).map((item: any, key) => {
                    return (
                        <Route
                            key={key}
                            path={item.path}
                            element={
                                <WithPageWraper>
                                    <item.component />
                                </WithPageWraper>
                            }
                        />
                    );
                })}

                <Route path="/auth/login" element={<PageAuthLogin />} />
            </Switch>
        </Router>
    );
};
