import React, { useState, memo, useEffect } from "react";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./index.scss";
import { Button, useForm, InputWraper, CreateAlert } from "../../../../modules";
import { translate } from "../../../../languages";
import * as Yup from "yup";
import {
    FaqService,
    FAQCategoryService,
    FileService,
} from "../../../../services";
import { withFaqCategoryWraper } from "../../wraper";
import { InputText } from "./input-text";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { useNavigate } from "react-router-dom";

import ImageUploader from "react-images-upload";
import {
    InputTextArea,
    InputSelect,
    InputCheckbox,
} from "../../../../components";
import {
    EFAQCustomerTypeList,
    EFAQFeatureTypeList,
    EFAQStatus,
} from "../../enumFAQCategory";

export const CreateEditFaqCategory = withFaqCategoryWraper(
    memo((props: any) => {
        const {
            name,
            iconFileId,
            customerType,
            featureType,
            status,
            parentId,
            iconUrl,
            isCreate = true,
            faqCategoryId,
        } = props.data || {};
        const navigate = useNavigate();

        const [valueCategory, setValueCategory] = useState(null);
        const [valueTitle, setValueTitle] = useState(null);
        const [valueDescription, setValueDescription] = useState(null);
        const [valueContent, setValueContent] = useState(null);
        const [valueCheckbox, setValueCheckbox] = useState(
            status === EFAQStatus.ACTIVE.value
        );

        const { handleSubmit, getInputProps, isSubmitting, setValues } =
            useForm({
                structure: [
                    {
                        name: "customerType",
                        defaultValue: customerType,
                        validate: Yup.string().required(
                            translate("validate.mustProvided")
                        ),
                    },
                    {
                        name: "featureType",
                        defaultValue: featureType,
                        validate: Yup.string().required(
                            translate("validate.mustProvided")
                        ),
                    },
                    {
                        name: "name",
                        defaultValue: name,
                        validate: Yup.string().required(
                            translate("validate.mustProvided")
                        ),
                    },
                ],
                onSubmit: async (values) => {
                    let res = null;
                    if (isCreate) {
                        return FAQCategoryService.createFaqCategory({
                            name: values.name,
                            customerType: values.customerType,
                            featureType: values.featureType,
                            status: valueCheckbox
                                ? EFAQStatus.ACTIVE.value
                                : EFAQStatus.INACTIVE.value,
                        })
                            .then((result) => {
                                CreateAlert({
                                    message: translate("all.createSuccess"),
                                    type: "success",
                                });
                                navigate("/faq-category/");
                            })
                            .catch((error) => {
                                CreateAlert({
                                    message: error.message,
                                    type: "danger",
                                });
                            });
                    }
                    return FAQCategoryService.updateFaqCategory({
                        name: values.name,
                        customerType: values.customerType,
                        featureType: values.featureType,
                        status: valueCheckbox
                            ? EFAQStatus.ACTIVE.value
                            : EFAQStatus.INACTIVE.value,
                        faqCategoryId: faqCategoryId,
                    })
                        .then(() => {
                            CreateAlert({
                                message: translate("all.updateSuccess"),
                                type: "success",
                            });
                            props.onFinishEdit();
                        })
                        .catch((error) => {
                            CreateAlert({
                                message: error.message,
                                type: "danger",
                            });
                        });
                },
            });

        const handleChange = (name: any, value: any) => {
            setValues({ [name]: value });
        };

        return (
            <form className="Edit" onSubmit={handleSubmit}>
                <div className="group">
                    <InputCheckbox
                        label={translate("all.active")}
                        onChange={(value) => setValueCheckbox(value)}
                        name="active"
                        value={valueCheckbox}
                        onTouched={() => false}
                    />
                </div>
                <InputWraper
                    label={"Đối tượng *"}
                    inputProps={getInputProps("customerType")}
                    className="mr5 inputTextRight"
                    renderInput={(inputProps) => {
                        return (
                            <InputSelect
                                {...inputProps}
                                options={EFAQCustomerTypeList}
                                onChange={(value) =>
                                    handleChange("customerType", value)
                                }
                                onTouched={() => false}
                                name="customerType"
                            />
                        );
                    }}
                />
                <InputWraper
                    label={"Loại tính năng *"}
                    inputProps={getInputProps("featureType")}
                    className="mr5 inputTextRight"
                    renderInput={(inputProps) => {
                        return (
                            <InputSelect
                                {...inputProps}
                                options={EFAQFeatureTypeList}
                                onChange={(value) =>
                                    handleChange("featureType", value)
                                }
                                onTouched={() => false}
                                name="featureType"
                            />
                        );
                    }}
                />
                <div className="label">{"Tiêu đề loại câu hỏi *"}</div>
                <InputWraper
                    className="title"
                    inputProps={getInputProps("name")}
                    component={InputText}
                />

                <div className="groupButton">
                    <Button
                        label={translate("all.cancel")}
                        buttonType="danger"
                        onClick={
                            isCreate
                                ? () => navigate("/faq-category/")
                                : props.onClose
                        }
                    />
                    <Button
                        isLoading={isSubmitting}
                        label={
                            isCreate
                                ? translate("all.create")
                                : translate("all.update")
                        }
                        className="btnBan ml5"
                        type="submit"
                    />
                </div>
            </form>
        );
    })
);
