import React, { FC } from "react";

// import { ControlTradeResults } from "../../components/control-trade-results";
// import { InterventionLimit } from "../../components/intervention-limit";
import { OpennedOrders } from "../../components/opened-orders";
// import { TradePannel } from "./trade-pannel";

export const PageDashboard: FC = () => {
    return (
        <div className="PageDashboard">
            <OpennedOrders />
        </div>
    );
};
