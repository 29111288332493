import React, { FC, useEffect, useMemo, useState } from "react";
import "./index.scss";
import {
    Button,
    useForm,
    InputWraper,
    CreateAlert,
    Regex,
} from "../../../../modules";
import { translate } from "../../../../languages";
import * as Yup from "yup";
import { InputPassword, InputSelect, InputText } from "../../../../components";
import { AdminService } from "../../../../services/admin";
import { RoleService } from "../../../../services/role";
import { enumUser } from "../../enumUser";
import { useSelector } from "../../../../store";

export const CreateEditUser: FC<{ data: any; onFinishEdit: () => void }> = (
    props
) => {
    const {
        isActived,
        roleId,
        email,
        phoneNumber,
        fullName,
        lastName,
        firstName,
        isCreate,
        accountType,
        birthday,
        userId,
        password,
        countryId,
        paymentName,
    } = props.data;
    const countries = useSelector((state) => state.countries);
    const optionCountries = useMemo(
        () =>
            countries?.data.map((item: any) => ({
                label: "+ " + item.phoneNumberPrefix,
                value: item.countryId,
            })),
        []
    );

    const optionsAccountType = AdminService.getListUserType();
    const optionsActived = useMemo(() => enumUser.optionsActive(), []);
    const [optionsRole, setOptionRoles] = useState([]);

    const { handleSubmit, getInputProps, isSubmitting, values, setValues } =
        useForm({
            structure: [
                {
                    name: "email",
                    defaultValue: email,
                    validate: Yup.string()
                        .required(translate("user.emailRequired"))
                        .matches(Regex.email, translate("validate.emailRegex")),
                    isDisabled: isCreate ? false : true,
                },
                {
                    name: "fullName",
                    defaultValue: fullName,
                },
                {
                    name: "firstName",
                    defaultValue: firstName,
                    validate: Yup.string()
                        .nullable(true)
                        .required(translate("user.firstRequired")),
                },
                {
                    name: "lastName",
                    defaultValue: lastName,
                    validate: Yup.string()
                        .nullable(true)
                        .required(translate("user.lastNameRequired")),
                },
                {
                    name: "password",
                    defaultValue: password,
                    validate: Yup.string().matches(
                        Regex.password,
                        translate("validate.passwordRegex")
                    ),
                },
                {
                    name: "repassword",
                    defaultValue: password,
                    validate: Yup.string()
                        .oneOf(
                            [Yup.ref("password")],
                            translate("user.repasswordRequired")
                        )
                        .matches(
                            Regex.password,
                            translate("validate.passwordRegex")
                        ),
                },
                {
                    name: "phoneNumber",
                    defaultValue: phoneNumber || "",
                    validate: isCreate
                        ? Yup.string()
                              .max(9, "Số điện thoại tối đa 9 số")
                              .required(translate("user.phoneRequired"))
                        : undefined,
                    isDisabled: isCreate ? false : true,
                },
                {
                    name: "paymentName",
                    defaultValue: paymentName || "",
                    isDisabled: true,
                },
                {
                    name: "countryId",
                    defaultValue: countryId,
                },
                {
                    name: "accountType",
                    defaultValue: accountType || "",
                },
                {
                    name: "roleId",
                    defaultValue: roleId,
                    validate: Yup.string()
                        .nullable(true)
                        .required(translate("user.roleRequired")),
                },
                {
                    name: "isActived",
                    defaultValue: `${isActived}`,
                    validate: Yup.string()
                        .nullable(true)
                        .required(translate("validate.mustProvided")),
                },
            ],
            onSubmit: async (items) => {
                const sFullName = `${items.lastName} ${items.firstName}`;

                if (isCreate) {
                    return AdminService.createUser({
                        ...items,
                        fullName: sFullName,
                        phoneNumber: items.phoneNumber,
                        countryId: 232,
                        languageId: 1,
                    })
                        .then((res) => {
                            if (res.success) {
                                CreateAlert({
                                    message: translate("all.createSuccess"),
                                    type: "success",
                                });
                                props.onFinishEdit();
                            } else {
                                CreateAlert({
                                    message: res.message,
                                    type: "danger",
                                });
                            }
                        })
                        .catch((error) => {
                            CreateAlert({
                                message: error.message,
                                type: "danger",
                            });
                        });
                }

                return AdminService.updateUser({
                    ...items,
                    id: userId,
                    fullName: sFullName,
                })
                    .then((res) => {
                        if (res.success) {
                            CreateAlert({
                                message: translate("all.updateSuccess"),
                                type: "success",
                            });
                            props.onFinishEdit();
                        } else {
                            CreateAlert({
                                message: res.message,
                                type: "danger",
                            });
                        }
                    })
                    .catch((error) => {
                        CreateAlert({
                            message: error.message,
                            type: "danger",
                        });
                    });
            },
        });

    useEffect(() => {
        RoleService.get({
            page: 1,
            numberOfRolePerPage: 1,
            isGetAll: true,
            roleStatus: "ACTIVE",
        }).then((res) => {
            if (res.data) {
                setOptionRoles(
                    res.data.map((item: any) => ({
                        label: item.roleName,
                        value: item.roleId,
                    }))
                );
            }
        });
    }, []);

    return (
        <form className="EditUser" onSubmit={handleSubmit}>
            <InputWraper
                label={"Email"}
                inputProps={getInputProps("email")}
                component={InputText}
            />

            <div className="group">
                <InputWraper
                    label={translate("all.lastName")}
                    inputProps={getInputProps("lastName")}
                    component={InputText}
                    className="mr5"
                />

                <InputWraper
                    label={translate("all.name")}
                    inputProps={getInputProps("firstName")}
                    component={InputText}
                    className="ml5"
                />
            </div>

            {isCreate && (
                <>
                    <div className="group">
                        <InputWraper
                            label={translate("user.password")}
                            inputProps={getInputProps("password")}
                            component={InputPassword}
                        />
                    </div>
                    <div className="group">
                        <InputWraper
                            label={translate("user.repassword")}
                            inputProps={getInputProps("repassword")}
                            component={InputPassword}
                        />
                    </div>
                </>
            )}
            <div className="group">
                <InputWraper
                    label={translate("user.phonePrefix")}
                    inputProps={getInputProps("countryId")}
                    className="mr5"
                    renderInput={(inputProps) => (
                        <InputSelect
                            {...inputProps}
                            options={optionCountries}
                            onChange={(value) =>
                                setValues({ countryId: value })
                            }
                            onTouched={() => false}
                            value={values.countryId}
                            name="countryId"
                            isClearable={false}
                            isDisabled={isCreate ? false : true}
                        />
                    )}
                />

                <InputWraper
                    label={translate("user.phone")}
                    inputProps={getInputProps("phoneNumber")}
                    component={InputText}
                    className="ml5 widthPhone"
                />
            </div>

            <div className="group">
                <InputWraper
                    label={"Role"}
                    inputProps={getInputProps("roleId")}
                    renderInput={(inputProps) => (
                        <InputSelect
                            {...inputProps}
                            options={optionsRole}
                            onChange={(value) => setValues({ roleId: value })}
                            onTouched={() => false}
                            value={values.roleId}
                            name="roleId"
                        />
                    )}
                />

                {!!paymentName && (
                    <InputWraper
                        label={translate("all.payment")}
                        inputProps={getInputProps("paymentName")}
                        component={InputText}
                        className="ml5 widthPhone"
                    />
                )}
            </div>

            <div className="group">
                <InputWraper
                    label={translate("user.type")}
                    inputProps={{ ...getInputProps("accountType") }}
                    className="mr5"
                    renderInput={(inputProps) => (
                        <InputSelect
                            {...inputProps}
                            options={optionsAccountType}
                            onChange={(value) =>
                                setValues({ accountType: value })
                            }
                            onTouched={() => false}
                            value={values.accountType}
                            name="accountType"
                            isClearable={false}
                        />
                    )}
                />

                <InputWraper
                    label={translate("user.status")}
                    inputProps={getInputProps("isActived")}
                    className="ml5"
                    renderInput={(inputProps) => (
                        <InputSelect
                            {...inputProps}
                            options={optionsActived}
                            onChange={(value) =>
                                setValues({ isActived: value })
                            }
                            onTouched={() => false}
                            value={values.isActived}
                            name="isActived"
                            isClearable={false}
                        />
                    )}
                />
            </div>

            <div>
                <Button
                    // className="mb20"
                    isLoading={isSubmitting}
                    label={
                        isCreate
                            ? translate("all.create")
                            : translate("all.update")
                    }
                    // onClick={props.onClick}
                    isMiddle
                    // buttonType="warning"
                    className="btnBan"
                    type="submit"
                />
            </div>
        </form>
    );
};
