import React, { useState, memo, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import XLSX from "xlsx";
import {
    Table,
    ITableStructureItem,
    CreateAlert,
    Button,
    Icon,
    InputWraper,
    DateTimeUtils,
} from "../../../modules";
import "./CmsList.scss";
import { CmsService, CategoryService } from "../../../services";
import { withCmsWraper } from "../wraper";
import { translate } from "../../../languages";
import { PopupWraper } from "../../../components/popup";
import { InputSelect, InputText } from "../../../components";
import { Ban } from "./ban";
import { CreateEditCms as CreateEdit } from "./CreateEdit";
import { ECMSStatus, ECMSVisiblePage, getStatusOption } from "../enumCMS";

export const CmsList = withCmsWraper(
    memo(() => {
        const navigate = useNavigate();
        const [params, setParams] = useState([] as any);
        const [editData, setEditData] = useState<any>(null);
        const [freezeDataId, setFreezeDataId] = useState<number | null>(null);
        const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
        const [titleValue, setTitleValue] = useState<any>(null);
        const [statusValue, setStatusValue] = useState<any>(null);
        const [optionCategory, setOptionCategory] = useState([]);
        const [enableReinitialize, setEnableReinitialize] =
            useState<boolean>(false);
        const [datas, setDatas] = useState(null as any);

        const getCategoryList = async () => {
            const res = await CategoryService.getCategoryList();
            if (res.data) {
                setOptionCategory(
                    res.data.map((item: any) => {
                        return {
                            label: item.categoryName,
                            value: item.categoryId,
                        };
                    })
                );
            }
        };

        useEffect(() => {
            setEnableReinitialize(false);
        }, [datas]);

        useEffect(() => {
            getCategoryList();
        }, []);

        useEffect(() => {
            if (params && params.limit && params.pageNumber) {
                CmsService.getCmsList({
                    page: params.pageNumber,
                    numberOfItemsPerPage: params.limit,
                    title: titleValue || "",
                    isActived: statusValue ? JSON.parse(statusValue) : "",
                })
                    .then((res) => {
                        if (params.pageNumber === 1) {
                            setEnableReinitialize(true);
                        }
                        setDatas({
                            count: res.count,
                            data: res.data,
                        });
                    })
                    .catch((err) => setDatas({ error: err.error }));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [forceUpdateTable, params.limit, params.pageNumber]);

        const getDetailCms = async (item: any) => {
            const res = await CmsService.getCms(item.cmsId);

            setEditData({
                ...res.result,
                isCreate: false,
            });
        };
        const structure: ITableStructureItem[] = [
            {
                name: "Tiêu đề",
                key: "title",
            },
            {
                name: "Trang hiển thị",
                key: "visibleOnPages",
                render: (item) => {
                    return (
                        <div>
                            {item.visibleOnPages?.map((visiblePage: any) => {
                                return (
                                    <p>{ECMSVisiblePage[visiblePage].label}</p>
                                );
                            })}
                        </div>
                    );
                },
            },
            {
                name: translate("all.status"),
                key: "isActived",
                render: (item) =>
                    getStatusOption("status", item.isActived?.toString())
                        ?.label,
            },
            {
                name: "Nổi bật",
                key: "isActived",
                render: (item) =>
                    item.isHighlight && (
                        <i
                            className="fa-solid fa-bolt-lightning"
                            style={{ color: "#bfbf00" }}
                        ></i>
                    ),
            },

            {
                name: `${translate("ads.modifiedTime")} / ${translate(
                    "ads.createdTime"
                )}`,
                key: "created",
                render: (item) => {
                    const createdSeconds = DateTimeUtils.timeToSeconds(
                        item.created
                    );
                    const ModifiedSeconds = DateTimeUtils.timeToSeconds(
                        item.modified
                    );
                    return (
                        <>
                            <div>
                                {DateTimeUtils.formatToShow(ModifiedSeconds)}
                            </div>
                            <div>
                                {DateTimeUtils.formatToShow(createdSeconds)}
                            </div>
                        </>
                    );
                },
            },
            {
                name: translate("all.actions"),
                key: "actions",
                render: (item) => {
                    return (
                        <div className="btnGroup">
                            <span
                                className="btn btn__edit"
                                onClick={() => getDetailCms(item)}
                            >
                                <Icon.Edit />
                            </span>
                            <span
                                className="btn btn__ban"
                                onClick={() => setFreezeDataId(item.cmsId)}
                            >
                                <Icon.Trash />
                            </span>
                        </div>
                    );
                },
            },
        ];

        const handleExportExcel = async () => {
            return new Promise(async (resolve) => {
                try {
                    const response = await CmsService.getCmsList({
                        page: params.pageNumber,
                        numberOfItemsPerPage: 1000,
                        title: titleValue || "",
                        isActived: statusValue ? JSON.parse(statusValue) : "",
                    });

                    const data = response.data;

                    const fileHead = structure.map((v) => v.name);
                    const dataExport = [
                        fileHead,
                        ...data.map((item: any) =>
                            structure.map((column, index) => {
                                if (!column.key) return "";
                                return item[column.key];
                            })
                        ),
                    ];

                    const ws = XLSX.utils.aoa_to_sheet(dataExport);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                    const now = new Date();
                    XLSX.writeFile(
                        wb,
                        `Cms List ${now
                            .toLocaleDateString()
                            .replace(/\//g, "-")} ${now
                            .toLocaleTimeString()
                            .replace(/:/g, "-")}.xlsx`,
                        { type: "binary" }
                    );

                    resolve(
                        CreateAlert({
                            type: "success",
                            message: "Export data success.",
                        })
                    );
                } catch (error: any) {
                    resolve(
                        CreateAlert({ type: "danger", message: error.message })
                    );
                }
            });
        };

        const tableMemo = useMemo(() => {
            return (
                <Table
                    structure={structure}
                    fetchData={async (params) => {
                        setParams(params);
                        return CmsService.getCmsList({
                            page: params.pageNumber,
                            numberOfItemsPerPage: params.limit,
                            title: titleValue || "",
                            isActived: statusValue
                                ? JSON.parse(statusValue)
                                : "",
                        });
                    }}
                />
            );
        }, [forceUpdateTable]);

        return (
            <div className="CmsList">
                <Button
                    className="mb20 mr20"
                    label={translate("all.exportExcel")}
                    buttonType="success"
                    onClick={handleExportExcel}
                />
                <Button
                    className="mb20"
                    label={translate("cms.create")}
                    buttonType="success"
                    onClick={() => navigate("/cms/new")}
                />

                <div className="group mb20">
                    <div className="itemSelect wfull mr5">
                        <div className="label">Trạng thái</div>
                        <InputSelect
                            options={ECMSStatus()}
                            onChange={(e) => setStatusValue(e)}
                            onTouched={() => false}
                            value={statusValue}
                            name="isActived"
                        />
                    </div>
                    <div className="itemSelect wfull mr5">
                        <div className="label">Tìm kiếm</div>
                        <InputWraper
                            inputProps={{
                                name: "titleValue",
                                onChange: (e) => {
                                    setTitleValue(e);
                                },
                                onTouched: () => false,
                                value: titleValue,
                            }}
                            renderInput={InputText}
                            placeholder="Tìm kiếm theo tiêu đề"
                        />
                    </div>
                    <div>
                        <div className="btnGroup">
                            <Button
                                label={translate("all.search")}
                                onClick={() => setForceUpdateTable(Math.random)}
                            />
                            <span
                                className="btn btn__edit"
                                onClick={() => {
                                    setTitleValue("");
                                    setStatusValue(null);
                                }}
                            >
                                <Icon.Remove />
                            </span>
                        </div>
                    </div>
                </div>

                {tableMemo}
                {editData && (
                    <PopupWraper
                        center
                        title={
                            editData.isCreate
                                ? "Thêm mới tin tức"
                                : "Cập nhật tin tức"
                        }
                        onClose={() => setEditData(null)}
                    >
                        <CreateEdit
                            onClose={() => setEditData(null)}
                            data={editData}
                            onFinishEdit={() => {
                                setForceUpdateTable(Math.random);
                                setEditData(null);
                            }}
                        />
                    </PopupWraper>
                )}
                {freezeDataId && (
                    <PopupWraper
                        center
                        title={translate("cms.delete")}
                        onClose={() => setFreezeDataId(null)}
                    >
                        <Ban
                            userId={freezeDataId}
                            onFinishEdit={() => {
                                setForceUpdateTable(Math.random);
                                setFreezeDataId(null);
                            }}
                        />
                    </PopupWraper>
                )}
            </div>
        );
    })
);
