import React, { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { translate } from "../../languages";

export const withFaqCategoryWraper = (Component: FC) => (props: any) => {
    const location = useLocation();
    const checkActive = (route: any) => {
        if (!location) return "";
        const { pathname } = location;
        return pathname === route ? "active" : "";
    };

    return (
        <div className="withFaqWraper">
            <Component {...props} />
        </div>
    );
};
