import React from "react";
import { Button } from "../../../modules";
import { translate } from "../../../languages";
import { userGetOptionEnum } from "../enumUser";

interface IUserInfo {
    data: any;
    onClose: () => void;
}

export const UserInfo = (props: IUserInfo) => {
    const { data = {}, onClose } = props;
    const kycStatusName = userGetOptionEnum("kycStatus", data.kycStatus)?.label;
    const statusName = userGetOptionEnum(
        "active",
        data.isActived.toString()
    )?.label;

    return (
        <div className="UserInfo">
            <div className="gridContainer">
                <div>
                    <h3>{translate("user.accountInfo")}</h3>
                    <div>User ID: {data.userId}</div>
                    <div>Email: {data.email}</div>
                    <div>
                        {translate("user.phone")}: {data.phoneNumber}
                    </div>
                    <div>
                        {translate("all.status")}: {statusName}
                    </div>
                    <div>KYC: {kycStatusName}</div>
                </div>

                <div>
                    <h3>{translate("user.info")}</h3>
                    <div>
                        {translate("user.fullname")}: {data.fullName}
                    </div>
                    <div>
                        {translate("user.phone")}: {data.phoneNumber}
                    </div>
                    <div>Role: {data.roleName}</div>
                    <div>{translate("all.payment")}:</div>
                    <div>{data.paymentName}</div>
                </div>
            </div>

            <div className="groupButton mt10">
                <Button
                    label={translate("all.close")}
                    buttonType="danger"
                    type="submit"
                    onClick={onClose}
                />
            </div>
        </div>
    );
};
