import { RequestMainService } from "../request";

export class CategoryService {

    static async getCategoryList() {
        return RequestMainService.get(`/category`).then(
            ({ result }) => ({
                data: result,
                count: result.length
            })
        );
    }
}
