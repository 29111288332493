import React, { useState, memo, useMemo } from "react";
import XLSX from "xlsx";

import {
    TableScroll,
    Table,
    ITableStructureItem,
    CreateAlert,
    Button,
    Icon,
    InputWraper,
} from "../../../modules";
import { AdminService } from "../../../services/admin";
import { Link } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import { withIpoWraper } from "../wraper";
import { TableFilterInputText } from "../../../components/table-filter-inputs";
import { translate } from "../../../languages";
import { PopupWraper } from "../../../components/popup";
import "./IpoList.scss";
import { Ban } from "./ban";
import { CreateEdit } from "./CreateEdit";
import { IpoService } from "../../../services/ipo";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { InputToggleSwitch } from "../../../components/toggle-switch";
import { InputSelect, InputText } from "../../../components";
import { EInvestorCategory, optionsStatus } from "../enumIPO";

export const IpoList = withIpoWraper(
    memo(() => {
        const [params, setParams] = useState([] as any[]);
        const [editData, setEditData] = useState<any>(null);
        const [freezeDataId, setFreezeDataId] = useState<number | null>(null);
        const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());

        const structure: ITableStructureItem[] = [
            {
                name: translate("all.name"),
                key: "name",
            },
            {
                name: translate("ipo.symbol"),
                key: "symbol",
            },
            {
                name: translate("all.type"),
                key: "actions",
                render: (item) => {
                    return (
                        <div className="btnGroup">
                            <InputToggleSwitch
                                onChange={async (value) => {
                                    const res = await IpoService.updateIpo({
                                        name: item.name,
                                        symbol: item.symbol,
                                        isActive: value,
                                        id: item.ipoManagerId,
                                    });
                                    if (res?.success) {
                                        setForceUpdateTable(Math.random);
                                        setEditData(null);
                                    }
                                }}
                                value={item.isActive}
                                onTouched={() => false}
                                label=""
                                name="active"
                            />
                        </div>
                    );
                },
            },
            {
                name: translate("all.actions"),
                key: "actions",
                render: (item) => {
                    return (
                        <div className="btnGroup">
                            <span
                                className="btn btn__edit"
                                onClick={() =>
                                    setEditData({ ...item, isCreate: false })
                                }
                            >
                                <Icon.Edit />
                            </span>
                            <span
                                className="btn btn__ban"
                                onClick={() =>
                                    setFreezeDataId(item.ipoManagerId)
                                }
                            >
                                <Icon.Trash />
                            </span>
                        </div>
                    );
                },
            },
        ];

        const handleExportExcel = async () => {
            return new Promise(async (resolve) => {
                try {
                    const response = await IpoService.getListIpo({
                        ...params,
                        page: 1,
                        numberOfItemsPerPage: 1000,
                    });

                    const data = response.data;

                    const fileHead = structure.map((v) => v.name);
                    const dataExport = [
                        fileHead,
                        ...data.map((item: any) =>
                            structure.map((column, index) => {
                                if (!column.key) return "";
                                return item[column.key];
                            })
                        ),
                    ];

                    const ws = XLSX.utils.aoa_to_sheet(dataExport);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                    const now = new Date();
                    XLSX.writeFile(
                        wb,
                        `User List ${now
                            .toLocaleDateString()
                            .replace(/\//g, "-")} ${now
                            .toLocaleTimeString()
                            .replace(/:/g, "-")}.xlsx`,
                        { type: "binary" }
                    );

                    resolve(
                        CreateAlert({
                            type: "success",
                            message: "Export data success.",
                        })
                    );
                } catch (error: any) {
                    resolve(
                        CreateAlert({ type: "danger", message: error.message })
                    );
                }
            });
        };

        const getList = (item: any) => {
            console.log(item);

            setParams(item);
            return IpoService.getListIpo({
                page: item.pageNumber,
                numberOfIPOPerPage: item.limit,
                ...item,
            });
        };

        const tableMemo = useMemo(() => {
            return (
                <Table
                    structure={structure}
                    fetchData={async (paramsTable) => {
                        // setParams(params);
                        return getList({ ...params, ...paramsTable });
                        // return IpoService.getListIpo({
                        //     // page: params.pageNumber,
                        //     // numberOfIPOPerPage: params.limit,
                        //     ...params,
                        // });
                    }}
                />
            );
        }, [forceUpdateTable, params]);

        return (
            <div className="UserList">
                <Button
                    className="mb20 mr20"
                    label={translate("all.exportExcel")}
                    buttonType="success"
                    onClick={handleExportExcel}
                />
                <Button
                    className="mb20"
                    label={translate("ipo.create")}
                    buttonType="success"
                    onClick={() =>
                        setEditData({ name: "", symbol: "", isCreate: true })
                    }
                />

                <FilterComponent
                    onFilter={(item: any) => {
                        console.log(item);

                        setParams({ ...params, ...item });
                    }}
                />

                {tableMemo}
                {editData && (
                    <PopupWraper
                        center
                        title={
                            editData.isCreate
                                ? translate("ipo.create")
                                : translate("ipo.update")
                        }
                        onClose={() => setEditData(null)}
                    >
                        <CreateEdit
                            onClose={() => setEditData(null)}
                            data={editData}
                            onFinishEdit={() => {
                                setForceUpdateTable(Math.random);
                                setEditData(null);
                            }}
                        />
                    </PopupWraper>
                )}
                {freezeDataId && (
                    <PopupWraper
                        center
                        title={translate("ipo.delete")}
                        onClose={() => setFreezeDataId(null)}
                    >
                        <Ban
                            ipoId={freezeDataId}
                            onFinishEdit={() => {
                                setForceUpdateTable(Math.random);
                                setFreezeDataId(null);
                            }}
                        />
                    </PopupWraper>
                )}
            </div>
        );
    })
);

const FilterComponent = ({ onFilter }: any) => {
    const [filter, setFilter] = useState<any>({});

    return (
        <div className="group">
            <div className="mr5">
                <div className="label">{translate("payment.name")}</div>
                <InputWraper
                    inputProps={{
                        onChange: (e) =>
                            setFilter((val: any) => ({
                                ...val,
                                paymentName: e,
                            })),
                        value: filter.paymentName,
                        name: "paymentName",
                        onTouched: () => false,
                    }}
                    component={InputText}
                />
            </div>

            <div className="ml5 mr5 wd250">
                <div className="label">{translate("all.status")}</div>
                <InputWraper
                    inputProps={{
                        onChange: (e) =>
                            setFilter((val: any) => ({ ...val, isActive: e })),
                        value: filter.isActive,
                        name: "isActive",
                        onTouched: () => false,
                    }}
                    renderInput={(inputProps) => (
                        <InputSelect
                            {...inputProps}
                            options={optionsStatus()}
                        />
                    )}
                />
            </div>

            <div className="ml5 mr5 wd250">
                <div className="label">chủ đầu tư</div>
                <InputWraper
                    inputProps={{
                        onChange: (e) =>
                            setFilter((val: any) => ({
                                ...val,
                                investorCategory: e,
                            })),
                        value: filter.investorCategory,
                        name: "investorCategory",
                        onTouched: () => false,
                    }}
                    renderInput={(inputProps) => (
                        <InputSelect
                            {...inputProps}
                            options={[
                                {
                                    value: EInvestorCategory.CODE,
                                    label: "Mã số",
                                },
                                {
                                    value: EInvestorCategory.LEGAL_ENTITY_NAME,
                                    label: "Tên cá nhân/Pháp nhân",
                                },
                                {
                                    value: EInvestorCategory.TAX_CODE,
                                    label: "Mã số pháp lý/Thuế (ĐKKD)",
                                },
                                {
                                    value: EInvestorCategory.PERMANENT_ADDRESS,
                                    label: "Địa chỉ thường trú/Trụ sở",
                                },
                                {
                                    value: EInvestorCategory.WEBSITE_ADDRESS,
                                    label: "Địa chỉ web site",
                                },
                            ]}
                        />
                    )}
                />
            </div>

            <div className="btnGroup ml5">
                <Button
                    label={translate("all.search")}
                    onClick={() => onFilter(filter)}
                />
                <span
                    className="btn"
                    onClick={() => {
                        setFilter(defaultFilter);
                        onFilter(defaultFilter);
                    }}
                >
                    <Icon.Remove />
                </span>
            </div>
        </div>
    );
};

const defaultFilter = { paymentName: "", status: "" };
