import React, { FC, Fragment, useEffect } from "react";

import { useSelector } from "../store";
import { MainService } from "../services/main";
import { Navigator } from "../components";
import { UserHead } from "../components/head";
import { useNavigate } from "react-router-dom";

export const WithPageWraper: FC = (props) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const serverTime = useSelector((state) => state.main.serverTime);
    const userPageInitializeStatus = useSelector(
        (state) => state.main.userPageInitializeStatus
    );

    useEffect(() => {
        if (userPageInitializeStatus === "pending")
            MainService.initialUserPage();
        if (userPageInitializeStatus === "completed") {
            if (!user) {
                localStorage.setItem(
                    "auth-redirect-to",
                    window.location.pathname
                );
                navigate("/auth/login");
            }
        }
    }, [user, userPageInitializeStatus, navigate]);

    if (user && userPageInitializeStatus === "completed" && serverTime)
        return (
            <Fragment>
                <div className="UserWraper">
                    <Navigator />
                    <UserHead />

                    <div className="content pd20">{props.children}</div>
                </div>
            </Fragment>
        );

    return (
        <div className="UserWraper loading">
            <div className="loading">
                <svg
                    id="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 89.695 92.981"
                >
                    <path
                        d="M2290.63,193.293l-53.1.026,41.981,72.554,4.743-8.384-18.6-32.409,14.775-.054-4.995-8.25h-14.559l-8.715-14.964,14.822-.061,18.971.02Z"
                        transform="translate(-2234.553 -172.892)"
                    />
                    <path
                        d="M2358.163,193.292h-9.773l-7.158,12.322,2.251,4.057-6.261,3.182-3.276,5.318,4.934,8.371,7.017-12.059,6.2.189-17.586,29.695,5.048,8.536,21.8-37.746-2.305-3.357,7.589-5.642,7.32-12.868h-9.625l-7.26,13.09-6.613-.02Z"
                        transform="translate(-2287.244 -172.891)"
                    />
                    <path
                        className="b"
                        d="M2289.6,157.826a35.152,35.152,0,0,1,18.756,5.4l14.628,0a44.83,44.83,0,0,0-66.788.022l14.628-.005A35.152,35.152,0,0,1,2289.6,157.826Z"
                        transform="translate(-2244.757 -148.307)"
                    />
                    <path
                        className="b"
                        d="M2240.486,230.2c0-.508.018-1.012.039-1.515l-7.283-12.586a44.854,44.854,0,0,0,33.587,58.048l-7.283-12.59A35.316,35.316,0,0,1,2240.486,230.2Z"
                        transform="translate(-2230.966 -185.357)"
                    />
                    <path
                        className="b"
                        d="M2376.282,228.856c.019.463.035.925.035,1.391a35.315,35.315,0,0,1-18.8,31.225l-7.287,12.665a44.851,44.851,0,0,0,33.346-57.956Z"
                        transform="translate(-2296.143 -185.401)"
                    />
                </svg>
                <svg
                    id="ripple1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 89.695 92.981"
                >
                    <path
                        d="M2290.63,193.293l-53.1.026,41.981,72.554,4.743-8.384-18.6-32.409,14.775-.054-4.995-8.25h-14.559l-8.715-14.964,14.822-.061,18.971.02Z"
                        transform="translate(-2234.553 -172.892)"
                    />
                    <path
                        d="M2358.163,193.292h-9.773l-7.158,12.322,2.251,4.057-6.261,3.182-3.276,5.318,4.934,8.371,7.017-12.059,6.2.189-17.586,29.695,5.048,8.536,21.8-37.746-2.305-3.357,7.589-5.642,7.32-12.868h-9.625l-7.26,13.09-6.613-.02Z"
                        transform="translate(-2287.244 -172.891)"
                    />
                    <path
                        className="b"
                        d="M2289.6,157.826a35.152,35.152,0,0,1,18.756,5.4l14.628,0a44.83,44.83,0,0,0-66.788.022l14.628-.005A35.152,35.152,0,0,1,2289.6,157.826Z"
                        transform="translate(-2244.757 -148.307)"
                    />
                    <path
                        className="b"
                        d="M2240.486,230.2c0-.508.018-1.012.039-1.515l-7.283-12.586a44.854,44.854,0,0,0,33.587,58.048l-7.283-12.59A35.316,35.316,0,0,1,2240.486,230.2Z"
                        transform="translate(-2230.966 -185.357)"
                    />
                    <path
                        className="b"
                        d="M2376.282,228.856c.019.463.035.925.035,1.391a35.315,35.315,0,0,1-18.8,31.225l-7.287,12.665a44.851,44.851,0,0,0,33.346-57.956Z"
                        transform="translate(-2296.143 -185.401)"
                    />
                </svg>
                <svg
                    id="ripple2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 89.695 92.981"
                >
                    <path
                        d="M2290.63,193.293l-53.1.026,41.981,72.554,4.743-8.384-18.6-32.409,14.775-.054-4.995-8.25h-14.559l-8.715-14.964,14.822-.061,18.971.02Z"
                        transform="translate(-2234.553 -172.892)"
                    />
                    <path
                        d="M2358.163,193.292h-9.773l-7.158,12.322,2.251,4.057-6.261,3.182-3.276,5.318,4.934,8.371,7.017-12.059,6.2.189-17.586,29.695,5.048,8.536,21.8-37.746-2.305-3.357,7.589-5.642,7.32-12.868h-9.625l-7.26,13.09-6.613-.02Z"
                        transform="translate(-2287.244 -172.891)"
                    />
                    <path
                        className="b"
                        d="M2289.6,157.826a35.152,35.152,0,0,1,18.756,5.4l14.628,0a44.83,44.83,0,0,0-66.788.022l14.628-.005A35.152,35.152,0,0,1,2289.6,157.826Z"
                        transform="translate(-2244.757 -148.307)"
                    />
                    <path
                        className="b"
                        d="M2240.486,230.2c0-.508.018-1.012.039-1.515l-7.283-12.586a44.854,44.854,0,0,0,33.587,58.048l-7.283-12.59A35.316,35.316,0,0,1,2240.486,230.2Z"
                        transform="translate(-2230.966 -185.357)"
                    />
                    <path
                        className="b"
                        d="M2376.282,228.856c.019.463.035.925.035,1.391a35.315,35.315,0,0,1-18.8,31.225l-7.287,12.665a44.851,44.851,0,0,0,33.346-57.956Z"
                        transform="translate(-2296.143 -185.401)"
                    />
                </svg>
            </div>
        </div>
    );
};
