import React, { memo, useEffect, useMemo, useState } from "react";
import { Box } from '@mui/material';
import { InputCheckbox, InputSelect } from "../../../components";
import { CreateAlert } from "../../../modules";
import { RoleService } from "../../../services/role";
import { GroupPermission, RolePermissionService } from "../../../services/role-permission";
import { withRoleWraper } from "../wraper";
import "./index.scss";
import { translate } from "../../../languages";
import { cloneDeep } from "lodash";

const adminRoleId = 1;
export const Permission = withRoleWraper(
    memo(() => {
        const groupPermissionMemo = useMemo(() => GroupPermission(), [])
        const [optionsRole, setOptionsRole] = useState();
        const [role, setRole] = useState<any>();
        const [dataOrigin, setDataOrigin] = useState<any>();
        const [availablePermis, setAvailablePermis] = useState<any>();
        const [unAvailablePermis, setUnAvailablePermis] = useState<any>();

        useEffect(() => {
            getPermissionByRole(adminRoleId)

            RoleService.get({
                page: 1,
                numberOfRolePerPage: 1,
                isGetAll: true,
                // roleStatus: "ACTIVE"
            })
                .then(res => {
                    if (res.data) {
                        setOptionsRole(res.data.map((item: any) => ({ label: item.roleName, value: item.roleId })))
                    }
                })
                .catch(error => {
                    CreateAlert({ type: "danger", message: error.message })
                })
        }, [])

        const getPermissionByRole = (value: any) => {
            RolePermissionService.getByRoleId(value)
                .then(res => {
                    if (res.success) {
                        const results = res.result;
                        setRole(value)
                        setDataOrigin(results)

                        // map permission
                        const { availableArray, unAvailableArray } = mapPermissionToDataRender(results)
                        setAvailablePermis(availableArray)
                        setUnAvailablePermis(unAvailableArray)
                    }
                })
        }

        const handleCheckPermission = (value: boolean, { name, position, items, groupIndex, permisIndex }: any) => {
            let newItems: any = position === "LEFT" ? cloneDeep(availablePermis[groupIndex]) : cloneDeep(unAvailablePermis[groupIndex]);

            if (name === "checkall") {
                newItems.checkall = value;
                newItems.permission = newItems.permission.map((item: any) => ({ ...item, check: value }));
            } else {
                newItems.permission[permisIndex] = { ...items[0], check: value }

                const checkCount = newItems.permission.filter((permis: any) => permis.check).length
                if (newItems.permission.length === checkCount) {
                    newItems.checkall = true
                } else {
                    newItems.checkall = false
                }
            }

            if (position === "LEFT") {
                setAvailablePermis(availablePermis.map((item: any, index: number) => index === groupIndex ? newItems : item))
            } else {
                setUnAvailablePermis(unAvailablePermis.map((item: any, index: number) => index === groupIndex ? newItems : item))
            }
        }

        function mapPermissionToDataRender(results: any) {
            // map permission
            const availableArray: any = [],
                unAvailableArray: any = [];

            groupPermissionMemo.forEach((group: any) => {
                const availableObj = { title: group.title, checkall: false, permission: [] as any }
                const unAvailableObj = { title: group.title, checkall: false, permission: [] as any }

                group.permission.forEach((permis: any) => {
                    const temp = results.find((t: any) => t.permission === permis.value)
                    permis.check = false;
                    if (temp) {
                        availableObj.permission.push(permis)
                    } else {
                        unAvailableObj.permission.push(permis)
                    }
                })

                availableArray.push(availableObj)
                unAvailableArray.push(unAvailableObj)
            })
            return {
                availableArray: availableArray.filter((item: any) => item.permission?.length > 0),
                unAvailableArray: unAvailableArray.filter((item: any) => item.permission?.length > 0)
            }
        }

        const handleUpdatePermission = (toTable: string) => {
            let data = availablePermis;
            if (toTable === "LEFT") data = unAvailablePermis;

            let updateItems: any = [];
            data.forEach((item: any) => {
                const enumPermis = item.permission
                    .filter((permis: any) => permis.check)
                    .map((permis: any) => permis.value)
                updateItems = updateItems.concat(enumPermis)
            });

            if (!updateItems || updateItems.length === 0) {
                return CreateAlert({ type: "danger", message: translate("permission.validRequired") });
            }

            const updateItemsOrigin = dataOrigin.map((item: any) => item.permission)
            if (toTable === "LEFT") {
                updateItems = updateItems.concat(updateItemsOrigin)
            } else {
                updateItems = updateItemsOrigin.filter((permisName: any) => !updateItems.includes(permisName))
            }

            RolePermissionService.update({ id: role, permissions: updateItems })
                .then(res => {
                    if (res.success) {
                        getPermissionByRole(role)
                        CreateAlert({ type: "success", message: translate("all.updateSuccess") })
                    } else {
                        CreateAlert({ type: "danger", message: res.message })
                    }
                })
                .catch(error => {
                    CreateAlert({ type: "danger", message: error.message })
                })
        }

        return <div className="RolePermission">
            <div className="group">
                <div className="label mr10">Role</div>
                <InputSelect
                    defaultValue={adminRoleId}
                    value={role}
                    isClearable={false}
                    options={optionsRole}
                    name="roleId"
                    onTouched={() => false}
                    onChange={getPermissionByRole}
                />
            </div>

            <div className="gridContainer mt20">
                <TableRolePermission
                    tableKey="LEFT"
                    title={translate("permission.available")}
                    data={availablePermis}
                    onChange={handleCheckPermission}
                />

                <div className="iconLeftRight">
                    <Box fontSize={60} onClick={() => handleUpdatePermission("LEFT")}>
                        <i className="fa-solid fa-left-long"></i>
                    </Box>
                    <Box fontSize={60} onClick={() => handleUpdatePermission("RIGHT")}>
                        <i className="fa-solid fa-right-long"></i>
                    </Box>
                </div>

                <TableRolePermission
                    tableKey="RIGHT"
                    title={translate("permission.unavailable")}
                    data={unAvailablePermis}
                    onChange={handleCheckPermission}
                />
            </div>

        </div>
    })
);

const TableRolePermission = ({ tableKey, title, data, onChange }: any) => {
    return <div className="tablePermission">
        <h2 className="title">{title}</h2>

        <div className="content">
            {data?.map((item: any, groupIndex: number) => {
                return <div key={`k${groupIndex}`} className="mb10">
                    <div className="hover">
                        <InputCheckbox
                            name="checkall"
                            type="checkbox"
                            label={item.title}
                            value={item.checkall}
                            onTouched={() => false}
                            onChange={(value) => onChange(value,
                                { position: tableKey, name: "checkall", items: item.permission, groupIndex: groupIndex })}
                        />
                    </div>

                    {item.permission?.map((permis: any, permisIndex: number) => {
                        return <div key={permis.value} className="ml40 hover">
                            <InputCheckbox
                                name="checkbox"
                                type="checkbox"
                                label={permis.label}
                                value={permis.check}
                                onTouched={() => false}
                                onChange={(value) => onChange(value,
                                    { position: tableKey, ame: "check", items: [permis], groupIndex, permisIndex })}
                            />
                        </div>
                    })}
                </div>
            })}
        </div>
    </div>
}