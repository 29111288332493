import React, { useState, memo, useMemo, useEffect } from "react";
import XLSX from "xlsx";

import {
    ITableStructureItem,
    CreateAlert,
    Button,
    Icon,
    InputWraper,
    Table,
} from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withUserWraper } from "../wraper";
import { translate } from "../../../languages";
import { PopupWraper } from "../../../components/popup";
import "./UserList.scss";
import { CreateEditUser } from "./CreateEdit";
import { InputSelect, InputText } from "../../../components";
import { enumUser, userGetOptionEnum } from "../enumUser";
import { UserInfo } from "./UserInfo";
import { cloneDeep } from "lodash";
import { RoleService } from "../../../services/role";

export const UserList = withUserWraper(
    memo(() => {
        const [forceUpdateTable, setForceUpdateTable] = useState(false);
        const [paramsTable, setParamsTable] = useState<any>({});
        const [editData, setEditData] = useState<any>(null);
        const [viewInfoUser, setViewInfoUser] = useState<any>(null);

        const structure: ITableStructureItem[] = [
            {
                name: "User ID",
                key: "userId",
                render: (item) => {
                    return (
                        <a onClick={() => getInfoUser(item)}>{item.userId}</a>
                    );
                },
            },
            {
                name: "Email",
                key: "email",
                // render: (item) => {
                //     return (
                //         <Link to={Routes.userDetail.renderPath(item.userId)}>
                //             {item.email}
                //         </Link>
                //     );
                // },
            },
            {
                name: translate("all.lastName"),
                key: "lastName",
            },
            {
                name: translate("all.name"),
                key: "firstName",
            },
            {
                name: translate("user.phone"),
                key: "phoneNumber",
                render: (item) => {
                    return item.phoneNumber
                        ? `+${item.phoneNumberPrefix} ${item.phoneNumber}`
                        : "";
                },
            },
            {
                name: "Role",
                key: "roleName",
                render: (item) => {
                    return item.roleName || "";
                },
            },
            {
                name: translate("user.status"),
                key: "isActived",
                render: (item) =>
                    userGetOptionEnum("active", item.isActived.toString())
                        ?.label,
                // sort: {
                //     descreaseValue: "desc",
                //     increaseValue: "",
                // },
            },
            {
                name: "Kyc",
                key: "kycStatus",
                render: (item) =>
                    userGetOptionEnum("kycStatus", item.kycStatus)?.label,
            },
            {
                name: translate("all.payment"),
                key: "paymentName",
            },
            {
                name: translate("all.actions"),
                key: "actions",
                render: (item) => {
                    return (
                        <div className="btnGroup">
                            <span
                                className="btn btn__edit"
                                onClick={() => getDetailUser(item)}
                            >
                                <Icon.Edit />
                            </span>
                        </div>
                    );
                },
            },
        ];

        const handleExportExcel = () => {
            return new Promise(async (resolve) => {
                try {
                    const response = await getUserList(paramsTable);

                    const data = response.data;
                    // Remove last element (always action)
                    let newStructure: ITableStructureItem[] =
                        cloneDeep(structure);
                    newStructure.pop();

                    const fileHead = newStructure.map((v: any) => v.name);
                    const dataExport = [
                        fileHead,
                        ...data.map((item: any) =>
                            newStructure.map((column: any) => {
                                if (!column.key) return "";

                                if (column.key === "kycStatus")
                                    return userGetOptionEnum(
                                        "kycStatus",
                                        item[column.key]
                                    )?.label;
                                else if (column.key === "isActived")
                                    return userGetOptionEnum(
                                        "active",
                                        item[column.key]
                                    )?.label;
                                else return item[column.key];
                            })
                        ),
                    ];

                    const ws = XLSX.utils.aoa_to_sheet(dataExport);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                    const now = new Date();
                    XLSX.writeFile(
                        wb,
                        `User List ${now
                            .toLocaleDateString()
                            .replace(/\//g, "-")} ${now
                            .toLocaleTimeString()
                            .replace(/:/g, "-")}.xlsx`,
                        { type: "binary" }
                    );

                    resolve(
                        CreateAlert({
                            type: "success",
                            message: "Export data success.",
                        })
                    );
                } catch (error: any) {
                    resolve(
                        CreateAlert({ type: "danger", message: error.message })
                    );
                }
            });
        };

        const getDetailUser = (item: any) => {
            return new Promise(async (resolve) => {
                try {
                    const res = await AdminService.getUser(item.userId);
                    if (res.success) {
                        setEditData({
                            ...res.result,
                            isCreate: false,
                            userId: item.userId,
                        });
                    }
                } catch (error: any) {
                    resolve(
                        CreateAlert({ type: "danger", message: error.message })
                    );
                }
            });
        };

        const getInfoUser = (item: any) => {
            AdminService.getUser(item.userId)
                .then((res) => {
                    if (res.success) {
                        setViewInfoUser({
                            ...res.result,
                            userId: item.userId,
                            paymentName: item.paymentName,
                            kycStatus: item.kycStatus,
                        });
                    }
                })
                .catch((err) => {
                    CreateAlert({ type: "danger", message: err.message });
                });
        };

        const getUserList = (params?: any) => {
            return AdminService.getListUser({
                ...params,
                page: params.pageNumber,
                numberOfItemsPerPage: params.limit,
            });
        };

        const tableMemo = useMemo(() => {
            return (
                <Table
                    structure={structure}
                    fetchData={async (params) => {
                        const temp = { ...paramsTable, ...params };
                        setParamsTable(temp);
                        return getUserList(temp);
                    }}
                />
            );
        }, [forceUpdateTable, setParamsTable]);

        return (
            <div className="UserList">
                <div className="mb20">
                    <Button
                        className="mr20"
                        label={translate("all.exportExcel")}
                        buttonType="success"
                        onClick={handleExportExcel}
                    />

                    <Button
                        label={translate("user.create")}
                        buttonType="success"
                        onClick={() => setEditData(defaultAddItem)}
                    />
                </div>

                <FilterComponent
                    onFilter={(item: any) => {
                        setParamsTable({ ...paramsTable, ...item });
                        setForceUpdateTable(!forceUpdateTable);
                    }}
                />

                {tableMemo}

                {editData && (
                    <PopupWraper
                        center
                        title={
                            editData.isCreate
                                ? translate("user.create")
                                : translate("user.update")
                        }
                        onClose={() => setEditData(null)}
                    >
                        <CreateEditUser
                            data={editData}
                            onFinishEdit={() => {
                                setForceUpdateTable(!forceUpdateTable);
                                setEditData(null);
                            }}
                        />
                    </PopupWraper>
                )}
                {viewInfoUser && (
                    <PopupWraper
                        center
                        title={translate("user.info")}
                        onClose={() => setViewInfoUser(null)}
                    >
                        <UserInfo
                            data={viewInfoUser}
                            onClose={() => setViewInfoUser(null)}
                        />
                    </PopupWraper>
                )}
            </div>
        );
    })
);

const FilterComponent = ({ onFilter }: any) => {
    const optionsKYC = useMemo(() => enumUser.optionsKycStatus(), []);
    const optionStatus = useMemo(() => enumUser.optionsActive(), []);
    const [optionsRole, setOptionRoles] = useState([]);
    const [filter, setFilter] = useState<any>(defaultFilterUser);

    useEffect(() => {
        RoleService.get({
            page: 1,
            numberOfRolePerPage: 1,
            isGetAll: true,
            roleStatus: "ACTIVE",
        }).then((res) => {
            if (res.data) {
                setOptionRoles(
                    res.data.map((item: any) => ({
                        label: item.roleName,
                        value: item.roleId,
                    }))
                );
            }
        });
    }, []);

    return (
        <>
            <div className="group">
                <div className="itemSelect wfull mr5">
                    <div className="label">{translate("user.email")}</div>
                    <InputWraper
                        inputProps={{
                            onChange: (value) =>
                                setFilter({ ...filter, email: value }),
                            value: filter.email,
                            name: "email",
                            onTouched: () => false,
                        }}
                        component={InputText}
                    />
                </div>
                <div className="itemSelect wfull mr5">
                    <div className="label">{translate("user.fullname")}</div>
                    <InputWraper
                        inputProps={{
                            onChange: (value) =>
                                setFilter({ ...filter, name: value }),
                            value: filter.name,
                            name: "name",
                            onTouched: () => false,
                        }}
                        component={InputText}
                    />
                </div>
                <div className="itemSelect wfull mr5">
                    <div className="label">{translate("user.status")}</div>
                    <InputSelect
                        options={optionStatus}
                        onChange={(value) =>
                            setFilter({ ...filter, isActived: value })
                        }
                        onTouched={() => false}
                        value={filter.isActived}
                        name="isActived"
                    />
                </div>
                <div className="itemSelect wfull mr5">
                    <div className="label">Role</div>
                    <InputSelect
                        options={optionsRole}
                        onChange={(value) =>
                            setFilter({ ...filter, roleId: value })
                        }
                        onTouched={() => false}
                        value={filter.roleId}
                        name="roleId"
                    />
                </div>
                <div className="itemSelect wfull">
                    <div className="label">KYC</div>
                    <InputSelect
                        options={optionsKYC}
                        onChange={(value) =>
                            setFilter({ ...filter, kycStatus: value })
                        }
                        onTouched={() => false}
                        value={filter.kycStatus}
                        name="kycStatus"
                    />
                </div>
            </div>
            <div>
                <div className="btnGroup">
                    <Button
                        label={translate("all.search")}
                        onClick={() => onFilter(filter)}
                    />
                    <span
                        className="btn btn__edit"
                        onClick={() => {
                            setFilter(defaultFilterUser);
                            onFilter(defaultFilterUser);
                        }}
                    >
                        <Icon.Remove />
                    </span>
                </div>
            </div>
        </>
    );
};

const defaultFilterUser = {
    isActived: null,
    roleId: "",
    email: "",
    name: "",
    status: "",
    kycStatus: "",
};
const defaultAddItem = {
    isCreate: true,
    accountType: "BUSINESS",
    isActived: "true",
    countryId: 232,
};
