import React, { FC } from "react";
import { useAppLifeCycle } from "../../hook";
import { InputToggleSwitch } from "../toggle-switch";
import { translate } from "../../languages";

export const SettingBox: FC = () => {
    const { updateLifeCycle } = useAppLifeCycle();
    const isEnableSound = localStorage.getItem("sound") !== "off";

    return (
        <div className="SettingBox">
            <InputToggleSwitch
                onChange={(e) => {
                    if (!e) localStorage.setItem("sound", "off");
                    else localStorage.setItem("sound", "on");
                    updateLifeCycle();
                }}
                value={isEnableSound}
                onTouched={() => false}
                label={translate("all.sound")}
                name="sound"
            />
        </div>
    );
};
