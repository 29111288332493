import React, { FC, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import { ClassNames, IInputProps } from '../../../modules';
import './index.scss';

interface IInputNumber extends IInputProps {
    suffix?: string;
    thousandSeparator?: string | boolean;
    decimalScale?: number;
}

const InputNumber: FC<IInputNumber> = ({ value, onChange, onTouched,
    suffix, thousandSeparator, decimalScale }: any) => {
    return (
        <NumberFormat
            fixedDecimalScale={false}
            value={typeof value === 'number' ? value : undefined}
            onValueChange={({ floatValue }) => {
                const data = floatValue || null;
                onChange(data);
            }}
            onBlur={() => setTimeout(() => onTouched(), 500)}
            suffix={suffix}
            decimalScale={decimalScale}
            thousandSeparator={thousandSeparator}
        />
    )
}


/********************************** IInputNumberRange ************************************/
interface IValues {
    fromNum?: number,
    toNum?: number
}

interface IInputNumberRange extends Omit<IInputNumber, 'value' | 'name' | 'defaultValue'> {
    values?: IValues;
    isDisable?: boolean;
}

export const InputNumberRange: FC<IInputNumberRange> = (props: IInputNumberRange) => {
    const { onChange, onTouched, suffix, thousandSeparator, decimalScale } = props
    const [values, setValues] = useState<IValues>();

    useEffect(() => {
        if (props.values !== values) {
            setValues(props.values)
        }
    }, [props.values])

    const handleChange = (e: any, name: string) => {
        const newValues = { ...values, [name]: e }
        setValues(newValues)
        onChange(newValues)
    }

    return <div className={ClassNames({
        InputNumberRange: true,
        // [className as string]: !!className,
        disabled: props.isDisable,
    })}>
        <div className="input">
            <div className='label'>From</div>
            <InputNumber
                name="fromNum"
                value={values?.fromNum}
                onChange={(e) => handleChange(e, "fromNum")}
                onTouched={() => false}
                thousandSeparator
            />
        </div>

        <div className="input">
            <div className='label'>To</div>
            <InputNumber
                name="toNum"
                value={values?.toNum}
                onChange={(e) => handleChange(e, "toNum")}
                onTouched={() => false}
                thousandSeparator
            />
        </div>
    </div>
}