import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { translate } from "../../languages";

export const withFranchiseWraper =
    (Component: FC<{ data: any; onFinishEdit: () => void }>) =>
    (props: any) => {
        return (
            <div className="withFranchiseWraper">
                <div className="TabLinks">
                    <NavLink to={Routes.setting.path}>Setting</NavLink>

                    <NavLink to={Routes.settingNoti.path}>
                        {translate("notify")}
                    </NavLink>
                </div>

                <Component {...props} />
            </div>
        );
    };
