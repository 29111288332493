import React, { FC } from "react";
import { IInputProps } from "../../../../modules";

export const InputText: FC<IInputProps> = ({
    onChange,
    onTouched,
    value,
    placeholder,
}) => {
    return (
        <input
            type="text"
            onChange={(e) => onChange(e.target.value)}
            onBlur={() => setTimeout(() => onTouched(), 500)}
            value={value}
            style={{
                background: "white",
                color: "black",
            }}
            placeholder={placeholder}
        />
    );
};
