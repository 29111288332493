import { translate } from "../../languages";

export const EDigitalType = {
    EQUITIZATION: "EQUITIZATION",
    CERTIFICATIONS: "CERTIFICATIONS",
    BIOCHEMICAL_DERIVATION: "BIOCHEMICAL_DERIVATION",
    SECURITIZATION: "SECURITIZATION",
};

export const EInvestorCategory = {
    CODE: "CODE",
    LEGAL_ENTITY_NAME: "LEGAL_ENTITY_NAME",
    TAX_CODE: "TAX_CODE",
    PERMANENT_ADDRESS: "PERMANENT_ADDRESS",
    WEBSITE_ADDRESS: "WEBSITE_ADDRESS",
};

export const optionsStatus = () => [
    { value: "true", label: translate("all.active") },
    { value: "false", label: translate("all.deactive") },
];
