/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from "react";
import "./index.scss";
import { Button, CreateAlert } from "../../../../modules";
import { CmsService } from "../../../../services";
import { translate } from "../../../../languages";

export const Ban: FC<{ userId: number; onFinishEdit: () => void }> = (
    props
) => {
    const confirmDelete = async () => {
        await CmsService.deleteCms(props.userId)
            .then(() => {
                CreateAlert({
                    message: translate("all.deleteSuccess"),
                    type: "success",
                });
                props.onFinishEdit();
            })
            .catch((error) => {
                CreateAlert({
                    message: error.message,
                    type: "danger",
                });
            });
    };
    return (
        <div className="Info">
            <div className="mb20">{translate("cms.deleteConfirm")}</div>
            <Button
                label={translate("all.confirm")}
                onClick={() => confirmDelete()}
                isMiddle
                className="btnBan"
                type="submit"
            />
        </div>
    );
};
