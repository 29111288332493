import { translate } from "../../languages";

export const EFAQDescriptionType = {
    URL: "URL",
    SHORT_DESCRIPTION: "SHORT_DESCRIPTION",
    LONG_DESCRIPTION: "LONG_DESCRIPTION",
};

export const EFAQStatus: any = {
    ACTIVE: {
        value: "ACTIVE",
        label: "Hoạt động",
    },
    INACTIVE: {
        value: "INACTIVE",
        label: "không hoạt động",
    },
};

export const EFAQVisiblePage: any = {
    HOME_PAGE: {
        value: "HOME_PAGE",
        label: "Homepage",
    },
    FAQ_PAGE: {
        value: "FAQ_PAGE",
        label: "Câu hỏi thường gặp",
    },
};
