import { RequestMainService } from "../request";
import { CookieService, ECookieVariable } from "../cookie";
import { ObjectUtils } from "../../modules";

export class IpoService {
    static async getListIpo(params: any = {}) {
        return RequestMainService.get(`/admin/ipo`, params).then(
            ({ result }) => ({
                count: result.total,
                data: result.ipos,
            })
        );
    }
    static getListIpoIsActive(params: any = {}) {
        return [
            { label: "IS_ACTIVE", value: "true" },
            { label: "IS_NOT_ACTIVE", value: "false" },
        ];
    }
    static async createIpo(params: any) {
        return RequestMainService.post(`/admin/ipo`, params);
    }

    static async updateIpo(params: any) {
        return RequestMainService.put(`/admin/ipo/${params.id}`, params);
    }

    static async deleteIpo(id: any) {
        return RequestMainService.delete(`/admin/ipo/${id}`);
    }
}
