import React, { FC } from "react";
import "./index.scss";
import {
    Button,
    useForm,
    InputWraper,
    CreateAlert,
} from "../../../../modules";
import { translate } from "../../../../languages";
import * as Yup from "yup";
import {
    InputCheckbox,
    InputText,
} from "../../../../components";
import { RoleService } from "../../../../services/role";
import { roleEnumStatus, roleFormType } from "../../enumRole";

export const CreateEditRole: FC<{ data: any; onFinishEdit: () => void; onClose: () => void }> = (
    props
) => {
    const {
        roleId,
        formType,
        roleName,
        status
    } = props.data;

    const { handleSubmit, getInputProps, isSubmitting, values, setValues } = useForm({
        structure: [
            {
                name: "roleName",
                defaultValue: roleName,
                validate: Yup.string()
                    .required(translate("validate.mustProvided")),
            },
            {
                name: "roleStatus",
                defaultValue: status,
            },
        ],
        onSubmit: async (items) => {
            if (formType === roleFormType.ADD) {
                return RoleService.create({
                    ...items
                })
                    .then((res) => {
                        if (res.success) {
                            CreateAlert({
                                message: translate("all.createSuccess"),
                                type: "success",
                            });
                            props.onFinishEdit();
                        } else {
                            CreateAlert({
                                message: translate("role.validDuplicate"),
                                type: "danger",
                            });
                        }
                    })
                    .catch((error) => {
                        CreateAlert({
                            message: error.message,
                            type: "danger",
                        });
                    });
            }
            return RoleService.update({
                id: roleId,
                ...items
            })
                .then((res) => {
                    if (res.success) {
                        CreateAlert({
                            message: translate("all.updateSuccess"),
                            type: "success",
                        });
                        props.onFinishEdit();
                    } else {
                        CreateAlert({
                            message: translate("role.validDuplicate"),
                            type: "danger",
                        });
                    }
                })
                .catch((error) => {
                    CreateAlert({
                        message: error.message,
                        type: "danger",
                    });
                });
        },
    });

    return (
        <form className="Edit" onSubmit={handleSubmit}>
            <InputWraper
                label={translate("role.nameRole")}
                inputProps={getInputProps("roleName")}
                component={InputText}
            />
            <div>
                <label style={{ marginRight: "20px", color: "#fff" }}>
                    {translate("all.status")}:
                </label>

                <InputCheckbox
                    label={translate("role.inActive")}
                    onChange={() => setValues({ roleStatus: roleEnumStatus.INACTIVE })}
                    name="roleStatus"
                    value={values.roleStatus === roleEnumStatus.INACTIVE}
                    onTouched={() => false}
                    isRequired={true}
                    type='radio'
                />

                <InputCheckbox
                    label={translate("role.active")}
                    onChange={() => setValues({ roleStatus: roleEnumStatus.ACTIVE })}
                    name="roleStatus"
                    value={values.roleStatus === roleEnumStatus.ACTIVE}
                    onTouched={() => false}
                    isRequired={true}
                    type='radio'
                />
            </div>


            <div style={{ textAlign: "right" }}>
                <Button
                    isLoading={isSubmitting}
                    label={translate("all.cancel")}
                    onClick={props.onClose}
                    buttonType="danger"
                    className="mr5"
                />

                <Button
                    isLoading={isSubmitting}
                    label={
                        formType === roleFormType.ADD
                            ? translate("all.create")
                            : translate("all.update")
                    }
                    type="submit"
                />
            </div>
        </form>
    );
};
