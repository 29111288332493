import React, { FC } from "react";
import { Icon } from "../icon";

export const PopupWraper: FC<{
    title: string;
    onClose: () => void;
    center?: boolean;
    className?: string;
    shouldCloseOnOverlayClick?: boolean;
}> = (props) => {
    const id = `${Date.now()}-popupwraper`;

    return (
        <div
            id={id}
            className={`PopupWraper ${
                props.center ? "PopupWraper--center" : ""
            } ${props.className || ""}`}
            onClick={(e: any) =>
                e.target.id === id && props.shouldCloseOnOverlayClick !== false
                    ? props.onClose()
                    : null
            }
        >
            <div className="box">
                <div className="boxTitle">
                    <span>{props.title}</span>
                    <div className="btnClose" onClick={() => props.onClose()}>
                        <Icon.Close />
                    </div>
                </div>
                <div className="content">{props.children}</div>
            </div>
        </div>
    );
};
