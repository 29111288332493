import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    ObjectUtils,
    useForm,
    InputWraper,
    Button,
    CreateAlert,
    onError,
} from "../../../modules";
import { AdminService } from "../../../services/admin";
import { PageHead } from "../../../components/page-head";
import { InputNumber } from "../../../components";

export const UserDetail: FC = () => {
    const { params } = useParams();
    const email = ObjectUtils.getIn(params, "email", "");
    const id = +ObjectUtils.getIn(params, "id", "");

    useEffect(() => {}, [email]);

    return (
        <div className="UserDetail">
            <PageHead title="User Detail" />

            <div className="row">
                <div className="col-sm-4">
                    <div className="box">
                        <div className="head">Set Rank</div>
                        <SetRank userId={id} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const SetRank: FC<{ userId: number }> = (props) => {
    const { handleSubmit, getInputProps, isSubmitting } = useForm({
        structure: [
            {
                name: "rank",
            },
        ],
        onSubmit: async (values) => {
            return AdminService.setRank(props.userId, values.rank)
                .then(() =>
                    CreateAlert({
                        type: "success",
                        message: "Set rank successful.",
                    })
                )
                .catch(onError);
        },
    });

    return (
        <form onSubmit={handleSubmit} className="pd15">
            <InputWraper
                inputProps={getInputProps("rank")}
                renderInput={InputNumber}
            />

            <Button isLoading={isSubmitting} label="Set Rank" type="submit" />
        </form>
    );
};
